import { StyleSheet } from "react-native";
import selectBreakpoint from "../../src/Utils/selectBreakpoint";

const styles = (breakpoint: Breakpoint) => StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'rgba(107,202,255,0.7)',
    ...StyleSheet.absoluteFillObject
  },
  backButtonContainer: {
    gap: 32,
    padding: 32,
    flexDirection: 'row',
    alignItems: 'center',
    position: 'absolute'
  },
  backButton: {
    height: 100,
    width: 104
  },
  wrapper: {
    flex: 1,
    marginHorizontal: 'auto',
    paddingVertical: 32,
    alignItems: 'center',
    justifyContent: 'center',
    ...selectBreakpoint(breakpoint, {
      desktop: {
        width: 1200,
      },
      tablet: {
        width: '100%',
        paddingHorizontal: 32
      },
      mobile: {
        width: '100%',
        paddingHorizontal: 16
      }
    })
  },
  modal: {
    padding: 32,
    borderRadius: 16,
    borderWidth: 3,
    borderColor: '#CEEDFF',
    ...selectBreakpoint(breakpoint, {
      mobile: {
        width: '100%'
      },
      tablet: {
        width: '75%'
      }
    })
  },
  modalTitle: {
    textShadow: 'rgb(0,0,0) -1.05px 1.05px 2px',
    fontFamily: 'tt_trailer',
    textTransform: 'uppercase',
    color: '#ffffff',
    textAlign: 'center',
    fontSize: selectBreakpoint(breakpoint, {
      desktop: 60,
      tablet: 60,
      mobile: 36
    }),
  },
  buttonsWrapper: {
    flexDirection: 'row',
    gap: 16
  },
  buttonWrapper: {
    flex: 1,
    ...selectBreakpoint(breakpoint, {
      desktop: {
        minWidth: 220
      },
      tablet: {
        minWidth: 220
      },
      mobile: {
        marginTop: 16
      }
    })
  },
  buttonBackgroundImg: {
    width: '100%',
    aspectRatio: 333 / 129,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 16
  },
  buttonText: {
    fontFamily: 'tt_trailer',
    textTransform: 'uppercase',
    color: '#477DFD',
    fontSize: selectBreakpoint(breakpoint, {
      desktop: 36,
      tablet: 36,
      mobile: 20
    }),
  }
})

export default styles