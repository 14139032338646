import { Box3, Group } from "three";

import Generic from "./Generic";
import { log } from "../Utils/LogUtils"

export default class SnowBall extends Generic {
    speed = 0;
    getDepth = (mesh) => {
        let box3 = new Box3();
        box3.setFromObject(mesh);

        return Math.round(box3.max.x - box3.min.x);
    };

    setup = async () => {
        const {
            vehicles: { snow_balls },
        } = this.globalModels;

        const numberOfSnowballs = Object.keys(snow_balls).length;

        for (let i = 0; i < numberOfSnowballs; i++) {
            const key = `${i}`
            log("~~~~ [SNOWBALL] ~~~~", key);
            await this._register(key, {
                ...snow_balls[`${i}`],
                castShadow: true,
                receiveShadow: true,
            });

            const afterLoadFn = snow_balls[`${i}`]['afterLoad'];
            const model = this.models[`${i}`];
            if(afterLoadFn && model) {
                afterLoadFn(model);
            }
        }
        return this.models;
    };
}
