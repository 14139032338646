import { getUsersByRankRes } from "../index";

export const LeaderBoardResMock: getUsersByRankRes = {
  message: "Found users by rank",
  isSuccess: true,
  users: {
    token_traits: [
      {
        id: "2d3de3af-4963-422f-bd4a-17bfa65cc6d6",
        bg_color: "#FFFFFF",
        description: "Test description",
        image:
          "https://pudgyworld-penguins.s3.amazonaws.com/production/2d3de3af-4963-422f-bd4a-17bfa65cc6d6.png",
        penguin_name: "Eat",
        user_ranks: {
          roads_rank: 238,
        },
        user_penguin: {
          id: "2d3de3af-4963-422f-bd4a-17bfa65cc6d6",
          created_at: "2023-09-21T00:00:00.000Z",
        },
      },
      {
        id: "019dd18b-b01b-413f-a754-0870c8bec05d",
        bg_color: "#000000",
        description: "Test description 2",
        image:
          "https://pudgyworld-penguins.s3.amazonaws.com/production/019dd18b-b01b-413f-a754-0870c8bec05d.png",
        penguin_name: "Mark",
        user_ranks: {
          roads_rank: 200,
        },
        user_penguin: {
          id: "019dd18b-b01b-413f-a754-0870c8bec05d",
          created_at: "2023-09-21T00:00:01.000Z",
        },
      },
      {
        id: "d0c8725c-c852-4b95-bf5b-b55d83375c3e",
        bg_color: "#FFFFFF",
        description: "Test description",
        image:
          "https://pudgyworld-penguins.s3.amazonaws.com/production/d0c8725c-c852-4b95-bf5b-b55d83375c3e.png",
        penguin_name: "Lorenzo",
        user_ranks: {
          roads_rank: 139,
        },
        user_penguin: {
          id: "d0c8725c-c852-4b95-bf5b-b55d83375c3e",
          created_at: "2023-09-21T00:00:00.000Z",
        },
      },
      {
        id: "107035696846055408251167865409690968792",
        bg_color: "#000000",
        description: "Test description 2",
        image: "http://example.com/image2.png",
        penguin_name: "Mimi Mercedes",
        user_ranks: {
          roads_rank: 94,
        },
        user_penguin: {
          id: "107035696846055408251167865409690968792",
          created_at: "2023-09-21T00:00:01.000Z",
        },
      },
      {
        id: "185228286847748218277346603446760839514",
        bg_color: "#FFFFFF",
        description: "Test description",
        image:
          "https://pudgyworld-penguins.s3.amazonaws.com/production/d0c8725c-c852-4b95-bf5b-b55d83375c3e.png",
        penguin_name: "LNK",
        user_ranks: {
          roads_rank: 79,
        },
        user_penguin: {
          id: "185228286847748218277346603446760839514",
          created_at: "2023-09-21T00:00:00.000Z",
        },
      },
      {
        id: "771ceb6a-ca9e-4adf-bbaa-52138a2a45cd",
        bg_color: "#000000",
        description: "Test description 2",
        image:
          "https://pudgyworld-penguins.s3.amazonaws.com/production/771ceb6a-ca9e-4adf-bbaa-52138a2a45cd.png",
        penguin_name: "Jeff",
        user_ranks: {
          roads_rank: 61,
        },
        user_penguin: {
          id: "771ceb6a-ca9e-4adf-bbaa-52138a2a45cd",
          created_at: "2023-09-21T00:00:01.000Z",
        },
      },
    ],
  },
};
/*

 */
