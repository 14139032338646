import { useMemo } from "react"
import { useWindowDimensions } from "react-native"

const useBreakpoint = () => {
  const dimensions = useWindowDimensions()
  
  const value: Breakpoint = useMemo(() => {
    if (dimensions.width < 768)
      return 'mobile'

    if (dimensions.width > 768 && dimensions.width < 1200)
      return 'tablet'

    return 'desktop'
  }, [dimensions])

  return value
}

export default useBreakpoint