import { createContext } from "react";
import events from "events";
import State from "../src/state";
import Engine from "../src/GameEngine";
const { EventEmitter } = events;
// test
export const GameContext = createContext({
  character: "pudgypenguin",
  score: 0,
  highscore: 0,
  coins: 0,
  xp: 0,
  gems: 0,
  globalPosition: 0,
  usersName: "Penguin",
  traits: {},
  subscribeToEvent: (eventName, callback) => () => {},
  triggerEvent: (eventName, data) => {},
  subscribeEventOnce: (eventName, callback) => () => {},
  setGameState: (state) => {},
  gameState: State.Game.none,
  engine: Engine,
  setEngine: (engine) => {},
});

export const gameEventEmitter = new EventEmitter();
gameEventEmitter.setMaxListeners(100);
