import { Image, ImageStyle, StyleProp, StyleSheet } from "react-native";
import { Pressable } from "react-native-web";
import soundOnIcon from "../assets/images/ui/sound-on-button.png";
import soundOffIcon from "../assets/images/ui/sound-off-button.png";
import soundOffPressedIcon from "../assets/images/ui/sound-off-button-pressed.png";
import { FC, useCallback, useMemo, useState } from "react";
import { gameEventEmitter } from "../context/GameContext";
import { EVENT_GAME_TOGGLE_MUTE } from "../src/GameEvents";
import AudioManager from "../src/AudioManager";
import useBreakpoint from "../src/hooks/useBreakpoint";
import selectBreakpoint from "../src/Utils/selectBreakpoint";

interface Props {}
const SoundButton: FC<Props> = () => {
  const [isMuted, setIsMuted] = useState(AudioManager.MUTED);
  const breakpoint = useBreakpoint();

  const handleClick = useCallback(() => {
    gameEventEmitter.emit(EVENT_GAME_TOGGLE_MUTE);
    setIsMuted((prev) => !prev);
  }, []);

  const icon = useMemo(() => {
    if (isMuted) {
      return {
        default: soundOnIcon,
        pressed: soundOffPressedIcon,
      };
    }

    return {
      default: soundOffIcon,
      pressed: soundOffPressedIcon,
    };
  }, [isMuted]);

  const imageStyle: StyleProp<ImageStyle> = useMemo(
    () => ({
      aspectRatio: 286 / 290,
      transitionProperty: ["opacity"],
      transitionDuration: ["120ms"],
      width: selectBreakpoint(breakpoint, {
        desktop: 75,
        tablet: 75,
        mobile: 40,
      }),
    }),
    [breakpoint]
  );

  return (
    <Pressable onPress={handleClick}>
      {({ hovered }) => (
        <>
          <Image source={icon.default} style={imageStyle} />
          <Image
            source={icon.pressed}
            style={[
              imageStyle,
              StyleSheet.absoluteFillObject,
              { zIndex: hovered ? 1 : -1 },
            ]}
          />
        </>
      )}
    </Pressable>
  );
};

export default SoundButton;
