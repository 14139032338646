import Generic from "./Generic";
import { gameEventEmitter } from "../../context/GameContext";
import { EVENT_LOADING_PROGRESS } from "../GameEvents";
import { log } from "../Utils/LogUtils";

export default class Coin extends Generic {
  setup = async () => {
    const {
      environment: { coin },
    } = this.globalModels;
    const numOfCoins = Object.keys(coin).length;
    for (let i = 0; i < numOfCoins; i++) {
      console.log("Loading coins: ", i, "/", numOfCoins);
      gameEventEmitter.emit(EVENT_LOADING_PROGRESS, {
        msg: `Loading coins: ${i} / ${numOfCoins}`,
      });
      await this._register(`${i}`, {
        ...coin[`${i}`],
        castShadow: true,
        receiveShadow: false,
      });
    }
    return this.models;
  };

  getRandomWithPoints = () => {
    let keys = Object.keys(this.models);
    console.log("~~~ [SPC] keys", keys);

    const randomNum = Math.floor(Math.max(0, keys.length - 1) * Math.random());
    console.log("~~~ [SPC] randomNum", randomNum);

    const points = randomNum === 2 ? 5 : 1;
    console.log("~~~ [SPC] points", points);

    const key = keys[randomNum];
    console.log("~~~ [SPC] key", key);

    return {
      points,
      mesh: this.models[key].clone(),
    };
  };
}
