export const pudgyMapping = {
    'short_sleds': ['small_pudgy_rider_1', 'small_pudgy_rider_2', 'small_pudgy_rider_3', 'small_pudgy_rider_4', 'small_pudgy_rider_5'],
    'medium_sleds': ['medium_pudgy_rider_1', 'medium_pudgy_rider_2', 'medium_pudgy_rider_3', 'medium_pudgy_rider_4', 'medium_pudgy_rider_5'],
    'large_sled': ['large_pudgy_rider_1', 'large_pudgy_rider_2', 'large_pudgy_rider_3']
};

export const pudgyRiders: string[] = [
    'small_pudgy_rider_1',
    'small_pudgy_rider_2',
    'small_pudgy_rider_3',
    'small_pudgy_rider_4',
    'small_pudgy_rider_5',
    'medium_pudgy_rider_1',
    'medium_pudgy_rider_2',
    'medium_pudgy_rider_3',
    'medium_pudgy_rider_4',
    'medium_pudgy_rider_5',
    'large_pudgy_rider_1',
    'large_pudgy_rider_2',
    'large_pudgy_rider_3'
];

export const cars: string[] = [
    // 'police_car',
    'short_sleds',
    // 'blue_truck',
    // 'green_car',
    // 'orange_car',
    // 'purple_car',
    // 'red_truck',
    'medium_sleds',
    'large_sled'
];
