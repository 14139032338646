import { FC, useMemo } from "react"
import { Image, ImageBackground, Pressable, StyleSheet, Text, TouchableOpacity, View } from "react-native"
import backIcon from "../../assets/images/ui/back.png"
import buttonBackgroundImg from "../../assets/images/ui/button-background.png"
import blueButtonBackgroundImg from "../../assets/images/ui/blue-button-background.png"
import playIcon from "../../assets/images/ui/play.png"
import { gameEventEmitter } from "../../context/GameContext"
import { EVENT_GAME_STATE_CHANGE } from "../../src/GameEvents"
import state from "../../src/state"
import { LinearGradient } from "expo-linear-gradient"
import { useNavigation } from "@react-navigation/native"
import { StackNavigationProp } from "@react-navigation/stack"
import useBreakpoint from "../../src/hooks/useBreakpoint"
import styles from "./styles"
import BackButton from "../../components/BackButton"

interface Props {}
const ExitToModeSelectScreen: FC<Props> = () => {
  const navigation = useNavigation<StackNavigationProp<Navigation.Route>>()

  const breakpoint = useBreakpoint()
  const style = useMemo(() => styles(breakpoint), [breakpoint])

  const handleBack = () => {
    gameEventEmitter.emit(EVENT_GAME_STATE_CHANGE, state.Game.paused)
  }

  const handleResume = () => {
    gameEventEmitter.emit(EVENT_GAME_STATE_CHANGE, state.Game.playing)
  }

  const handleExit = () => {
    navigation.navigate('GameMode')
  }

  return (
    <View style={style.container}>
      <BackButton onPress={handleBack} />
      <View style={style.wrapper}>
        <LinearGradient
          colors={['#ffffff', 'rgba(255, 255, 255, 0.23)']}
          locations={[0.018, 1.11]}
          start={[0.0, 0.0]}
          end={[1.0, 1.0]}
          style={style.modal}
        >
          <Text style={style.modalTitle}>Exit to mode select?</Text>
          <View style={style.buttonsWrapper}>
            <Pressable style={style.buttonWrapper} onPress={handleResume}>
              <ImageBackground source={buttonBackgroundImg} style={style.buttonBackgroundImg}>
                <Image
                  source={playIcon}
                  style={{
                    height: '25%',
                    aspectRatio: 103 / 118
                  }}
                />
                <Text style={style.buttonText}>Resume</Text>
              </ImageBackground>
            </Pressable>
            <Pressable style={style.buttonWrapper} onPress={handleExit}>
              <ImageBackground source={blueButtonBackgroundImg} style={style.buttonBackgroundImg}>
                <Text style={[style.buttonText, {color: '#ffffff'}]}>Yes</Text>
              </ImageBackground>
            </Pressable>
          </View>
        </LinearGradient>
      </View>
    </View>
  )
}

export default ExitToModeSelectScreen