import Generic from './Generic';

export default class Boulder extends Generic {
  setup = async () => {
    const {
      environment: { boulder },
    } = this.globalModels;

    const numOfMeshes = Object.keys(boulder).length;

    for (let i = 0; i < numOfMeshes; i++) {
      const key = `${i}`
      await this._register(key, {
        ...boulder[key],
        castShadow: true,
        receiveShadow: false,
      });

      const mesh = this.models[key];
      if(mesh) {
        mesh.scale.x *= 0.15;  // scale down x by 20%
        mesh.scale.y *= 0.15;  // scale down y by 20%
        mesh.scale.z *= 0.15;  // scale down z by 20%
      }
      const afterLoadFn = boulder[key]['afterLoad'];
      if(afterLoadFn) afterLoadFn(mesh);
    }
    return this.models;
  };
}
