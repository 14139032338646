import Generic from './Generic';

export default class LilyPad extends Generic {
  setup = async () => {
    const {
      environment: { lily_pad },
    } = this.globalModels;

    const numOfMeshes = Object.keys(lily_pad).length;

    for (let i = 0; i < numOfMeshes; i++) {
      const key = `${i}`
      await this._register(key, {
        ...lily_pad[`${i}`],
        castShadow: true,
        receiveShadow: true,
      });

      // todo: setup generic alteration function as part of Models.js
      const model = this.models[key];
      if(model) {
        // model.rotation.y = Math.PI / 2;
        model.scale.x *= 0.4;  // scale down x by 20%
        model.scale.y *= 0.4;  // scale down y by 20%
        model.scale.z *= 0.4;  // scale down z by 20%
      }
    }

    return this.models;
  };
}
