import { StyleSheet } from "react-native";
import selectBreakpoint from "../../src/Utils/selectBreakpoint";

const styles = (breakpoint: Breakpoint) =>
  StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: "rgba(107,202,255,0.7)",
      ...StyleSheet.absoluteFillObject,
    },
    backButtonContainer: {
      gap: 32,
      padding: 32,
      flexDirection: "row",
      alignItems: "center",
      position: "absolute",
    },
    backButton: {
      height: 100,
      width: 104,
    },
    wrapper: {
      flex: 1,
      marginHorizontal: "auto",
      paddingVertical: 32,
      alignItems: "center",
      justifyContent: "center",
      ...selectBreakpoint(breakpoint, {
        desktop: {
          gap: 92,
          width: 1200,
          flexDirection: "row",
        },
        tablet: {
          gap: 64,
          width: "100%",
          paddingHorizontal: 32,
          flexDirection: "row",
        },
        mobile: {
          gap: 8,
          width: "100%",
          paddingHorizontal: 16,
        },
      }),
    },
    pauseArtworkWrapper: {
      flex: 1,
    },
    pauseArtWorkImage: {
      width: "100%",
      aspectRatio: 1,
    },
    menuContainer: {
      ...selectBreakpoint(breakpoint, {
        desktop: {
          gap: 32,
          width: "25%",
        },
        tablet: {
          gap: 32,
          width: "30%",
        },
        mobile: {
          gap: 8,
          width: 250,
        },
      }),
    },
    menuItem: {
      width: "100%",
    },
    menuItemBackgroundImage: {
      width: "100%",
      aspectRatio: 333 / 129,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      gap: 16,
      transitionProperty: ["opacity"],
      transitionDuration: ["120ms"],
    },
    buttonText: {
      fontFamily: "tt_trailer",
      fontSize: 36,
      textTransform: "uppercase",
      color: "#477DFD",
    },
  });

export default styles;
