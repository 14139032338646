import { StyleSheet } from "react-native"
import selectBreakpoint from "../../src/Utils/selectBreakpoint"

const styles = (breakpoint: Breakpoint) => StyleSheet.create({
  scoreBackgroundImg: {
    aspectRatio: 940 / 412,
    height: selectBreakpoint(breakpoint, {
      desktop: 111,
      mobile: 55,
      tablet: 95
    })
  },
  scoreText: {
    position: 'absolute',
    fontFamily: 'tt_trailer',
    color: '#68B4FB',
    ...selectBreakpoint(breakpoint, {
      desktop: {
        top: 64,
        left: 38,
        fontSize: 20,
      },
      tablet: {
        top: 48,
        left: 28,
        fontSize: 24,
      },
      mobile: {
        top: 34,
        left: 20,
        fontSize: 8,
      }
    })
  },
  scoreNumber: {
    position: 'absolute',
    fontFamily: 'TT_Trailer',
    color: '#68B4FB',
    ...selectBreakpoint(breakpoint, {
      desktop: {
        fontSize: 48,
        left: 130,
        top: 26,
      },
      tablet: {
        fontSize: 38,
        left: 115,
        top: 25,
      },
      mobile: {
        fontSize: 20,
        left: 66,
        top: 16,
      }
    })
  }
})

export default styles