import { StyleSheet } from "react-native";
import selectBreakpoint from "../../src/Utils/selectBreakpoint";

const styles = (breakpoint: Breakpoint) => StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    position: 'absolute',
    zIndex: 2,
    ...selectBreakpoint(breakpoint, {
      desktop: {
        gap: 32,
        padding: 32,
      },
      tablet: {
        gap: 32,
        padding: 32,
      },
      mobile: {
        gap: 8,
        padding: 8,
      }
    })
  },
  button: {
    aspectRatio: 1,
    height: selectBreakpoint(breakpoint, {
      desktop: 100,
      tablet: 100,
      mobile: 60
    }),
  },
  title: {
    fontFamily: 'tt_trailer',
    textTransform: 'uppercase',
    color: '#ffffff',
    textAlign: 'center',
    textShadow: '-1.05px 1.05px 0px #1A1A1A',
    fontSize: selectBreakpoint(breakpoint, {
      desktop: 72,
      tablet: 72,
      mobile: 36
    }),
  }
})

export default styles