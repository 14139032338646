import { userRes } from "../index";

export const mockUserRes: userRes = {
  user: {
    id: "107035696846055408251167865409690968792",
    schema_id: "abc",
    schma_url: "http://example.com/schema",
    state: "active",
    state_changed_at: new Date(),
    traits: {
      email: "test@example.com",
      penguin_name: "Mimi Mercedes",
      twitter_url: "http://twitter.com/test",
      opensea_url: "http://opensea.io/test",
      instagram_url: "http://instagram.com/test",
      facebook_url: "http://facebook.com/test",
      description: "Test description",
      bg_color: "#FFFFFF",
      previewed_traits: [1, 2, 3],
      gems: 10,
      coins: 50,
      xp: 100,
      tickets: 5,
      last_login: 1632217614,
      login_reward_multiplier: 1,
      created_at: "2023-09-21T00:00:00.000Z",
    },
    recovery_addresses: [
      {
        id: "1",
        value: "recovery@example.com",
        via: "email",
        created_at: new Date(),
        updated_at: new Date(),
      },
    ],
    verifiable_addresses: [
      {
        id: "1",
        value: "test@example.com",
        verified: true,
        via: "email",
        status: "sent",
        created_at: new Date(),
        updated_at: new Date(),
      },
    ],
    metadata_public: null,
    metadata_admin: null,
    created_at: "2023-09-21T00:00:00.000Z",
    updated_at: "2023-09-21T00:00:00.000Z",
  },
  penguin: {
    user_ranks: {
      chess_rank: 1,
      roads_rank: 94,
    },
    user_penguin: {
      penguin_name: "Mimi Mercedes",
      trait_body_id: 1,
      trait_skin_id: 1,
      trait_face_id: 2,
      trait_head_id: 3,
      id: "107035696846055408251167865409690968792",
      image: "http://example.com/image.png",
      created_at: "2023-09-21T00:00:00.000Z",
    },
  },
  error: "",
  isSuccess: true,
};
