export default {
  penguin: {
    move: {
      '0': require('../assets/audio/PenguDash_PlayerJump_1.mp3'),
      '1': require('../assets/audio/PenguDash_PlayerJump_2.mp3'),
      '2': require('../assets/audio/PenguDash_PlayerJump_3.mp3'), // Original pitch
      '3': require('../assets/audio/PenguDash_PlayerJump_4.mp3'),
      '4': require('../assets/audio/PenguDash_PlayerJump_5.mp3'),
    },
    die: {
      '0': require('../assets/audio/PenguDash_CharacterHit.aac'),
      '1': require('../assets/audio/PenguDash_CharacterHit_2.aac'),
    },
  },
  sled: {
    alert: require('../assets/audio/car-horn.wav'),
    passive: {
      '0': require('../assets/audio/PenguDash_SledsApproaching.aac'), // TODO: New sound
    },
    die: {
      '0': require('../assets/audio/carhit.mp3'),
      '1': require('../assets/audio/carsquish3.wav'),
    },
  },
  train: {
    alert: require('../assets/audio/Train_Alarm.wav'),
    move: {
      '0': require('../assets/audio/PenguDash_TrainApproaching_Short.aac'),
      '1': require('../assets/audio/train_pass_shorter.wav'), // TODO:: Game Feel - use this one when the player is almost hit
    },
    die: {
      '0': require('../assets/audio/trainsplat.wav'),
    },
  },
  pickup: {
    coin: require('../assets/audio/PenguDash_CoinGet_2.aac'),
    reward: require('../assets/audio/PenguDash_RewardGet.aac'), // TODO: New sound
    gem: require('../assets/audio/PenguDash_GemGet.aac'), // TODO: New sound
  },

  bg_music: require('../assets/audio/PenguDash_BG_Music_2_Mono.mp3'),

  button_in: require('../assets/audio/Pop_1.wav'),
  button_out: require('../assets/audio/Pop_2.wav'),

  banner: require('../assets/audio/bannerhit3-g.wav'),
  water: require('../assets/audio/watersplashlow.mp3'),
  game_start: require('../assets/audio/PenguDash_GameStart.aac'),
  quest_complete: require('../assets/audio/PudgyWorld_QuestComplete.aac'), // TODO: New sound
};
