import React, { useContext, FC, useMemo } from "react";
import { Image, Text, View } from "react-native";
import { GameContext } from "../../context/GameContext";
import scoreBackgroundImg from '../../assets/images/ui/score-background.png'
import styles from "./styles";
import useBreakpoint from "../../src/hooks/useBreakpoint";

interface Props {}
const Score: FC<Props> = () => {
  const { score = 0 } = useContext(GameContext);

  const breakpoint = useBreakpoint()
  const style = useMemo(() => styles(breakpoint), [breakpoint])

  return (
    <View>
      <Image source={scoreBackgroundImg} style={style.scoreBackgroundImg} />
      <Text style={style.scoreText}>Score</Text>
      <Text style={style.scoreNumber}>{String(score).padStart(4, "0")}</Text>
    </View>
  )
}

export default Score