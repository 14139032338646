import { GLView } from "expo-gl";
import React, { Component } from "react";
import {
  Animated,
  Dimensions,
  StyleSheet,
  Platform,
  Vibration,
  View,
  useColorScheme,
} from "react-native";

import GestureRecognizer, { swipeDirections } from "../components/GestureView";
import Engine from "../src/GameEngine";
import State, { Tags } from "../src/state";
import GameOverScreen from "./GameOverScreen";
import HomeScreen from "./HomeScreen";
import { GameContext, gameEventEmitter } from "../context/GameContext";
import {
  EVENT_COIN_COLLISION,
  EVENT_GAME_SCORE_CHANGED,
  EVENT_GAME_START,
  EVENT_GAME_STATE_CHANGE,
  EVENT_PLAYER_START,
} from "../src/GameEvents";
import AudioManager from "../src/AudioManager";
import { useMobileDetect } from "../src/hooks/useMobileDetect";

import { GOTO } from "@env";
import PauseScreen from "./PauseScreen";
import ControlsScreen from "./ControlsScreen";
import ExitToModeSelectScreen from "./ExitToModeSelectScreen";
import { useAmplitude } from "../src/hooks/useAmplitude";
import GamePlayingScreen from "./GamePlayingScreen";
const DEBUG_CAMERA_CONTROLS = false;

class Game extends Component {
  /// Reserve State for UI related updates...
  state = {
    ready: false,
    viewKey: 0,
    gameState: State.Game.none,
  };

  transitionScreensValue = new Animated.Value(1);

  constructor(props) {
    super(props);
    console.log("~~~~ Game constructor");
    this.handleGameStateChange = this.handleGameStateChange.bind(this);
    gameEventEmitter.on(EVENT_GAME_STATE_CHANGE, this.handleGameStateChange);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    if (nextState.gameState && nextState.gameState !== this.state.gameState) {
      this.updateWithGameState(nextState.gameState, this.state.gameState);
    }
    if (this.engine && nextProps.character !== this.props.character) {
      this.engine._hero.setCharacter(nextProps.character);
    }
  }

  transitionToGamePlayingState = () => {
    Animated.timing(this.transitionScreensValue, {
      toValue: 0,
      useNativeDriver: true,
      duration: 200,
      onComplete: ({ finished }) => {
        this.engine.setupGame(this.props.character);
        this.engine.init();
        gameEventEmitter.emit(EVENT_GAME_START);

        if (finished) {
          Animated.timing(this.transitionScreensValue, {
            toValue: 1,
            useNativeDriver: true,
            duration: 300,
          }).start();
        }
      },
    }).start();
  };

  updateWithGameState = (gameState) => {
    gameEventEmitter.emit("EVENT_GAME_STATE_CHANGE", gameState);
  };

  handleGameStateChange = (gameState) => {
    if (!gameState) throw new Error("gameState cannot be undefined");

    if (gameState === this.state.gameState) {
      console.log(
        "~~~~ Game state is the same as we are already in.",
        gameState,
        this.state.gameState,
      );
      return;
    }

    const lastState = this.state.gameState;

    this.setState({ gameState });
    this.engine.gameState = gameState;
    const { playing, gameOver, paused, none } = State.Game;
    switch (gameState) {
      case playing:
        if (lastState.includes(Tags.pause)) {
          this.engine.unpause();
        } else if (lastState !== none) {
          this.transitionToGamePlayingState();
        } else {
          // Coming straight from the menu.
          this.engine._hero.stopIdle();
          this.onSwipe(swipeDirections.SWIPE_UP);
          gameEventEmitter.emit(EVENT_PLAYER_START);
          this.trackGameStart("Game Started");
        }

        break;
      case gameOver:
        break;
      case paused:
        // this.engine.pause();
        break;
      case none:
        if (lastState === gameOver) {
          this.transitionToGamePlayingState();
        }
        this.newScore();

        break;
      default:
        break;
    }

    if (gameState.includes(Tags.pause)) {
      this.engine.pause();
    }
  };

  async componentDidMount() {
    Dimensions.addEventListener("change", this.onScreenResize);

    setTimeout(() => {
      if (GOTO && GOTO === "GAME_OVER") {
        this.engine.gameOver();
      }
    }, 100);
  }

  onScreenResize = ({ window }) => {
    this.engine.updateScale();
  };

  componentWillUnmount() {
    cancelAnimationFrame(this.engine.raf);
    Dimensions.removeEventListener("change", this.onScreenResize);
    gameEventEmitter.removeListener(
      EVENT_GAME_STATE_CHANGE,
      this.handleGameStateChange,
    );
  }

  UNSAFE_componentWillMount() {
    this.engine = new Engine();
    // this.engine.hideShadows = this.hideShadows;
    this.engine.onUpdateScore = (position) => {
      this.setScore(position);
    };
    this.engine.onGameInit = () => {
      this.setScore(0);
    };
    this.engine._isGameStateEnded = () => {
      return this.state.gameState !== State.Game.playing;
    };
    this.engine.onGameReady = () => this.setState({ ready: true });
    this.engine.onGameEnded = () => {
      this.setState({ gameState: State.Game.gameOver });
      // this.props.navigation.navigate('GameOver')
    };
    this.engine.setupGame(this.props.character);
    this.engine.init();
  }

  newScore = () => {
    Vibration.cancel();
    // this.props.setGameState(State.Game.playing);
    this.setScore(0);
    // this.engine.init();
  };

  setScore = (score) => {
    gameEventEmitter.emit(EVENT_GAME_SCORE_CHANGED, score);
  };

  trackGameStart = (event) => {
    const { trackEvent } = useAmplitude();
    trackEvent(event);
  };

  onSwipe = (gestureName) => this.engine.moveWithDirection(gestureName);

  renderGame = () => {
    if (!this.state.ready) return;

    return (
      <GestureView
        pointerEvents={DEBUG_CAMERA_CONTROLS ? "none" : undefined}
        onStartGesture={this.engine.beginMoveWithDirection}
        onSwipe={this.onSwipe}
      >
        <GLView
          style={{ flex: 1, height: "100%", overflow: "hidden" }}
          onContextCreate={this.engine._onGLContextCreate}
        />
      </GestureView>
    );
  };

  renderGamePlayingScreen = () => {
    if (!this.state.ready) return;

    if (this.state.gameState !== State.Game.playing) {
      return;
    }

    return <GamePlayingScreen />;
  };

  renderGameOver = () => {
    if (this.state.gameState !== State.Game.gameOver) {
      return null;
    }

    return <GameOverScreen />;
  };

  renderReturnToModeSelect = () => {
    if (this.state.gameState !== State.Game.returningToModeSelect) {
      return null;
    }

    return <ExitToModeSelectScreen />;
  };

  renderControls = () => {
    const { isDesktop, isMobile, isTablet } = useMobileDetect();

    if (this.state.gameState !== State.Game.showingControls) {
      return null;
    }

    return <ControlsScreen />;
  };

  render() {
    return (
      <View
        pointerEvents="box-none"
        style={[
          StyleSheet.absoluteFill,
          { flex: 1, backgroundColor: "#87C6FF" },
          Platform.select({
            web: { position: "fixed" },
            default: { position: "absolute" },
          }),
          this.props.style,
        ]}
      >
        <Animated.View
          style={{ flex: 1, opacity: this.transitionScreensValue }}
        >
          {this.renderGame()}
          {this.renderGamePlayingScreen()}
        </Animated.View>

        {this.renderGameOver()}

        {this.renderReturnToModeSelect()}

        {this.renderControls()}

        {this.state.gameState === State.Game.paused && <PauseScreen />}

        {this.state.gameState === State.Game.none && (
          <HomeScreen
            gameState={this.state.gameState}
            onPlay={() => {
              this.updateWithGameState(State.Game.playing);
            }}
          />
        )}
      </View>
    );
  }
}

const GestureView = ({ onStartGesture, onSwipe, ...props }) => {
  const config = {
    // Minimum velocity to trigger swipe
    // WARNING: The velocity threshold must be zero because when there lag the swipe velocity comes back as 0.
    // This caused all lags to be interpreted as the default SWIPE_UP gesture.
    velocityThreshold: 0.0,
    // Minimum pixel distance to recognize as a swipe
    directionalOffsetThreshold: 30,
  };

  return (
    <GestureRecognizer
      onResponderGrant={() => {
        onStartGesture();
      }}
      onSwipe={(direction) => {
        onSwipe(direction);
      }}
      config={config}
      onTap={() => {
        onSwipe(swipeDirections.SWIPE_UP);
      }}
      style={{ flex: 1 }}
      {...props}
    />
  );
};

function GameScreen(props) {
  const scheme = useColorScheme();
  const { character, subscribeToEvent } = React.useContext(GameContext);

  React.useEffect(() => {
    const unsubCoins = subscribeToEvent(EVENT_COIN_COLLISION, async () => {
      await AudioManager.playCoinCollectedSound();
    });

    return unsubCoins;
  }, []);
  // const appState = useAppState();

  return (
    <Game {...props} character={character} isDarkMode={scheme === "dark"} />
  );
}

export default GameScreen;
