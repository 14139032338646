import { UserGlobalPositionData } from "../index";

export const mockUserGlobalPositionData: UserGlobalPositionData = {
  message: "User position retrieved successfully",
  isSuccess: true,
  position: 4,
  data: {
    owner_wallet: "0x1234",
    user_id: "107035696846055408251167865409690968792",
    image:
      "https://pudgyworld-penguins.s3.amazonaws.com/production/107035696846055408251167865409690968792.png",
    id: "107035696846055408251167865409690968792",
    penguin_name: "Mimi Mercedes",
    position: 4,
    bg_color: "#FFFFFF",
    user_ranks: {
      roads_rank: 90,
    },
  },
};
