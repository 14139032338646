import { Box3, Object3D } from "three";

import ModelLoader from "../../src/ModelLoader";
import { gameEventEmitter } from "../../context/GameContext";
import {
  EVENT_GAME_OVER,
  EVENT_PLAYER_ROW_CHANGED,
  EVENT_PLAYER_START,
} from "../GameEvents";
import { INV_MILLIS_PER_SEC } from "../Utils/MathUtils";
const crossing_time_coef = 2;
export default class SnowBallRail extends Object3D {
  active = false;
  snowBalls = [];
  resetDelay = 0.25;

  row = 0;
  top = 0.3;

  getWidth = (mesh) => {
    let box3 = new Box3();
    box3.setFromObject(mesh);
    // console.log( box.min, box.max, box.size() );
    return Math.round(box3.max.z - box3.min.z);
  };

  snowBallGen = () => {
    this.snowBalls.map((val) => {
      this.road.remove(val.mesh);
      val = null;
    });
    this.snowBalls = [];

    // Speeds: .01 through .08
    // Number of cars: 1 through 3
    let speed = 80 / crossing_time_coef;
    let numBalls = 1; //Math.floor(Math.random() * 2) + 1;
    let xDir = 1;

    let xPos = -6 * xDir;

    for (let x = 0; x < numBalls; x++) {
      if (this.snowBalls.length - 1 < x) {
        let mesh = ModelLoader._snowBall.getRandom();
        const width = this.getWidth(mesh);

        this.snowBalls.push({
          mesh,
          speed,
          dir: xDir,
          width,
          collisionBox: this.heroWidth / 2 + width / 2 - 0.1,
        });

        this.road.add(mesh);
      }

      this.snowBalls[x].mesh.position.set(xPos, 0.25, 0);
      this.snowBalls[x].speed = speed * xDir;
      this.snowBalls[x].mesh.rotation.y = (Math.PI / 2) * xDir;
      this.snowBalls[x].mesh.position.y += 0.25;

      xPos -= (Math.random() * 3 + 5) * xDir;
    }
  };

  constructor(heroWidth, onCollide) {
    super();
    this.heroWidth = heroWidth;
    this.onCollide = onCollide;
    const { _railroad } = ModelLoader;

    this.road = _railroad.getNode(); //_road.models['1'].children[0].clone();
    this.add(this.road);

    this.snowBallGen();

    this.active = false;

    gameEventEmitter.once(EVENT_PLAYER_START, () => {
      gameEventEmitter.on(EVENT_PLAYER_ROW_CHANGED, this.onRowChanged);
      gameEventEmitter.once(EVENT_GAME_OVER, () => {
        gameEventEmitter.off(EVENT_PLAYER_ROW_CHANGED, this.onRowChanged);
      });
    });
  }

  onRowChanged = (row) => {
    if (!this.active && this.row > 0 && Math.abs(this.row - row) < 3) {
      // console.log("~~~~ [PLAYER: on Row: SNOWBALL] ~~~~ activate",  row, this.row);
      this.randomActivate();
      this.snowBalls.map((snowball) => (snowball.mesh.visible = true));
    } else if (this.active && this.row > 0 && Math.abs(this.row - row) > 3) {
      // console.log("~~~~ [PLAYER: on Row: SNOWBALL] ~~~~ deactivate", row, this.row);
      setTimeout(() => {
        this.active = false;
        this.snowBalls.map((snowball) => (snowball.mesh.visible = false));
      }, 500);
    }
  };

  update = (dt, player) => {
    if (!this.active) {
      return;
    }
    if (Math.abs(player.row - this.row) > 3) {
      this.active = false;
      return;
    }
    this.snowBalls.map((snowBall) => this.drive({ dt, player, snowBall }));
  };

  drive = ({ dt, player, snowBall }) => {
    const { hitBy } = player;
    const offset = 15;

    dt = dt * INV_MILLIS_PER_SEC;

    snowBall.mesh.position.x +=
      snowBall.speed * dt * player.difficultyMultiplier;

    if (snowBall.mesh.position.x > offset && snowBall.speed > 0) {
      // console.log("~~~~ [SNOWBALL] ~~~~ offscreen", snowBall.mesh.position.x, snowBall.speed);
      this.active = false;
      snowBall.mesh.position.x = -offset;
      if (snowBall === hitBy) {
        player.hitBy = null;
      }
      this.randomActivate(Math.random() * 3);
    } else if (snowBall.mesh.position.x < -offset && snowBall.speed < 0) {
      // console.log("~~~~ [SNOWBALL] ~~~~ offscreen 2", snowBall.mesh.position.x, snowBall.speed);
      this.active = false;
      snowBall.mesh.position.x = offset;
      if (snowBall === hitBy) {
        player.hitBy = null;
      }
      this.randomActivate(Math.random() * 3);
    } else {
      this.shouldCheckCollision({ player, snowBall });
    }
  };

  shouldCheckCollision = ({ player, snowBall }) => {
    if (Math.round(player.position.z) == this.position.z && player.isAlive) {
      const { mesh, collisionBox } = snowBall;

      if (
        player.position.x < mesh.position.x + collisionBox &&
        player.position.x > mesh.position.x - collisionBox
      ) {
        player.collideWithCar(this, snowBall);
        this.onCollide(snowBall, "feathers", "car");
      }
    }
  };

  randomActivate = (offset = 0) => {
    const random = Math.round(Math.random() * this.resetDelay + offset);
    // setTimeout(()=> {
    //     AudioManager.playGemSound();
    // }, (random - 1) * 1000);
    setTimeout(() => (this.active = true), random * 1000);
  };
}
