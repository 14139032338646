import React, { useContext, useEffect, FC, useRef, useMemo } from "react";
import {
  Animated,
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from "react-native";
import { GameContext, gameEventEmitter } from "../../context/GameContext";
import {
  EVENT_GAME_STATE_CHANGE,
  EVENT_RANDOM_TRAITS,
} from "../../src/GameEvents";
import PlatformScaler from "../../components/PlatformScaler";
import pudgyWorldlogoImg from "../../assets/images/ui/PudgyWorldPreAlphaLogo.png";
import penguDashLogoImg from "../../assets/images/ui/penguDashLogo.png";
import gameStartButtonImg from "../../assets/images/ui/gameStartButton.png";
import tapImg from "../../assets/images/ui/tap.png";
import Score from "../../components/ScoreText";
import CoinText from "../../components/CoinText";
import PauseButton from "../../components/PauseButton";
import SoundButton from "../../components/SoundButton";
import state from "../../src/state";
import useBreakpoint from "../../src/hooks/useBreakpoint";
import styles from "./styles";

type Props = {
  onPlay: () => void;
  gameState: string;
};

const HomeScreen: FC<Props> = (props) => {
  const { triggerEvent } = useContext(GameContext);
  const dimensions = useWindowDimensions();
  const pudgyWorldY = useRef(new Animated.Value(-300)).current;
  const penguDashX = useRef(new Animated.Value(0)).current;
  const penguDashScale = useRef(new Animated.Value(0)).current;
  const startButtonY = useRef(new Animated.Value(300)).current;
  const scoreY = useRef(new Animated.Value(-300)).current;

  const breakpoint = useBreakpoint();
  const style = useMemo(() => styles(breakpoint), [breakpoint]);

  const handleLogoPress = () => {
    gameEventEmitter.emit(
      EVENT_GAME_STATE_CHANGE,
      state.Game.returningToModeSelect
    );
  };

  const startInitialTransitions = () => {
    Animated.spring(penguDashScale, {
      toValue: 1,
      useNativeDriver: true,
    }).start();
    Animated.spring(startButtonY, {
      toValue: 0,
      useNativeDriver: true,
    }).start();
    Animated.spring(pudgyWorldY, {
      toValue: 0,
      useNativeDriver: true,
    }).start();
    Animated.spring(penguDashX, {
      toValue: 0,
      useNativeDriver: true,
    }).start();
    Animated.spring(scoreY, {
      toValue: -300,
      useNativeDriver: true,
    }).start();
  };

  const startGame = () => {
    if (props.gameState === state.Game.none) {
      props.onPlay();
    }

    Animated.spring(penguDashX, {
      toValue: dimensions.width,
      useNativeDriver: true,
    }).start();
    Animated.spring(penguDashScale, {
      toValue: 0,
      useNativeDriver: true,
    }).start();
    Animated.spring(startButtonY, {
      toValue: 300,
      useNativeDriver: true,
    }).start();
    Animated.spring(scoreY, {
      toValue: 0,
      useNativeDriver: true,
    }).start();

    setTimeout(() => {
      Animated.spring(penguDashX, {
        toValue: 0,
        useNativeDriver: true,
      }).start();
    }, 1000);
  };

  useEffect(() => {
    if (props.gameState === state.Game.none) {
      startInitialTransitions();
    }
  }, [props.gameState]);

  useEffect(() => {
    function onKeyUp({ keyCode }) {
      // Space, up-arrow
      setTimeout(() => {
        if (props.gameState !== state.Game.none) return;

        if ([32, 38, 87].includes(keyCode)) {
          startGame();
        } else if (keyCode === 70) {
          triggerEvent(EVENT_RANDOM_TRAITS);
        }
      }, 0);
    }

    window.addEventListener("keyup", onKeyUp, false);
    return () => {
      window.removeEventListener("keyup", onKeyUp);
    };
  }, []);

  if (
    props.gameState === state.Game.playing ||
    props.gameState === state.Game.none
  )
    return (
      <View
        style={style.container}
        pointerEvents="box-none"
        // @ts-expect-error works on web
        onClick={() => {
          if (props.gameState === state.Game.none) {
            startGame();
          }
        }}
      >
        <View style={style.header}>
          <Animated.View style={{ transform: [{ translateY: scoreY }] }}>
            <Score />
          </Animated.View>
          <Animated.View style={{ transform: [{ translateY: pudgyWorldY }] }}>
            <TouchableOpacity
              onPress={handleLogoPress}
              style={style.logoWrapper}
            >
              <Image source={pudgyWorldlogoImg} style={style.logo} />
            </TouchableOpacity>
          </Animated.View>
          <Animated.View style={{ transform: [{ translateY: scoreY }] }}>
            <CoinText />
            <View
              style={{
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <SoundButton />
              <PauseButton />
            </View>
          </Animated.View>
        </View>
        <Animated.View
          style={[
            style.dashLogoWrapper,
            {
              transform: [
                {
                  translateX: penguDashX,
                },
                {
                  scale: penguDashScale,
                },
              ],
            },
          ]}
        >
          <PlatformScaler>
            <Image source={penguDashLogoImg} style={style.dashLogo} />
          </PlatformScaler>
        </Animated.View>
        <Animated.View style={{ transform: [{ translateY: startButtonY }] }}>
          <TouchableOpacity onPress={startGame} style={style.startButton}>
            <Text style={style.startButtonText}>
              {breakpoint === "desktop" ? "Click" : "Tap"}
            </Text>
            <Image
              source={breakpoint === "desktop" ? gameStartButtonImg : tapImg}
              style={style.gameStartIcon}
            />
            <Text style={style.startButtonText}>To start the game</Text>
          </TouchableOpacity>
        </Animated.View>
      </View>
    );

  return null;
};

export default HomeScreen;
