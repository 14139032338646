import { StyleSheet } from "react-native";
import selectBreakpoint from "../../../src/Utils/selectBreakpoint";

const styles = (breakpoint: Breakpoint) =>
  StyleSheet.create({
    container: {
      backgroundColor: "#EBF5FF",
      justifyContent: "center",
      ...selectBreakpoint(breakpoint, {
        desktop: {
          paddingHorizontal: 42,
          paddingVertical: 16,
        },
        tablet: {
          paddingHorizontal: 42,
          paddingVertical: 16,
        },
        mobile: {
          paddingHorizontal: 8,
          paddingVertical: 8,
        },
      }),
    },
    indicator: {
      position: "absolute",
      borderWidth: 3,
      borderColor: "#ffffff",
      borderRadius: 12,
      zIndex: 2,
      overflow: "hidden",
      boxShadow:
        "rgba(0, 135, 211, 0.40) 0px 0px 3px 0px inset, #ffffff60 0px 4px 15px",
    },
    indicatorLines: {
      width: "150%",
      height: "150%",
    },
    buttonsContainer: {
      flexDirection: "row",
      borderWidth: 3,
      borderColor: "#ffffff",
      borderRadius: 20,
      boxShadow: "0px 4px 4px 0px rgba(128, 171, 255, 0.80) inset",
    },
    button: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      ...selectBreakpoint(breakpoint, {
        desktop: {
          paddingHorizontal: 16,
          paddingVertical: 24,
        },
        tablet: {
          paddingHorizontal: 16,
          paddingVertical: 24,
        },
        mobile: {
          paddingHorizontal: 16,
          paddingVertical: 16,
        },
      }),
    },
    buttonText: {
      fontFamily: "menco_black",
      fontSize: 24,
      color: "#ffffff",
    },
    disabledButtonText: {
      color: "gray",
    },
  });

export default styles;
