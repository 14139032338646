import { ScrollView, StyleSheet, Text, View } from "react-native";
import firstPlaceImg from "../../../assets/images/ui/1st-place.png";
import secondPlaceImg from "../../../assets/images/ui/2nd-place.png";
import thirdPlaceImg from "../../../assets/images/ui/3rd-place.png";

import { FC, useMemo } from "react";
import LeaderboardRankRow from "../LeaderboardRankRow";
import useBreakpoint from "../../../src/hooks/useBreakpoint";
import styles from "./styles";
import { LeaderboardRow } from "../../../src/Models/LeaderBoardModel";

interface Props {
  data: LeaderboardRow[];
  currentUsersName: string;
}
const LeaderboardRank: FC<Props> = ({ data, currentUsersName }) => {
  const breakpoint = useBreakpoint();
  const style = useMemo(() => styles(breakpoint), [breakpoint]);

  const medalImage = (index: number) => {
    const medals = [firstPlaceImg, secondPlaceImg, thirdPlaceImg];

    return medals[index];
  };

  const me = useMemo(() => {
    const index = data.findIndex((item) => item.nameText === currentUsersName);
    return index === -1
      ? null
      : {
          data: data[index],
          index: index,
        };
  }, [data]);

  return (
    <View style={style.container}>
      <View style={style.headerContainer}>
        <View style={style.headerRank}>
          <Text style={style.headerItemText}>Rank</Text>
        </View>
        <View style={style.headerReward}>
          <Text style={style.headerItemText}>Reward</Text>
        </View>
        <View style={style.headerHighScore}>
          <Text style={style.headerItemText}>High Score</Text>
        </View>
      </View>
      <ScrollView
        style={style.bodyContainer}
        stickyHeaderIndices={[me?.index || -1]}
      >
        {data.map((item, index) => (
          <View
            key={item.id}
            style={{
              backgroundColor:
                item.nameText === currentUsersName ? "#ffffff" : "transparent",
            }}
          >
            <LeaderboardRankRow
              data={item}
              index={index}
              medalImage={medalImage(index)}
              isLast={index + 1 === data.length}
              breakpoint={breakpoint}
            />
          </View>
        ))}
      </ScrollView>
    </View>
  );
};

export default LeaderboardRank;
