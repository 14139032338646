import React, { useContext, useEffect, FC, useRef, useMemo } from "react";
import {
  Animated,
  Image,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from "react-native";
import { GameContext, gameEventEmitter } from "../../context/GameContext";
import { EVENT_GAME_STATE_CHANGE } from "../../src/GameEvents";
import PlatformScaler from "../../components/PlatformScaler";
import pudgyWorldlogoImg from "../../assets/images/ui/PudgyWorldPreAlphaLogo.png";
import penguDashLogoImg from "../../assets/images/ui/penguDashLogo.png";
import Score from "../../components/ScoreText";
import CoinText from "../../components/CoinText";
import PauseButton from "../../components/PauseButton";
import SoundButton from "../../components/SoundButton";
import state from "../../src/state";
import useBreakpoint from "../../src/hooks/useBreakpoint";
import styles from "./styles";

const GamePlayingScreen: FC<{}> = (props) => {
  const { triggerEvent } = useContext(GameContext);
  const dimensions = useWindowDimensions();
  const pudgyWorldY = useRef(new Animated.Value(-300)).current;
  const penguDashX = useRef(new Animated.Value(0)).current;
  const penguDashScale = useRef(new Animated.Value(0)).current;
  const startButtonY = useRef(new Animated.Value(300)).current;
  const scoreY = useRef(new Animated.Value(-300)).current;

  const breakpoint = useBreakpoint();
  const style = useMemo(() => styles(breakpoint), [breakpoint]);

  const handleLogoPress = () => {
    gameEventEmitter.emit(
      EVENT_GAME_STATE_CHANGE,
      state.Game.returningToModeSelect,
    );
  };

  const startInitialTransitions = async () => {
    return new Promise<void>((resolve) => {
      Animated.spring(penguDashScale, {
        toValue: 1,
        useNativeDriver: true,
      }).start();
      Animated.spring(pudgyWorldY, {
        toValue: 0,
        useNativeDriver: true,
      }).start();
      Animated.spring(penguDashX, {
        toValue: 0,
        useNativeDriver: true,
      }).start();
      Animated.spring(scoreY, {
        toValue: 0,
        useNativeDriver: true,
      }).start((finished) => {
        resolve();
      });
    });
  };

  const startGame = () => {
    Animated.spring(penguDashX, {
      toValue: dimensions.width,
      useNativeDriver: true,
    }).start();
    Animated.spring(penguDashScale, {
      toValue: 0,
      useNativeDriver: true,
    }).start();
    Animated.spring(startButtonY, {
      toValue: 300,
      useNativeDriver: true,
    }).start();
    Animated.spring(scoreY, {
      toValue: 0,
      useNativeDriver: true,
    }).start();

    setTimeout(() => {
      Animated.spring(penguDashX, {
        toValue: 0,
        useNativeDriver: true,
      }).start();
    }, 1000);
  };

  useEffect(() => {
    startInitialTransitions().then(() => {
      startGame();
    });
  }, []);

  return (
    <View style={style.container} pointerEvents="box-none">
      <View style={style.header}>
        <Animated.View style={{ transform: [{ translateY: scoreY }] }}>
          <Score />
        </Animated.View>
        <Animated.View style={{ transform: [{ translateY: pudgyWorldY }] }}>
          <TouchableOpacity onPress={handleLogoPress} style={style.logoWrapper}>
            <Image source={pudgyWorldlogoImg} style={style.logo} />
          </TouchableOpacity>
        </Animated.View>
        <Animated.View style={{ transform: [{ translateY: scoreY }] }}>
          <CoinText />
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <SoundButton />
            <PauseButton />
          </View>
        </Animated.View>
      </View>
    </View>
  );
};

export default GamePlayingScreen;
