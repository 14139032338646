import Generic from "./Generic";

export default class Log extends Generic {
  logCount = 0;
  setup = async () => {
    const {
      environment: { log },
    } = this.globalModels;

    const numberOfLogModels = Object.keys(log).length;

    for (let i = 0; i < numberOfLogModels; i++) {
      const key = `${i}`;
      await this._register(key, {
        ...log[`${i}`],
        castShadow: true,
        receiveShadow: true,
      });

      // todo: setup generic alteration function as part of Models.js
      const model = this.models[key];
      if (model) {
        model.rotation.y = Math.PI / 2;
        if (key < 3) {
          model.scale.x *= 0.8; // scale down x by 20%
          model.scale.y *= 0.8; // scale down y by 20%
          model.scale.z *= 0.8; // scale down z by 20%
        } else {
          model.position.y += 0.5;
        }
      }
    }
    return this.models;
  };

  getRandomLog = () => {
    let key;
    console.log("~~~ [LOGS] log count", this.logCount);

    // If it's the third log, pick a split log
    if (this.logCount % 3 === 2) {
      const splitLogs = ["3", "4", "5"];
      key = splitLogs[Math.floor(Math.random() * splitLogs.length)];
    } else {
      // Exclude split logs for random selection
      const nonSplitLogs = ["0", "1", "2"];
      key = nonSplitLogs[Math.floor(Math.random() * nonSplitLogs.length)];
    }

    this.logCount++; // Increase the count after selecting a log
    console.log("~~~ [LOGS] Chosen Key", key);
    return this.models[key].clone();
  };
}
