import { FC, useMemo } from "react";
import { Image, View } from "react-native";
import controlsImage from "../../assets/images/ui/controls-desktop.png";
import controlsMobileImage from "../../assets/images/ui/controls-mobile.png";
import { gameEventEmitter } from "../../context/GameContext";
import { EVENT_GAME_STATE_CHANGE } from "../../src/GameEvents";
import state from "../../src/state";
import useBreakpoint from "../../src/hooks/useBreakpoint";
import styles from "./styles";
import BackButton from "../../components/BackButton";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { useMobileDetect } from "../../src/hooks/useMobileDetect";

interface Props {}
const ControlsScreen: FC<Props> = () => {
  const navigation = useNavigation<StackNavigationProp<Navigation.Route>>();
  const route = useRoute<RouteProp<Navigation.Route, "Controls">>();

  const device = useMobileDetect();
  const breakpoint = useBreakpoint();
  const style = useMemo(() => styles(breakpoint), [breakpoint]);

  const handleBack = () => {
    console.log("route params", route.params);
    if (route.params?.shouldGoGameMode) {
      navigation.navigate("GameMode");
    } else {
      gameEventEmitter.emit(EVENT_GAME_STATE_CHANGE, state.Game.paused);
    }
  };

  return (
    <View style={style.container}>
      <BackButton onPress={handleBack} />
      <View style={style.wrapper}>
        <Image
          source={device.isDesktop() ? controlsImage : controlsMobileImage}
          style={style.controlsImage}
        />
      </View>
    </View>
  );
};

export default ControlsScreen;
