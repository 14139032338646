const mainTexture = require("../assets/models/textures/Main.png");
const snowBallTexture = require("../assets/models/vehicles/snow_balls/Snowball.png");

export default {
  environment: {
    grass: {
      0: {
        model: require("../assets/models/environment/grass/model.obj"),
        texture: require("../assets/models/environment/grass/light-grass.png"),
      },
      1: {
        model: require("../assets/models/environment/grass/model.obj"),
        texture: require("../assets/models/environment/grass/dark-grass.png"),
      },
    },
    road: {
      0: {
        model: require("../assets/models/environment/road/model.obj"),
        texture: require("../assets/models/environment/road/stripes-texture.png"),
      },
      1: {
        model: require("../assets/models/environment/road/model.obj"),
        texture: require("../assets/models/environment/road/blank-texture.png"),
      },
    },
    log: {
      0: {
        model: require("../assets/models/environment/log/custom/PudgyWorld_LargeLog1.obj"),
        texture: mainTexture,
      },
      1: {
        model: require("../assets/models/environment/log/custom/PudgyWorld_MediumLog1.obj"),
        texture: mainTexture,
      },
      2: {
        model: require("../assets/models/environment/log/custom/PudgyWorld_SmallLog1.obj"),
        texture: mainTexture,
      },
      3: {
        model: require("../assets/models/environment/log/custom/split/PudgyWorld_SmallHalfCutLog.obj"),
        texture: mainTexture,
      },
      4: {
        model: require("../assets/models/environment/log/custom/split/PudgyWorld_MediumHalfCutLog.obj"),
        texture: mainTexture,
      },
      5: {
        model: require("../assets/models/environment/log/custom/split/PudgyWorld_LargeHalfCutLog.obj"),
        texture: mainTexture,
      },
    },
    tree: {
      0: {
        model: require("../assets/models/environment/tree/0/0.obj"),
        texture: require("../assets/models/environment/tree/0/0.png"),
      },
      1: {
        model: require("../assets/models/environment/tree/1/0.obj"),
        texture: require("../assets/models/environment/tree/1/0.png"),
      },
      2: {
        model: require("../assets/models/environment/tree/2/0.obj"),
        texture: require("../assets/models/environment/tree/2/0.png"),
      },
      3: {
        model: require("../assets/models/environment/tree/3/0.obj"),
        texture: require("../assets/models/environment/tree/3/0.png"),
      },
    },
    lily_pad: {
      0: {
        model: require("../assets/models/environment/lily_pad/custom/PudgyWorld_IceChunk1.obj"),
        texture: mainTexture,
      },
      1: {
        model: require("../assets/models/environment/lily_pad/custom/PudgyWorld_IceChunk1.obj"),
        texture: mainTexture,
      },
      2: {
        model: require("../assets/models/environment/lily_pad/custom/PudgyWorld_IceChunk1.obj"),
        texture: mainTexture,
      },
      // model: require('../assets/models/environment/lily_pad/0.obj'),
      // texture: require('../assets/models/environment/lily_pad/0.png'),
    },

    river: {
      model: require("../assets/models/environment/river/0.obj"),
      texture: require("../assets/models/environment/river/0.png"),
    },
    railroad: {
      model: require("../assets/models/environment/railroad/0.obj"),
      texture: require("../assets/models/environment/railroad/0.png"),
    },
    train_light: {
      active: {
        0: {
          model: require("../assets/models/environment/train_light/active/0/0.obj"),
          texture: require("../assets/models/environment/train_light/active/0/0.png"),
        },
        1: {
          model: require("../assets/models/environment/train_light/active/1/0.obj"),
          texture: require("../assets/models/environment/train_light/active/1/0.png"),
        },
      },
      inactive: {
        model: require("../assets/models/environment/train_light/inactive/0.obj"),
        texture: require("../assets/models/environment/train_light/inactive/0.png"),
      },
    },
    boulder: {
      0: {
        model: require("../assets/models/environment/boulder/custom/PudgyWorld_Rock1.obj"),
        texture: mainTexture,
      },
      1: {
        model: require("../assets/models/environment/boulder/custom/PudgyWorld_Rock2.obj"),
        texture: mainTexture,
      },
      2: {
        model: require("../assets/models/environment/boulder/custom/PudgyWorld_Rock3.obj"),
        texture: mainTexture,
      },
    },
    coin: {
      0: {
        model: require("../assets/models/environment/coins/0/PudgyWorld_Coin1.obj"),
        texture: require("../assets/models/environment/coins/PudgyWorld_CoinTexture.png"),
      },
      1: {
        model: require("../assets/models/environment/coins/1/PudgyWorld_Coin2.obj"),
        texture: require("../assets/models/environment/coins/PudgyWorld_CoinTexture.png"),
      },
      2: {
        model: require("../assets/models/environment/coins/2/PudgyWorld_Coin3.obj"),
        texture: require("../assets/models/environment/coins/PudgyWorld_CoinTexture.png"),
      },
      3: {
        model: require("../assets/models/environment/coins/3/PudgyWorld_Coin4.obj"),
        texture: require("../assets/models/environment/coins/PudgyWorld_CoinTexture.png"),
      },
    },
  },
  vehicles: {
    train: {
      front: {
        model: require("../assets/models/vehicles/train/front/0.obj"),
        texture: require("../assets/models/vehicles/train/front/0.png"),
      },
      middle: {
        model: require("../assets/models/vehicles/train/middle/0.obj"),
        texture: require("../assets/models/vehicles/train/middle/0.png"),
      },
      back: {
        model: require("../assets/models/vehicles/train/back/0.obj"),
        texture: require("../assets/models/vehicles/train/back/0.png"),
      },
    },

    police_car: {
      model: require("../assets/models/vehicles/police_car/0.obj"),
      texture: require("../assets/models/vehicles/police_car/0.png"),
    },

    small_pudgy_rider_1: {
      model: require("../assets/models/vehicles/custom/penguin_riders/SmallSled2_Pudgy1.obj"),
      texture: require("../assets/models/vehicles/custom/penguin_riders/Penguin_Normal.png"),
    },
    small_pudgy_rider_2: {
      model: require("../assets/models/vehicles/custom/penguin_riders/SmallSled2_Pudgy2.obj"),
      texture: require("../assets/models/vehicles/custom/penguin_riders/PenguinBody_BabyPink_LP.png"),
    },
    small_pudgy_rider_3: {
      model: require("../assets/models/vehicles/custom/penguin_riders/SmallSled3_Pudgy.obj"),
      texture: require("../assets/models/vehicles/custom/penguin_riders/PenguinBody_Mint_LP.png"),
    },
    small_pudgy_rider_4: {
      model: require("../assets/models/vehicles/custom/penguin_riders/SmallSled3_Pudgy1.obj"),
      texture: require("../assets/models/vehicles/custom/penguin_riders/PenguinBody_Sparkles_LP.png"),
    },
    small_pudgy_rider_5: {
      model: require("../assets/models/vehicles/custom/penguin_riders/SmallSled3_Pudgy2.obj"),
      texture: require("../assets/models/vehicles/custom/penguin_riders/PenguinBody_Iridiscent_Base_Color.png"),
    },
    medium_pudgy_rider_1: {
      model: require("../assets/models/vehicles/custom/penguin_riders/MediumSled2_Pudgy1.obj"),
      texture: require("../assets/models/vehicles/custom/penguin_riders/Penguin_Normal 2.png"),
    },
    medium_pudgy_rider_2: {
      model: require("../assets/models/vehicles/custom/penguin_riders/MediumSled2_Pudgy1.obj"),
      texture: require("../assets/models/vehicles/custom/penguin_riders/PenguinBody_CloudPattern_Base_Color.png"),
    },
    medium_pudgy_rider_3: {
      model: require("../assets/models/vehicles/custom/penguin_riders/MediumSled2_Pudgy1.obj"),
      texture: require("../assets/models/vehicles/custom/penguin_riders/PenguinBody_Gold_LP.png"),
    },
    medium_pudgy_rider_4: {
      model: require("../assets/models/vehicles/custom/penguin_riders/MediumSled2_Pudgy1.obj"),
      texture: require("../assets/models/vehicles/custom/penguin_riders/PenguinBody_Mint_LP.png"),
    },
    medium_pudgy_rider_5: {
      model: require("../assets/models/vehicles/custom/penguin_riders/MediumSled2_Pudgy1.obj"),
      texture: require("../assets/models/vehicles/custom/penguin_riders/PenguinBody_Ice_LP.png"),
    },
    large_pudgy_rider_1: {
      model: require("../assets/models/vehicles/custom/penguin_riders/LargeSled2_Pudgy1.obj"),
      texture: require("../assets/models/vehicles/custom/penguin_riders/PenguinBody_BabyPink_LP_Large.png"),
    },
    large_pudgy_rider_2: {
      model: require("../assets/models/vehicles/custom/penguin_riders/LargeSled2_Pudgy1.obj"),
      texture: require("../assets/models/vehicles/custom/penguin_riders/PenguinBody_Gold_LP_Large.png"),
    },
    large_pudgy_rider_3: {
      model: require("../assets/models/vehicles/custom/penguin_riders/LargeSled2_Pudgy1.obj"),
      texture: require("../assets/models/vehicles/custom/penguin_riders/Penguin_Normal_Large.png"),
    },

    medium_sleds: {
      model: require("../assets/models/vehicles/custom/PudgyWorld_MediumSled1.obj"),
      texture: mainTexture,
      afterLoad: (model) => {
        model.scale.x *= 0.3; // scale down x by 20%
        model.scale.y *= 0.3; // scale down y by 20%
        model.scale.z *= 0.3; // scale down z by 20%
      },
    },
    blue_truck: {
      model: require("../assets/models/vehicles/blue_truck/0.obj"),
      texture: require("../assets/models/vehicles/blue_truck/0.png"),
    },
    green_car: {
      model: require("../assets/models/vehicles/green_car/0.obj"),
      texture: require("../assets/models/vehicles/green_car/0.png"),
    },
    orange_car: {
      model: require("../assets/models/vehicles/orange_car/0.obj"),
      texture: require("../assets/models/vehicles/orange_car/0.png"),
    },
    purple_car: {
      model: require("../assets/models/vehicles/purple_car/0.obj"),
      texture: require("../assets/models/vehicles/purple_car/0.png"),
    },
    red_truck: {
      model: require("../assets/models/vehicles/red_truck/0.obj"),
      texture: require("../assets/models/vehicles/red_truck/0.png"),
    },
    short_sleds: {
      model: require("../assets/models/vehicles/custom/PudgyWorld_SmallSled1.obj"),
      texture: mainTexture,
      afterLoad: (model) => {
        model.scale.x *= 0.3; // scale down x by 20%
        model.scale.y *= 0.3; // scale down y by 20%
        model.scale.z *= 0.3; // scale down z by 20%
      },
    },
    large_sled: {
      model: require("../assets/models/vehicles/custom/PudgyWorld_LargeSled1.obj"),
      texture: mainTexture,
      afterLoad: (model) => {
        model.scale.x *= 0.3; // scale down x by 20%
        model.scale.y *= 0.3; // scale down y by 20%
        model.scale.z *= 0.3; // scale down z by 20%
      },
    },
    snow_balls: {
      0: {
        model: require("../assets/models/vehicles/snow_balls/SnowBall_1.obj"),
        texture: snowBallTexture,
      },
      1: {
        model: require("../assets/models/vehicles/snow_balls/SnowBall_2.obj"),
        texture: snowBallTexture,
      },
      2: {
        model: require("../assets/models/vehicles/snow_balls/SnowBall_3.obj"),
        texture: snowBallTexture,
      },
    },
  },
  characters: {
    brent: {
      model: require("../assets/models/characters/brent/0.obj"),
      texture: require("../assets/models/characters/brent/0.png"),
    },
    // chicken: {
    //   model: require('../assets/models/characters/chicken/0.obj'),
    //   texture: require('../assets/models/characters/chicken/0.png'),
    // },
    avocoder: {
      model: require("../assets/models/characters/avocoder/avocoder.obj"),
      texture: require("../assets/models/characters/avocoder/avocoder.png"),
    },
    bacon: {
      model: require("../assets/models/characters/bacon/bacon.obj"),
      texture: require("../assets/models/characters/bacon/bacon.png"),
    },
    wheeler: {
      model: require("../assets/models/characters/wheeler/wheeler.obj"),
      texture: require("../assets/models/characters/wheeler/wheeler.png"),
    },
    palmer: {
      model: require("../assets/models/characters/palmer/palmer.obj"),
      texture: require("../assets/models/characters/palmer/palmer.png"),
    },
    juwan: {
      model: require("../assets/models/characters/juwan/juwan.obj"),
      texture: require("../assets/models/characters/juwan/juwan.png"),
    },
    pudgypenguin: {
      model: require("../assets/models/characters/pudgypenguin/Pudgy_TS.obj"),
      texture: require("../assets/models/characters/pudgypenguin/Penguin_Normal.png"),
    },
  },
  toonShader: {
    TOON_TONE: require("../assets/images/fiveTone.jpg"),
  },
  traits: {
    Normal_Default: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Face/Normal_Default.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Face/Textures/Normal_Default.png",
      id: -2,
      name: "Normal",
      type: "face",
      urlId: "Normal_Default",
      rarity_level: "default",
    },
    PolarBear_Default: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/PolarBear_Default.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/Textures/PolarBear_Default.png",
      id: -7,
      name: "Polar Bear",
      type: "head",
      urlId: "PolarBear_Default",
      rarity_level: "default",
    },
    Cute_Default: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Face/Cute_Default.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Face/Textures/Cute_Default.png",
      id: -5,
      name: "Cute",
      type: "face",
      urlId: "Cute_Default",
      rarity_level: "default",
    },
    FurCoat_Rare: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/FurCoat_Rare.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/Textures/FurCoat_Rare.png",
      id: 1,
      name: "Fur Coat",
      type: "body",
      urlId: "FurCoat_Rare",
      rarity_level: "rare",
    },
    Gold_Uncommon: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Penguin(Color)/Gold_Uncommon.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Penguin(Color)/Textures/Gold_Uncommon.png",
      id: 3,
      name: "Gold",
      type: "skin",
      urlId: "Gold_Uncommon",
      rarity_level: "uncommon",
    },
    Mint_Common: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Penguin(Color)/Mint_Common.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Penguin(Color)/Textures/Mint_Common.png",
      id: 5,
      name: "Mint",
      type: "skin",
      urlId: "Mint_Common",
      rarity_level: "common",
    },
    Clouds_Uncommon: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Penguin(Color)/Clouds_Uncommon.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Penguin(Color)/Textures/Clouds_Uncommon.png",
      id: 6,
      name: "Clouds",
      type: "skin",
      urlId: "Clouds_Uncommon",
      rarity_level: "uncommon",
    },
    Sparkles_Rare: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Penguin(Color)/Sparkles_Rare.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Penguin(Color)/Textures/Sparkles_Rare.png",
      id: 7,
      name: "Sparkles",
      type: "skin",
      urlId: "Sparkles_Rare",
      rarity_level: "rare",
    },
    Iridescent_Epic: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Penguin(Color)/Iridescent_Epic.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Penguin(Color)/Textures/Iridescent_Epic.png",
      id: 8,
      name: "Iridescent",
      type: "skin",
      urlId: "Iridescent_Epic",
      rarity_level: "epic",
    },
    BowlCut_Uncommon: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/BowlCut_Uncommon.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/Textures/BowlCut_Uncommon.png",
      id: 9,
      name: "Bowl Cut",
      type: "head",
      urlId: "BowlCut_Uncommon",
      rarity_level: "uncommon",
    },
    SushiShirt_Uncommon: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/SushiShirt_Uncommon.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/Textures/SushiShirt_Uncommon.png",
      id: 13,
      name: "Sushi Shirt",
      type: "body",
      urlId: "SushiShirt_Uncommon",
      rarity_level: "uncommon",
    },
    IceCrown_Rare: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/IceCrown_Rare.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/Textures/IceCrown_Rare.png",
      id: 11,
      name: "Ice Crown",
      type: "head",
      urlId: "IceCrown_Rare",
      rarity_level: "rare",
    },
    SpinnyHat_Common: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/SpinnyHat_Common.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/Textures/SpinnyHat_Common.png",
      id: 15,
      name: "Spinny Hat",
      type: "head",
      urlId: "SpinnyHat_Common",
      rarity_level: "common",
    },
    Fedora_Common: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/Fedora_Common.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/Textures/Fedora_Common.png",
      id: 17,
      name: "Fedora",
      type: "head",
      urlId: "Fedora_Common",
      rarity_level: "common",
    },
    UniswapUnicorn_Epic: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/UniswapUnicorn_Epic.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/Textures/UniswapUnicorn_Epic.png",
      id: 20,
      name: "Uniswap Unicorn",
      type: "head",
      urlId: "UniswapUnicorn_Epic",
      rarity_level: "epic",
    },
    Cloud_Rare: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/Cloud_Rare.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/Textures/Cloud_Rare.png",
      id: 22,
      name: "Cloud",
      type: "head",
      urlId: "Cloud_Rare",
      rarity_level: "rare",
    },
    AstronautHelmet_Epic: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/AstronautHelmet_Epic.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/Textures/AstronautHelmet_Epic.png",
      id: 23,
      name: "Astronaut Helmet",
      type: "head",
      urlId: "AstronautHelmet_Epic",
      rarity_level: "epic",
    },
    CircleGlasses_Common: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Face/CircleGlasses_Common.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Face/Textures/CircleGlasses_Common.png",
      id: 26,
      name: "Circle Glasses",
      type: "face",
      urlId: "CircleGlasses_Common",
      rarity_level: "common",
    },
    CrossEyed_Common: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Face/CrossEyed_Common.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Face/Textures/CrossEyed_Common.png",
      id: 28,
      name: "Cross Eyed",
      type: "face",
      urlId: "CrossEyed_Common",
      rarity_level: "common",
    },
    Scar_Common: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Face/Scar_Common.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Face/Textures/Scar_Common.png",
      id: 29,
      name: "Scar",
      type: "face",
      urlId: "Scar_Common",
      rarity_level: "common",
    },
    Snorkel_Epic: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Face/Snorkel_Epic.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Face/Textures/Snorkel_Epic.png",
      id: 31,
      name: "Snorkel",
      type: "face",
      urlId: "Snorkel_Epic",
      rarity_level: "epic",
    },
    NounsGlasses_Uncommon: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Face/NounsGlasses_Uncommon.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Face/Textures/NounsGlasses_Uncommon.png",
      id: 32,
      name: "Nouns Glasses",
      type: "face",
      urlId: "NounsGlasses_Uncommon",
      rarity_level: "uncommon",
    },
    GoldMedal_Uncommon: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/GoldMedal_Uncommon.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/Textures/GoldMedal_Uncommon.png",
      id: 35,
      name: "Gold Medal",
      type: "body",
      urlId: "GoldMedal_Uncommon",
      rarity_level: "uncommon",
    },
    PudgyMan_Rare: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/PudgyMan_Rare.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/Textures/PudgyMan_Rare.png",
      id: 37,
      name: "Pudgy Man",
      type: "body",
      urlId: "PudgyMan_Rare",
      rarity_level: "rare",
    },
    BasketballJersey_Common: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/BasketballJersey_Common.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/Textures/BasketballJersey_Common.png",
      id: 39,
      name: "Basketball Jersey",
      type: "body",
      urlId: "BasketballJersey_Common",
      rarity_level: "common",
    },
    L0Hoodie_Common: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/L0Hoodie_Common.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/Textures/L0Hoodie_Common.png",
      id: 40,
      name: "L0 Hoodie",
      type: "body",
      urlId: "L0Hoodie_Common",
      rarity_level: "common",
    },
    "PythiaT-Shirt_Common": {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/PythiaT-Shirt_Common.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/Textures/PythiaT-Shirt_Common.png",
      id: 41,
      name: "Pythia T-Shirt",
      type: "body",
      urlId: "PythiaT-Shirt_Common",
      rarity_level: "common",
    },
    "60sVest_Uncommon": {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/60sVest_Uncommon.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/Textures/60sVest_Uncommon.png",
      id: 43,
      name: "60s Vest",
      type: "body",
      urlId: "60sVest_Uncommon",
      rarity_level: "uncommon",
    },
    BlackSothebysSuit_Common: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/BlackSotheby%60sSuit_Common.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/Textures/BlackSotheby%60sSuit_Common.png",
      id: 45,
      name: "Black Sotheby's Suit",
      type: "body",
      urlId: "BlackSotheby'sSuit_Common",
      rarity_level: "common",
    },
    Backpack_Uncommon: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/Backpack_Uncommon.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/Textures/Backpack_Uncommon.png",
      id: 47,
      name: "Backpack",
      type: "body",
      urlId: "Backpack_Uncommon",
      rarity_level: "uncommon",
    },
    BlackAndWhite_Default: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Penguin(Color)/BlackAndWhite_Default.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Penguin(Color)/Textures/BlackAndWhite_Default.png",
      id: -4,
      name: "Black and White",
      type: "skin",
      urlId: "BlackAndWhite_Default",
      rarity_level: "default",
    },
    CowboyHat_Default: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/CowboyHat_Default.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/Textures/CowboyHat_Default.png",
      id: -6,
      name: "Cowboy Hat",
      type: "head",
      urlId: "CowboyHat_Default",
      rarity_level: "default",
    },
    AstronautSuit_Epic: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/AstronautSuit_Epic.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/Textures/AstronautSuit_Epic.png",
      id: 0,
      name: "Astronaut Suit",
      type: "body",
      urlId: "AstronautSuit_Epic",
      rarity_level: "epic",
    },
    Ice_Rare: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Penguin(Color)/Ice_Rare.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Penguin(Color)/Textures/Ice_Rare.png",
      id: 2,
      name: "Ice",
      type: "skin",
      urlId: "Ice_Rare",
      rarity_level: "rare",
    },
    BabyPink_Common: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Penguin(Color)/BabyPink_Common.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Penguin(Color)/Textures/BabyPink_Common.png",
      id: 4,
      name: "Baby Pink",
      type: "skin",
      urlId: "BabyPink_Common",
      rarity_level: "common",
    },
    WalkmanHeadphones_Common: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/WalkmanHeadphones_Common.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/Textures/WalkmanHeadphones_Common.png",
      id: 48,
      name: "Walkman Headphones",
      type: "head",
      urlId: "WalkmanHeadphones_Common",
      rarity_level: "common",
    },
    BobbleHat_Common: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/BobbleHat_Common.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/Textures/BobbleHat_Common.png",
      id: 64,
      name: "Bobble Hat",
      type: "head",
      urlId: "BobbleHat_Common",
      rarity_level: "common",
    },
    HeadBand_Common: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/HeadBand_Common.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/Textures/HeadBand_Common.png",
      id: 63,
      name: "Head Band",
      type: "head",
      urlId: "HeadBand_Common",
      rarity_level: "common",
    },
    VShapedGlasses_Common: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Face/VShapedGlasses_Common.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Face/Textures/VShapedGlasses_Common.png",
      id: 62,
      name: "V Shaped Glasses",
      type: "face",
      urlId: "VShapedGlasses_Common",
      rarity_level: "common",
    },
    Scarf_Common: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/Scarf_Common.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/Textures/Scarf_Common.png",
      id: 61,
      name: "Scarf",
      type: "body",
      urlId: "Scarf_Common",
      rarity_level: "common",
    },
    FurryHat_Epic: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/FurryHat_Epic.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/Textures/FurryHat_Epic.png",
      id: 60,
      name: "Furry Hat",
      type: "head",
      urlId: "FurryHat_Epic",
      rarity_level: "epic",
    },
    HazmatSuit_Epic: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/HazmatSuit_Epic.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/Textures/HazmatSuit_Epic.png",
      id: 59,
      name: "Hazmat Suit",
      type: "body",
      urlId: "HazmatSuit_Epic",
      rarity_level: "epic",
    },
    FuturisticSuit_Rare: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/FuturisticSuit_Rare.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/Textures/FuturisticSuit_Rare.png",
      id: 58,
      name: "Futuristic Suit",
      type: "body",
      urlId: "FuturisticSuit_Rare",
      rarity_level: "rare",
    },
    FuturisticMask_Rare: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Face/FuturisticMask_Rare.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Face/Textures/FuturisticMask_Rare.png",
      id: 57,
      name: "Futuristic Mask",
      type: "face",
      urlId: "FuturisticMask_Rare",
      rarity_level: "rare",
    },
    PancakeEyes_Uncommon: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Face/PancakeEyes_Uncommon.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Face/Textures/PancakeEyes_Uncommon.png",
      id: 56,
      name: "Pancake Eyes",
      type: "face",
      urlId: "PancakeEyes_Uncommon",
      rarity_level: "uncommon",
    },
    GrizzlyBucketHat_Uncommon: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/GrizzlyBucketHat_Uncommon.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/Textures/GrizzlyBucketHat_Uncommon.png",
      id: 55,
      name: "Grizzly Bucket Hat",
      type: "head",
      urlId: "GrizzlyBucketHat_Uncommon",
      rarity_level: "uncommon",
    },
    SilverMedal_Uncommon: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/SilverMedal_Uncommon.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/Textures/SilverMedal_Uncommon.png",
      id: 54,
      name: "Silver Medal",
      type: "body",
      urlId: "SilverMedal_Uncommon",
      rarity_level: "uncommon",
    },
    Pancakes_Common: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/Pancakes_Common.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/Textures/Pancakes_Common.png",
      id: 53,
      name: "Pancakes",
      type: "head",
      urlId: "Pancakes_Common",
      rarity_level: "common",
    },
    GrizzlyBear_Common: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/GrizzlyBear_Common.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/Textures/GrizzlyBear_Common.png",
      id: 52,
      name: "Grizzly Bear",
      type: "head",
      urlId: "GrizzlyBear_Common",
      rarity_level: "common",
    },
    WhitePuffer_Common: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/WhitePuffer_Common.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/Textures/WhitePuffer_Common.png",
      id: 51,
      name: "White Puffer",
      type: "body",
      urlId: "WhitePuffer_Common",
      rarity_level: "common",
    },
    BlackPuffer_Common: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/BlackPuffer_Common.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/Textures/BlackPuffer_Common.png",
      id: 50,
      name: "Black Puffer",
      type: "body",
      urlId: "BlackPuffer_Common",
      rarity_level: "common",
    },
    BronzeMedal_Common: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/BronzeMedal_Common.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/Textures/BronzeMedal_Common.png",
      id: 49,
      name: "Bronze Medal",
      type: "body",
      urlId: "BronzeMedal_Common",
      rarity_level: "common",
    },
    BoaterStrawHat_Uncommon: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/BoaterStrawHat_Uncommon.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/Textures/BoaterStrawHat_Uncommon.png",
      id: 70,
      name: "Boater Straw Hat",
      type: "head",
      urlId: "BoaterStrawHat_Uncommon",
      rarity_level: "uncommon",
    },
    BikerJacket_Default: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/BikerJacket_Default.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/Textures/BikerJacket_Default.png",
      id: -9,
      name: "Biker Jacket",
      type: "body",
      urlId: "BikerJacket_Default",
      rarity_level: "default",
    },
    Vote4Pudgy_Default: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/Vote4Pudgy_Default.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/Textures/Vote4Pudgy_Default.png",
      id: -8,
      name: "Vote 4 Pudgy",
      type: "body",
      urlId: "Vote4Pudgy_Default",
      rarity_level: "default",
    },
    WizardRobe_Rare: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/WizardRobe_Rare.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/Textures/WizardRobe_Rare.png",
      id: 10,
      name: "Wizard Robe",
      type: "body",
      urlId: "WizardRobe_Rare",
      rarity_level: "rare",
    },
    GoldEgg_Uncommon: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/GoldEgg_Uncommon.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/Textures/GoldEgg_Uncommon.png",
      id: 12,
      name: "Gold Egg",
      type: "head",
      urlId: "GoldEgg_Uncommon",
      rarity_level: "uncommon",
    },
    EarMuffs_Common: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/EarMuffs_Common.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/Textures/EarMuffs_Common.png",
      id: 14,
      name: "Ear Muffs",
      type: "head",
      urlId: "EarMuffs_Common",
      rarity_level: "common",
    },
    WhaleHat_Common: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/WhaleHat_Common.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/Textures/WhaleHat_Common.png",
      id: 16,
      name: "Whale Hat",
      type: "head",
      urlId: "WhaleHat_Common",
      rarity_level: "common",
    },
    MoonBird_Rare: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/MoonBird_Rare.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/Textures/MoonBird_Rare.png",
      id: 18,
      name: "CC0 Bird",
      type: "head",
      urlId: "MoonBird_Rare",
      rarity_level: "rare",
    },
    BucketHat_Uncommon: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/BucketHat_Uncommon.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/Textures/BucketHat_Uncommon.png",
      id: 19,
      name: "Bucket Hat",
      type: "head",
      urlId: "BucketHat_Uncommon",
      rarity_level: "uncommon",
    },
    WizardHat_Rare: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/WizardHat_Rare.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/Textures/WizardHat_Rare.png",
      id: 21,
      name: "Wizard Hat",
      type: "head",
      urlId: "WizardHat_Rare",
      rarity_level: "rare",
    },
    AngelWings_Uncommon: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/AngelWings_Uncommon.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/Textures/AngelWings_Uncommon.png",
      id: 24,
      name: "Angel Wings",
      type: "body",
      urlId: "AngelWings_Uncommon",
      rarity_level: "uncommon",
    },
    WalkmanStrap_Common: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/WalkmanStrap_Common.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/Textures/WalkmanStrap_Common.png",
      id: 25,
      name: "Walkman Strap",
      type: "body",
      urlId: "WalkmanStrap_Common",
      rarity_level: "common",
    },
    CloutGoggles_Common: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Face/CloutGoggles_Common.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Face/Textures/CloutGoggles_Common.png",
      id: 27,
      name: "Clout Goggles",
      type: "face",
      urlId: "CloutGoggles_Common",
      rarity_level: "common",
    },
    "3DGlasses_Uncommon": {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Face/3DGlasses_Uncommon.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Face/Textures/3DGlasses_Uncommon.png",
      id: 30,
      name: "3D Glasses",
      type: "face",
      urlId: "3DGlasses_Uncommon",
      rarity_level: "uncommon",
    },
    BionicGlasses_Uncommon: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Face/BionicGlasses_Uncommon.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Face/Textures/BionicGlasses_Uncommon.png",
      id: 33,
      name: "Bionic Glasses",
      type: "face",
      urlId: "BionicGlasses_Uncommon",
      rarity_level: "uncommon",
    },
    OrangeKimono_Common: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/OrangeKimono_Common.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/Textures/OrangeKimono_Common.png",
      id: 34,
      name: "Orange Kimono",
      type: "body",
      urlId: "OrangeKimono_Common",
      rarity_level: "common",
    },
    SushiGlasses_Rare: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Face/SushiGlasses_Rare.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Face/Textures/SushiGlasses_Rare.png",
      id: 36,
      name: "Sushi Glasses",
      type: "face",
      urlId: "SushiGlasses_Rare",
      rarity_level: "rare",
    },
    HoloApeGlasses_Rare: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Face/HoloApeGlasses_Rare.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Face/Textures/HoloApeGlasses_Rare.png",
      id: 38,
      name: "Holo Ape Glasses",
      type: "face",
      urlId: "HoloApeGlasses_Rare",
      rarity_level: "rare",
    },
    ScubaGear_Epic: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/ScubaGear_Epic.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/Textures/ScubaGear_Epic.png",
      id: 42,
      name: "Scuba Gear",
      type: "body",
      urlId: "ScubaGear_Epic",
      rarity_level: "epic",
    },
    Overalls_Uncommon: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/Overalls_Uncommon.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/Textures/Overalls_Uncommon.png",
      id: 72,
      name: "Overalls",
      type: "body",
      urlId: "Overalls_Uncommon",
      rarity_level: "uncommon",
    },
    PufferVest_Rare: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/PufferVest_Rare.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/Textures/PufferVest_Rare.png",
      id: 69,
      name: "Puffer Vest",
      type: "body",
      urlId: "PufferVest_Rare",
      rarity_level: "rare",
    },
    PirateHat_Rare: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/PirateHat_Rare.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/Textures/PirateHat_Rare.png",
      id: 68,
      name: "Pirate Hat",
      type: "head",
      urlId: "PirateHat_Rare",
      rarity_level: "rare",
    },
    ElPudgy_Epic: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/ElPudgy_Epic.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/Textures/ElPudgy_Epic.png",
      id: 67,
      name: "El Pudgy",
      type: "body",
      urlId: "ElPudgy_Epic",
      rarity_level: "epic",
    },
    MeshGlasses_Epic: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Face/MeshGlasses_Epic.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Face/Textures/MeshGlasses_Epic.png",
      id: 66,
      name: "Mesh Glasses",
      type: "face",
      urlId: "MeshGlasses_Epic",
      rarity_level: "epic",
    },
    GreenSweater_Common: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/GreenSweater_Common.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/Textures/GreenSweater_Common.png",
      id: 65,
      name: "Green Sweater",
      type: "body",
      urlId: "GreenSweater_Common",
      rarity_level: "common",
    },
    FishermansHat_Uncommon: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/Fisherman%60sHat_Uncommon.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/Textures/Fisherman%60sHat_Uncommon.png",
      id: 44,
      name: "Fisherman's Hat",
      type: "head",
      urlId: "Fisherman'sHat_Uncommon",
      rarity_level: "uncommon",
    },
    SothebysFashionSuit_Uncommon: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/Sotheby%60sFashionSuit_Uncommon.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/Textures/Sotheby%60sFashionSuit_Uncommon.png",
      id: 46,
      name: "Sotheby's Fashion Suit",
      type: "body",
      urlId: "Sotheby'sFashionSuit_Uncommon",
      rarity_level: "uncommon",
    },
    CamperBag_Uncommon: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/CamperBag_Uncommon.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/Textures/CamperBag_Uncommon.png",
      id: 71,
      name: "Camper Bag",
      type: "body",
      urlId: "CamperBag_Uncommon",
      rarity_level: "uncommon",
    },
    WassieVerseHoodie_Uncommon: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/WassieVerseHoodie_Uncommon.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/Textures/WassieVerseHoodie_Uncommon.png",
      id: 73,
      name: "Wassie Verse Hoodie",
      description:
        "Get ready to rock the Pudgy World with the \"Wassie Verse Hoodie\"! This uncommon body trait isn't just any hoodie; it's a ticket to funky town. With its vibrant colors and bold patterns, you'll be the life of every Pudgy party.",
      type: "body",
      urlId: "WassieVerseHoodie_Uncommon",
      rarity_level: "uncommon",
    },
    KanpaiTShirt_Common: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/KanpaiTShirt_Common.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/Textures/KanpaiTShirt_Common.png",
      id: 74,
      name: "Kanpai T-Shirt",
      description:
        'Cheers to the good times with the "Kanpai T-Shirt"! Your penguin will be the ultimate party animal. Whether you\'re dancing the night away or just chilling with friends, this t-shirt screams fun and celebration.',
      type: "body",
      urlId: "KanpaiTShirt_Common",
      rarity_level: "common",
    },
    URSpecialSweater_Common: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/URSpecialSweater_Common.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/Textures/URSpecialSweater_Common.png",
      id: 75,
      name: "UR Special Sweater",
      description:
        "Embrace your inner fashionista with the \"UR Special Sweater\"! This trait might be common, but it's anything but ordinary. Slip into this cozy sweater and feel like a superstar. It's perfect for those days when you want to look effortlessly stylish",
      type: "body",
      urlId: "URSpecialSweater_Common",
      rarity_level: "common",
    },
    GCGSavannah_Uncommon: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Penguin(Color)/GCGSavannah_Uncommon.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Penguin(Color)/Textures/GCGSavannah_Uncommon.png",
      id: 76,
      name: "GCG Savannah",
      description:
        "Embrace the untamed spirit of the savannah with this mesmerizing skin. Your penguin's appearance undergoes a captivating transformation. This striking design captures the essence of the wild, making your penguin stand out as a true adventurer of the untamed plains. The Savannah Skin lets your penguin embody the elegance and mystery of the vast savannah.",
      type: "skin",
      urlId: "GCGSavannah_Uncommon",
      rarity_level: "uncommon",
    },
    XcopyRainbowEyes_Common: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Face/XcopyRainbowEyes_Common.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Face/Textures/XcopyRainbowEyes_Common.png",
      id: 77,
      name: "Xcopy Rainbow Eyes",
      description:
        "Step into a world of digital avant-garde with the Xcopy Rainbow Eyes! Your penguin's face undergoes a mesmerizing transformation. These eyes evoke a classic charm while adding a touch of digital chaos. Whether you're exploring the virtual realm or simply standing out in style, these glitched-out eyes will make your penguin a true digital trendsetter.",
      type: "face",
      urlId: "XcopyRainbowEyes_Common",
      rarity_level: "common",
    },
    DeGodsTShirt_Common: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/DeGodsTShirt_Common.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/Textures/DeGodsTShirt_Common.png",
      id: 78,
      name: "DeGods T-Shirt",
      description:
        "Elevate your style game with the DeGods T-shirt. Join the trendsetters and let your penguin make a fashion statement wherever it roams.",
      type: "body",
      urlId: "DeGodsTShirt_Common",
      rarity_level: "common",
    },
    YootsHoodie_Uncommon: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/YootsHoodie_Uncommon.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Body/Textures/YootsHoodie_Uncommon.png",
      id: 79,
      name: "Yoots Hoodie",
      description:
        "Wrap your penguin in cozy creativity with the Yoots Hoodie. Adventure awaits, and this hoodie keeps your penguin comfy and cool.",
      type: "body",
      urlId: "YootsHoodie_Uncommon",
      rarity_level: "uncommon",
    },
    CreepzCap_Rare: {
      model:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/CreepzCap_Rare.obj",
      texture:
        "https://pengu-dash.s3.us-east-2.amazonaws.com/Head/Textures/CreepzCap_Rare.png",
      id: 80,
      name: "Creepz Cap",
      description:
        "Unleash your penguin's mystique with the Creepz Cap. An enigmatic choice for those who dare to be different. Stand out in style and mystery.",
      type: "head",
      urlId: "CreepzCap_Rare",
      rarity_level: "rare",
    },
  },
};
