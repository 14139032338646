export const Tags = {
  pause: "#pause",
};

export default {
  Game: {
    playing: "playing",
    paused: `paused${Tags.pause}`,
    gameOver: "gameOver",
    modeSelect: "modeSelect",
    returningToPudgyWorld: `returningToPudgyWorld${Tags.pause}`,
    showingControls: `showingControls${Tags.pause}`,
    returningToModeSelect: `returningToModeSelect${Tags.pause}`,
    viewingLeaderboard: `viewingLeaderboard`,
    none: "none",
  },
};
