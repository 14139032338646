export default {
  // chicken: {
  //   name: 'Chicken',
  //   id: 'chicken',
  // },
  bacon: {
    name: "Bacon",
    id: "bacon"
  },
  brent: {
    name: "Brent Vatne",
    id: "brent"
  },
  avocoder: {
    name: "Avocoder",
    id: "avocoder"
  },
  wheeler: {
    name: "Wheeler",
    id: "wheeler"
  },
  palmer: {
    name: "Palmer",
    id: "palmer"
  },
  juwan: {
    name: "Juwan",
    id: "juwan"
  },
  pudgypenguin: {
    name: "Pudgy Penguin",
    id: "pudgypenguin"
  },
};
