import Generic from "./Generic";
import Models from "../Models";
import { getRandomElement } from "../Utils/GetRandomElement";
import { gameEventEmitter } from "../../context/GameContext";
import { EVENT_LOADING_DONE, EVENT_TRAITS_CHANGED } from "../GameEvents";
import { err, log } from "../Utils/LogUtils";
import { PlayerDataManager } from "../Models/PlayerDataManager";

const TraitTypes = Object.freeze({
  BODY: "body",
  FACE: "face",
  HEAD: "head",
  PENGUIN: "skin",
});

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

export default class TraitModel extends Generic {
  _penguinTraits = null;

  static FindById(id) {
    const { traits } = Models;
    for (let traitName in traits) {
      if (traits[traitName].id === id) {
        return traits[traitName];
      }
    }
    return null; // If the trait is not found, return null
  }

  static GetName(id) {
    const { traits } = Models;
    for (let traitName in traits) {
      if (traits[traitName].id === id) {
        return traitName;
      }
    }
    return null;
  }

  static FindByName(name) {
    const { traits } = Models;
    return traits[name];
  }

  static FindByType(type) {
    const { traits } = Models;
    const result = [];
    for (let traitName in traits) {
      if (traits[traitName].type === type) {
        result.push(traits[traitName]);
      }
    }
    return result;
  }

  static FindByRarity(rarity) {
    const { traits } = Models;
    const result = [];
    for (let traitName in traits) {
      if (traits[traitName].rarity === rarity) {
        result.push(traits[traitName]);
      }
    }
    return result;
  }

  setup = async () => {
    try {
      await PlayerDataManager.init();
      const userPenguin = PlayerDataManager.getInstance().getPenguinData();
      log("~~~ [ML] Loading traits from: ", userPenguin);
      const { trait_body_id, trait_skin_id, trait_face_id, trait_head_id } =
        userPenguin;

      const traitsToLoad = [
        TraitModel.FindById(trait_body_id),
        TraitModel.FindById(trait_skin_id),
        TraitModel.FindById(trait_face_id),
        TraitModel.FindById(trait_head_id),
      ].filter((val) => val !== null);

      gameEventEmitter.once(EVENT_LOADING_DONE, () => {
        this._penguinTraits = userPenguin;
        gameEventEmitter.emit(EVENT_TRAITS_CHANGED, userPenguin);
      });

      for (const trait of traitsToLoad) {
        await this.loadTrait(trait);
      }
      return this.models;
    } catch (error) {
      err("Error trying to load traits: ", error);
    }
  };

  loadTrait = async (trait) => {
    log("~~~ [ML] Loading trait in TraitLoader: ", trait);
    const { traits } = this.globalModels;
    const { model, texture } = trait;
    const key = getKeyByValue(traits, trait);

    log("~~~ [ML] KEY Loading trait in TraitLoader: ", key);
    await this._register(key, {
      model,
      texture, //: `${texture}?r=${Math.random()*10000}`, //&
      receiveShadow: false,
      castShadow: true,
    });
  };

  getNode(key) {
    const node = super.getNode(key);

    return node;
  }

  getRandomTraits() {
    return {
      body: getRandomElement(TraitModel.FindByType(TraitTypes.BODY)),
      face: getRandomElement(TraitModel.FindByType(TraitTypes.FACE)),
      head: getRandomElement(TraitModel.FindByType(TraitTypes.HEAD)),
      skin: getRandomElement(TraitModel.FindByType(TraitTypes.PENGUIN)),
    };
  }

  getWalmartTraits() {
    return {
      body: TraitModel.FindById(78),
      face: TraitModel.FindById(77),
      head: TraitModel.FindById(80),
      skin: TraitModel.FindById(3),
    };
  }
}
