import { StyleSheet } from "react-native";
import selectBreakpoint from "../../src/Utils/selectBreakpoint";

const styles = (breakpoint: Breakpoint) =>
  StyleSheet.create({
    background: {
      flex: 1,
      backgroundColor: "#6BCAFF",
    },
    container: {
      flex: 1,
      marginHorizontal: "auto",
      paddingVertical: 32,
      justifyContent: "center",
      ...selectBreakpoint(breakpoint, {
        desktop: {
          gap: 32,
          width: 1200,
        },
        tablet: {
          gap: 32,
          width: "100%",
          paddingHorizontal: 32,
        },
        mobile: {
          gap: 8,
          width: "100%",
          paddingHorizontal: 16,
        },
      }),
    },
    playModeContainer: {
      flexDirection: "row",
      ...selectBreakpoint(breakpoint, {
        desktop: {
          gap: 32,
          height: "100%",
          maxHeight: "40vh",
        },
        tablet: {
          gap: 32,
        },
        mobile: {
          gap: 8,
        },
      }),
    },
    playMode: {
      flex: 1,
      borderColor: "#477DFD",
      borderRadius: 16,
      overflow: "hidden",
      ...selectBreakpoint(breakpoint, {
        desktop: {
          aspectRatio: 1,
          borderWidth: 3,
        },
        tablet: {
          aspectRatio: 1,
          borderWidth: 3,
        },
        mobile: {
          aspectRatio: 1.5 / 2,
          borderWidth: 1,
        },
      }),
    },
    mobileStartNow: {
      position: "absolute",
      bottom: 0,
      zIndex: 1,
      width: "100%",
      height: '100%',
      justifyContent: 'flex-end',
    },
    challengeCard: {
      borderColor: "#A54BFF",
    },
    playModeGradient: {
      flex: 1,
    },
    playModeBackground: {
      flex: 1,
      paddingTop: selectBreakpoint(breakpoint, {
        desktop: 32,
        tablet: 32,
        mobile: 16,
      }),
    },
    cardTitle: {
      fontFamily: "tt_trailer",
      textTransform: "uppercase",
      color: "#ffffff",
      textAlign: "center",
      textShadow: "-1.05px 1.05px 0px #1A1A1A",
      fontSize: selectBreakpoint(breakpoint, {
        desktop: 72,
        tablet: 72,
        mobile: 28,
      }),
    },
    smCardTitle: {
      fontFamily: "tt_trailer",
      textTransform: "uppercase",
      color: "#ffffff",
      textAlign: "center",
      textShadow: "-1.05px 1.05px 0px #1A1A1A",
      fontSize: selectBreakpoint(breakpoint, {
        desktop: 60,
        tablet: 48,
        mobile: 28,
      }),
    },
    comingSoonOverlay: {
      position: "absolute",
      width: "100%",
      height: "100%",
      backgroundColor: "#000000",
      zIndex: 2,
      opacity: 0.6,
    },
    comingSoon: {
      position: "absolute",
      width: "100%",
      height: "100%",
      zIndex: 3,
      alignItems: "center",
      justifyContent: "flex-end",
      paddingBottom: selectBreakpoint(breakpoint, {
        desktop: 32,
        tablet: 32,
        mobile: 16,
      }),
    },
    menuContainer: {
      ...selectBreakpoint(breakpoint, {
        desktop: {
          gap: 32,
          flexDirection: "row",
        },
        tablet: {
          gap: 32,
          flexDirection: "row",
        },
        mobile: {
          gap: 16,
        },
      }),
    },
    leaderboard: {
      flex: 1,
      borderWidth: 3,
      borderColor: "#09A6FF",
      borderRadius: 16,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      overflow: "hidden",
    },
    leaderboardImage: {
      height: "100%",
      aspectRatio: 402 / 139,
      ...selectBreakpoint(breakpoint, {
        mobile: {
          aspectRatio: 682 / 360,
        },
      }),
    },
    leaderboardTitle: {
      padding: selectBreakpoint(breakpoint, {
        desktop: 16,
        tablet: 16,
        mobile: 32,
      }),
    },
    controlsWrapper: {
      ...selectBreakpoint(breakpoint, {
        mobile: {
          alignSelf: "center",
        },
      }),
    },
    controls: {
      borderWidth: 3,
      borderColor: "#F1A7FA",
      borderRadius: 16,
      flexDirection: "row",
      alignItems: "center",
      padding: 16,
      paddingHorizontal: 32,
      gap: 16,
    },
    controlsIcon: {
      width: 60,
      height: 60,
      ...selectBreakpoint(breakpoint, {
        desktop: {
          width: 60,
          height: 60,
        },
        tablet: {
          width: 60,
          height: 60,
        },
        mobile: {
          width: 32,
          height: 32,
        },
      }),
    },
    casualModeOverlay: {
      position: "absolute",
      width: "100%",
      height: "100%",
      backgroundColor: "#00000090",
    },
    overlayTitle: {
      ...selectBreakpoint(breakpoint, {
        desktop: {
          fontSize: "5vh",
        },
      }),
    },
    casualPlayModeImage: {
      ...selectBreakpoint(breakpoint, {
        desktop: {
          width: "100%",
          aspectRatio: 252 / 60,
        },
        tablet: {
          width: "100%",
          aspectRatio: 474 / 172,
        },
      }),
    },
    playNowText: {
      ...selectBreakpoint(breakpoint, {
        desktop: {
          fontSize: "5vh",
          position: "absolute",
          fontFamily: "menco_black",
          textShadow: "0px 1px 1px #5E0669, -1px -1px 1px #5E0669",
          color: "#ffffff",
          backgroundClip: "text",
        },
        tablet: {
          display: "none",
        },
        mobile: {
          display: "none",
        },
      }),
    },
  });

export default styles;
