import { Image, ImageStyle, StyleProp, StyleSheet } from "react-native";
import { Pressable } from "react-native-web";
import pauseIcon from "../assets/images/ui/pause.png";
import pausePressedIcon from "../assets/images/ui/pause-pressed.png";
import { FC, useCallback, useMemo } from "react";
import { gameEventEmitter } from "../context/GameContext";
import { EVENT_GAME_STATE_CHANGE } from "../src/GameEvents";
import state from "../src/state";
import useBreakpoint from "../src/hooks/useBreakpoint";
import selectBreakpoint from "../src/Utils/selectBreakpoint";

interface Props {}
const PauseButton: FC<Props> = () => {
  const breakpoint = useBreakpoint();

  const handleClick = useCallback(() => {
    gameEventEmitter.emit(EVENT_GAME_STATE_CHANGE, state.Game.paused);
  }, []);

  const imageStyle: StyleProp<ImageStyle> = useMemo(
    () => ({
      aspectRatio: 286 / 290,
      transitionProperty: ["opacity"],
      transitionDuration: ["120ms"],
      width: selectBreakpoint(breakpoint, {
        desktop: 75,
        tablet: 75,
        mobile: 40,
      }),
    }),
    [breakpoint]
  );

  return (
    <Pressable onPress={handleClick}>
      {({ hovered }) => (
        <>
          <Image source={pauseIcon} style={imageStyle} />
          <Image
            source={pausePressedIcon}
            style={[
              imageStyle,
              StyleSheet.absoluteFillObject,
              { opacity: hovered ? 1 : 0 },
            ]}
          />
        </>
      )}
    </Pressable>
  );
};

export default PauseButton;
