import React from "react";
import axios from "axios";
import { useResolvedValue } from "../src/hooks/useResolvedValue";
import penguinPortrait from "../assets/images/penguin-portrait.png";
import { Image, ImageProps, StyleProp } from "react-native";

export const getImageBase64 = async (src: string | null) => {
  if (!src) return penguinPortrait;
  return await axios
    .get(src)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return penguinPortrait;
    });
};

interface Props {
  svg: string;
  style: StyleProp<ImageProps>;
}

const Avatar: React.FC<Props> = ({ svg, style }) => {
  const [image, error] = useResolvedValue(() => {
    return getImageBase64(svg);
  });

  return <Image source={image} style={style} />;
};

export default Avatar;
