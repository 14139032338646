import { LinearGradient } from "expo-linear-gradient";
import { ImageBackground, StyleSheet, Text, View } from "react-native";
import leaderboardBackgroundImg from "../../assets/images/ui/leaderboard-background.png";
import LeaderboardTabs from "./LeaderboardTabs";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import LeaderboardRank from "./LeaderboardRank";
import dayjs from "dayjs";
import useBreakpoint from "../../src/hooks/useBreakpoint";
import styles from "./styles";
import {
  LeaderboardRow,
  LeaderBoardModel,
} from "../../src/Models/LeaderBoardModel";
import { PlayerDataManager } from "../../src/Models/PlayerDataManager";
import { useResolvedValue } from "../../src/hooks/useResolvedValue";
import LeaderboardRankRow from "./LeaderboardRankRow";
import firstPlaceImg from "../../assets/images/ui/1st-place.png";
import secondPlaceImg from "../../assets/images/ui/2nd-place.png";
import thirdPlaceImg from "../../assets/images/ui/3rd-place.png";

interface Props {
  currentUsersName: string;
  renderFooter: JSX.Element;
}
const Leaderboard: FC<Props> = ({ currentUsersName, renderFooter }) => {
  const [activeTab, setActiveTab] = useState(1);
  const [ranks, setRanks] = useState<LeaderboardRow[]>([]);
  const [countDownTime, setCountDownTime] = useState("07D:00H:00M");

  const breakpoint = useBreakpoint();
  const style = useMemo(() => styles(breakpoint), [breakpoint]);
  const [myRank, setMyRank] = useState(0);

  const [me, error] = useResolvedValue(() =>
    PlayerDataManager.toLeaderboardRow()
  );

  const fetchUsersByRank = async () => {
    const data = await LeaderBoardModel.GetLeaderBoards();
    setRanks(data);
    return data;
  };

  useEffect(() => {
    fetchUsersByRank().then((data) => {
      setMyRank(data.findIndex((item) => item.nameText === currentUsersName));
    });
  }, []);

  const medalImage = (index: number) => {
    const medals = [firstPlaceImg, secondPlaceImg, thirdPlaceImg];

    return medals[index];
  };

  useEffect(() => {
    const getTimeLeft = () => {
      const now = dayjs(); // Get the current time
      const today = now.day(); // Get the current day of the week (0 for Sunday, 1 for Monday, etc.)
      const daysUntilNextSunday = (7 - today) % 7; // Calculate the number of days until the next Sunday
      const nextSunday = now
        .add(daysUntilNextSunday, "day")
        .hour(17)
        .minute(0)
        .second(0); // Set the next Sunday at 5pm
      const secondsDiff = nextSunday.diff(now, "second"); // Calculate the time difference in seconds

      const days = Math.floor(secondsDiff / (24 * 60 * 60)); // Calculate the number of days
      const hours = Math.floor((secondsDiff % (24 * 60 * 60)) / (60 * 60)); // Calculate the number of hours
      const minutes = Math.floor((secondsDiff % (60 * 60)) / 60); // Calculate the number of minutes

      setCountDownTime(`${days}D:${hours}H:${minutes}M`);
    };

    const timer = setInterval(() => {
      getTimeLeft();
    }, 1000 * 60);

    getTimeLeft();
    return () => {
      clearInterval(timer);
    };
  }, []);

  const tabs: LeaderboardTab[] = useMemo(
    () => [
      { title: "Weekly", isDisabled: true },
      { title: "Global" },
      { title: "Friends", isDisabled: true },
    ],
    []
  );

  return (
    <LinearGradient
      colors={["#61C3FE", "#11A0F1"]}
      locations={[0.3, 0.87]}
      start={[0.0, 0.0]}
      end={[1.0, 1.0]}
      style={style.container}
    >
      <ImageBackground
        source={leaderboardBackgroundImg}
        style={style.countdownContainer}
      >
        <Text style={style.countdownText}>Ends in</Text>
        <Text style={[style.countdownText, { color: "#ffffff" }]}>
          {countDownTime}
        </Text>
      </ImageBackground>
      <LeaderboardTabs
        data={tabs}
        activeTab={activeTab}
        onChange={(index) => setActiveTab(index)}
      />
      {me && (
        <>
          <LeaderboardRankRow
            data={me}
            index={myRank}
            medalImage={medalImage(myRank)}
            isLast={true}
            breakpoint={breakpoint}
          />
          <View style={style.spacer} />
        </>
      )}
      <LeaderboardRank data={ranks} currentUsersName={currentUsersName} />
      {renderFooter}
    </LinearGradient>
  );
};

export default Leaderboard;
