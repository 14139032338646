import { Image, ImageSourcePropType, Text, View } from "react-native";

import { FC, useMemo } from "react";

import coinImg from "../../../assets/images/ui/coin.png";
import giftImg from "../../../assets/images/ui/gift.png";
import styles from "./styles";
import Avatar from "../../Avatar";
import { LeaderboardRow } from "../../../src/Models/LeaderBoardModel";

interface Props {
  data: LeaderboardRow;
  index: number;
  medalImage: ImageSourcePropType;
  isLast: boolean;
  breakpoint: Breakpoint;
}
const LeaderboardRankRow: FC<Props> = ({
  data,
  index,
  medalImage,
  isLast,
  breakpoint,
}: Props) => {
  const style = useMemo(() => styles(breakpoint), [breakpoint]);
  return (
    <>
      <View style={style.bodyRow}>
        <View style={style.bodyRank}>
          {index > 2 ? (
            <Text style={style.bodyText}>{index + 1}</Text>
          ) : (
            <Image
              source={medalImage}
              style={style.medalImg}
              resizeMode="contain"
            />
          )}
        </View>
        <View style={style.bodyUserContainer}>
          <View style={style.avatarWrapper}>
            <Avatar svg={data.image} style={style.avatarImg} />
          </View>
          <Text style={[style.bodyText, { textAlign: "left" }]}>
            {data.nameText}
          </Text>
        </View>
        <View style={style.bodyRewardContainer}>
          {index <= 2 && (
            <Image
              source={giftImg}
              style={style.giftImg}
              resizeMode="contain"
            />
          )}
          {index > 2 && index < 10 && (
            <>
              <Image
                source={coinImg}
                style={style.coinImg}
                resizeMode="contain"
              />
              <Text style={style.bodyText}>10</Text>
            </>
          )}
        </View>
        <View style={style.bodyRankContainer}>
          <Text style={style.bodyText}>{data.score}</Text>
        </View>
      </View>
      {!isLast && <View style={style.divider} />}
    </>
  );
};

export default LeaderboardRankRow;
