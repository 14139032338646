import { useContext, useEffect } from "react";
import { GameContext } from "../../context/GameContext";

export type Callback = (data?: any) => void;

export function useSubscribeToEvent(event: string, cb: Callback) {
  const { subscribeToEvent } = useContext(GameContext);

  useEffect(() => {
    return subscribeToEvent(event, cb);
  }, []);
}

export function useSubscribeOnce(event: string, cb: Callback) {
  const { subscribeEventOnce } = useContext(GameContext);

  useEffect(() => {
    return subscribeEventOnce(event, cb);
  });
}
