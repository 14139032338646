import {
  death,
  deathRes,
  setRankRes,
  getPenguinData,
  PenguinData,
  PenguinRanks,
  userRes,
  setRank,
  getUsersRank,
} from "../../api";
import { ENV_DEBUG } from "../Utils/EnvUtils";
import { log } from "../Utils/LogUtils";
import { LeaderboardRow } from "./LeaderBoardModel";

export class PlayerDataManager {
  // create singleton factory method that returns a PlayerDataManager instance
  private static instance: PlayerDataManager;
  protected penguinData: userRes;
  protected didLoad = false;
  protected _currentPosition = -1;

  protected constructor() {
    if (PlayerDataManager.instance) {
      throw new Error("PlayerDataManager is a singleton");
    }
  }
  static getInstance() {
    if (!PlayerDataManager.instance) {
      PlayerDataManager.instance = new PlayerDataManager();
    }
    return PlayerDataManager.instance;
  }

  static async init() {
    const instance = PlayerDataManager.getInstance();
    await instance.loadPlayerData();
    instance.didLoad = instance.penguinData.isSuccess;
  }

  get isLoaded(): boolean {
    return this.didLoad;
  }

  get currentPosition(): number {
    return this._currentPosition;
  }

  get rank(): number {
    if (!this.isLoaded) return 0;

    return this.penguinData.penguin.user_ranks.roads_rank;
  }

  get name(): string {
    if (!this.isLoaded) return "Penguin";

    return this.penguinData.penguin.user_penguin.penguin_name;
  }

  get id(): string {
    if (!this.isLoaded) return "";
    return this.penguinData.penguin.user_penguin.id;
  }

  static id(): string {
    if (!PlayerDataManager.getInstance().isLoaded) return "";

    return PlayerDataManager.getInstance().penguinData.penguin.user_penguin.id;
  }
  static currentRank(): number {
    return PlayerDataManager.getInstance().rank;
  }

  static playersName(): string {
    return PlayerDataManager.getInstance().name;
  }

  // create an async method to get the player data via api getPenguinTraits
  async loadPlayerData() {
    const penguinData = await getPenguinData();
    console.log("loaded penguinData", penguinData);
    this.penguinData = penguinData;
    if (
      !penguinData.isSuccess ||
      (penguinData.error && penguinData.error.length > 0)
    ) {
      throw new Error(penguinData.error);
    }
  }

  async postRank(
    points: number,
    localScore: number,
    Iscore: number,
  ): Promise<setRankRes> {
    if (!points) return;

    const exceedsIPoints = points > Iscore;

    if (exceedsIPoints) {
      return await setRank(points - Iscore);
    }
  }

  async submit(coins: number, gems: number): Promise<deathRes> {
    log("\n\n ~~~ submit", coins, gems);
    return await death(coins, gems);
  }

  // get the traits of the player
  getPenguinData(): PenguinData | null {
    return !this.didLoad ? null : this.penguinData.penguin.user_penguin;
  }

  getPenguinImage(): string {
    return !this.didLoad ? "" : this.penguinData.penguin.image;
  }

  getRankData(): PenguinRanks | null {
    return !this.didLoad ? null : this.penguinData.penguin.user_ranks;
  }

  setRank(rank: number) {
    this.penguinData.penguin.user_ranks.roads_rank = rank;
  }

  async getUsersRank(): Promise<number> {
    if (!this.isLoaded) return -1;

    const id = this.penguinData.user.id;
    const rank = this.penguinData.penguin.user_ranks.roads_rank;
    const results = await getUsersRank(id, rank);
    // DB is zero index, we are displaying this in human index
    // const position = Math.max(
    //   results.position,
    //   results.data.user_ranks.road_rank,
    // );
    this._currentPosition = results.position;
    return this._currentPosition;
  }

  static async toLeaderboardRow(): Promise<LeaderboardRow> {
    const instance = PlayerDataManager.getInstance();

    if (!instance.isLoaded) {
      await PlayerDataManager.init();
    }

    return {
      id: instance.id,
      nameText: instance.name,
      score: instance.rank,
      image: instance.getPenguinImage(),
    };
  }
}
