import { StyleSheet } from "react-native"
import selectBreakpoint from "../../src/Utils/selectBreakpoint"

const styles = (breakpont: Breakpoint) => StyleSheet.create({
  container: {
    backgroundColor: 'transparent',
    position: 'absolute',
    alignItems: 'center',
    ...selectBreakpoint(breakpont, {
      desktop: {
        padding: 32,
      },
      tablet: {
        padding: 32,
      },
      mobile: {
        padding: 8,
        paddingBottom: 32,
      }
    }),
    ...StyleSheet.absoluteFillObject
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  },
  logoWrapper: {
    height: selectBreakpoint(breakpont, {
      desktop: 144,
      tablet: 144,
      mobile: 80
    })
  },
  logo: {
    height: '100%',
    aspectRatio: 139 / 127
  },
  dashLogoWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  dashLogo: {
    width: 478,
    height: 420,
    resizeMode: 'contain',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2
  },
  startButton: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    borderRadius: 12,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 12,
    paddingVertical: 16,
    gap: 10
  },
  startButtonText: {
    fontFamily: 'menco_black',
    fontSize: 24,
    fontWeight: '900',
    color: '#ffffff',
    textTransform: 'uppercase'
  },
  gameStartIcon: {
    width: 32,
    aspectRatio: 1
  }
})

export default styles