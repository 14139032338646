import Generic from "./Generic";
import { cars } from "../Utils/AssetUtils";

export default class Car extends Generic {
  setup = async () => {
    const { vehicles } = this.globalModels;

    for (let index in cars) {
      let car = cars[index];
      await this._register(`${index}`, {
        ...vehicles[car],
        name: car,
        castShadow: true,
        receiveShadow: true,
      });

      const afterLoadFn = vehicles[car]["afterLoad"];
      const model = this.models[`${index}`];
      if (afterLoadFn && model) {
        afterLoadFn(model);
      }
    }

    return this.models;
  };
}
