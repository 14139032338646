import { PUBLIC_ENVIRONMENT, DEBUG, USE_MOCK_APP } from "@env";

export type Environment = "development" | "staging" | "production";

export function evalTrue(str: string = null) {
    return !!str && str.toLowerCase() === "true";
}

export const PUBLIC_ENVIRONMENT_API: Environment =
    (PUBLIC_ENVIRONMENT as Environment) ?? "production";
export const ENV_DEBUG = evalTrue(DEBUG);
export const ENV_USE_MOCK_APP = evalTrue(USE_MOCK_APP);