import { Box3, Object3D } from "three";

import ModelLoader from "../../src/ModelLoader";
import { groundLevel } from "../GameSettings";
import { Power1, Power2, TweenMax } from "gsap";
import { gameEventEmitter } from "../../context/GameContext";
import { log, warn } from "../Utils/LogUtils";
import { EVENT_COIN_COLLISION } from "../GameEvents";

export const Fill = {
  empty: "empty",
  solid: "solid",
  random: "random",
};

const HAS_WALLS = true;
const HAS_OBSTACLES = true;
const HAS_VARIETY = true;

export default class Grass extends Object3D {
  active = false;
  entities = [];
  heroWidth = 0.7;
  top = 0.4;

  coinIdleAnimations = new Map();
  coinPointsMap = new Map();

  /*

* Build Walls

* Random Fill Center
* Solid Fill Center
* Empty Fill Center


*/

  removeCoin = (data) => {
    const { mesh } = data;
    if (!mesh) {
      warn("~~~~ Can't remove coin, No mesh in data");
      return;
    }

    // console.log("mesh collided");
    // mesh.position.y += 5;
    const tween = this.coinIdleAnimations.get(mesh);

    if (tween) {
      this.coinIdleAnimations.delete(mesh);
      tween.kill();
      this.playCollectionAnimation(mesh);
    }
  };

  playCollectionAnimation = (mesh) => {
    const maxTime = 0.5;

    const secondTween = () => {
      TweenMax.to(mesh.position, maxTime, {
        y: "+=0.5", // Move the mesh up by 5 units (or any desired amount)
        ease: Power1.easeIn,
        onComplete: () => {
          mesh.visible = false;
        },
      });

      TweenMax.to(mesh.rotation, maxTime, {
        y: "+=5", // Rotate the mesh (add to its current rotation)
        ease: Power1.easeIn, // Ease-in will make it start slow and end fast
      });

      TweenMax.to(mesh.scale, maxTime, {
        x: 2, // Scale up the mesh in the x direction
        y: 2, // Scale up in the y direction
        z: 2, // Scale up in the z direction
        ease: Power1.easeIn,
      });

      TweenMax.to(mesh.position, maxTime, {
        y: "+=0.5", // Move the mesh up by 5 units (or any desired amount)
        ease: Power1.easeIn,
        onComplete: () => {
          mesh.visible = false;
        },
      });
    };

    TweenMax.to(mesh.position, 0.25, {
      z: "-=0.5", // Move the mesh up by 5 units (or any desired amount)
      ease: Power1.linear,
    });

    TweenMax.to(mesh.position, 0.25, {
      y: "+=0.5", // Move the mesh up by 5 units (or any desired amount)
      ease: Power1.linear,
      onComplete: () => {
        secondTween();
      },
    });
  };

  getWidth = (mesh) => {
    let box3 = new Box3();
    box3.setFromObject(mesh);
    // console.log( box.min, box.max, box.size() );
    return Math.round(box3.max.x - box3.min.x);
  };

  generate = (type = Fill.random) => {
    this.entities.map((val) => {
      this.floor.remove(val.mesh);
      val = null;
    });
    this.entities = [];
    this.obstacleMap = {};
    this.rowGen(type);
  };

  obstacleMap = {};
  addObstacle = (x) => {
    let mesh;
    if (HAS_VARIETY) {
      mesh =
        Math.random() < 0.4
          ? ModelLoader._boulder.getRandom()
          : ModelLoader._tree.getRandom();
    } else {
      mesh = ModelLoader._tree.getRandom();
    }
    this.obstacleMap[`${x | 0}`] = { index: this.entities.length };
    this.entities.push({ mesh });
    this.floor.add(mesh);
    mesh.position.set(x, groundLevel, 0);
  };

  rowGen = (type) => {
    // 0 - 8
    let _rowCount = 0;
    let hasCoin = false;
    const count = Math.round(Math.random() * 2) + 1;
    for (let x = -3; x < 12; x++) {
      const _x = x - 4;
      if (type === Fill.solid) {
        this.addObstacle(_x);
        continue;
      }

      if (HAS_WALLS) {
        /// Walls
        if (x >= 9 || x <= -1) {
          this.addObstacle(_x);
          continue;
        }
      }

      if (HAS_OBSTACLES) {
        if (_rowCount < count) {
          if (_x !== 0 && Math.random() > 0.6) {
            this.addObstacle(_x);
            _rowCount++;
          } else {
            const randomNumber = Math.floor(Math.random() * 100) + 1;
            const shouldSpawnCoin = randomNumber <= 30 && !hasCoin;

            if (type == Fill.random && shouldSpawnCoin) {
              hasCoin = true;
              const { mesh, points } = ModelLoader._coin.getRandomWithPoints();

              const width = this.getWidth(mesh);
              this.entities.push({
                mesh,
                top: 0.2,
                min: 0.01,
                mid: 0.125,
                dir: 0,
                width,
                collisionBox: this.heroWidth / 2 + width / 2 - 0.1,
              });

              this.floor.add(mesh);
              mesh.position.set(_x, groundLevel, 0);

              this.coinPointsMap.set(mesh, points);
              console.log("~~~ [SPC] Coin Points Map: ", this.coinPointsMap);

              const tween = TweenMax.to(mesh.rotation, Math.random() * 2 + 2, {
                y: mesh.rotation.y + 2 * Math.PI - 0.1,
                yoyo: true,
                repeat: -1,
                ease: Power2.easeInOut,
              });

              this.coinIdleAnimations.set(mesh, tween);
            }
          }
        }
      }
    }
  };

  update = (dt, player) => {
    if (!this.active) {
      return;
    }

    if (player.moving) {
      // console.log("checking for collision");
      this.entities.map((entity) => this.checkCollision({ player, entity }));
    }
  };

  checkCollision = ({ player, entity }) => {
    if (Math.round(player.position.z) === this.position.z && player.isAlive) {
      const { mesh, collisionBox } = entity;

      // console.log('~~~ checking for collision');
      if (!collisionBox) return;

      // console.log('~~~ has box', collisionBox);

      if (
        player.position.x < mesh.position.x + collisionBox &&
        player.position.x > mesh.position.x - collisionBox
      ) {
        if (this.onCollide) {
          let index = this.entities.indexOf(entity);

          if (index !== -1) {
            this.entities.splice(index, 1);
          }

          const points = this.coinPointsMap.get(mesh);
          log("~~~ [SPC] coinsMap: ", this.coinPointsMap);
          log("~~~ [SPC] Points in Grass.js: ", points);

          this.onCollide({ mesh }, "coin", `coin-${points}`);
        }
      }
    }
  };

  constructor(heroWidth, onCollide) {
    super();
    this.heroWidth = heroWidth;
    this.onCollide = onCollide;
    const { _grass } = ModelLoader;

    this.floor = _grass.getNode();
    this.add(this.floor);

    gameEventEmitter.on(EVENT_COIN_COLLISION, this.removeCoin);
  }
}
