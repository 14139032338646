import { getUsersByRank } from "../../api";

export type LeaderboardRow = {
  id: string;
  nameText: string;
  score: number;
  image: string;
};

export class LeaderBoardModel {
  private static instance: LeaderBoardModel;

  private constructor() {
    // Private constructor to prevent instantiation outside of the class
  }

  public static getInstance(): LeaderBoardModel {
    if (!LeaderBoardModel.instance) {
      LeaderBoardModel.instance = new LeaderBoardModel();
    }
    return LeaderBoardModel.instance;
  }

  static async GetLeaderBoards(
    limit: number = 50,
    offset: number = 0,
  ): Promise<Array<LeaderboardRow>> {
    const ranks = await getUsersByRank(limit, offset);
    console.log("~~~ ranks", ranks);
    if (!ranks.isSuccess) return [];
    return ranks.users.token_traits
      .map((rank) => {
        return {
          id: rank.id,
          nameText: rank.penguin_name,
          score: rank.user_ranks.roads_rank,
          image: rank.image,
        };
      })
      .sort((a, b) => {
        return b.score - a.score;
      });
  }
}
