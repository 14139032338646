import { Dimensions, StyleSheet } from "react-native";
import selectBreakpoint from "../../src/Utils/selectBreakpoint";

const windowHeight = Dimensions.get("window").height;
const styles = (breakpoint: Breakpoint) =>
  StyleSheet.create({
    container: {
      borderRadius: 30,
      borderWidth: 3,
      borderColor: "#CEEDFF",
      overflow: "hidden",
      transformOrigin: "left",
      ...selectBreakpoint(breakpoint, {
        desktop: {
          minWidth: 600,
          maxHeight: 650,
        },
        tablet: {
          width: "75%",
          maxHeight: windowHeight * 0.8,
        },
        mobile: {
          width: "100%",
          maxHeight: windowHeight * 0.8,
        },
      }),
    },
    countdownContainer: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      paddingVertical: 16,
      gap: 8,
    },
    countdownText: {
      fontFamily: "tt_trailer",
      color: "#2c5fff",
      textTransform: "uppercase",
      fontSize: selectBreakpoint(breakpoint, {
        desktop: 48,
        tablet: 48,
        mobile: 28,
      }),
    },
    spacer: {
      height: 15,
      backgroundColor: "#ffffff",
      width: "100%",
    },
  });

export default styles;
