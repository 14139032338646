import { LinearGradient } from "expo-linear-gradient";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Animated,
  Image,
  ImageBackground,
  LayoutChangeEvent,
  LayoutRectangle,
  Pressable,
  Text,
  View,
} from "react-native";
import useBreakpoint from "../../../src/hooks/useBreakpoint";
import styles from "./styles";
import linesImage from "../../../assets/images/ui/leaderboard-tab-lines.png";

interface Props {
  data: LeaderboardTab[];
  activeTab: number;
  onChange: (index: number) => void;
}
const LeaderboardTabs: FC<Props> = ({ data, activeTab, onChange }) => {
  const indicatorX = useRef(new Animated.Value(0)).current;
  const [tabLayout, setTabLayout] = useState<LayoutRectangle | null>(null);

  const breakpoint = useBreakpoint();
  const style = useMemo(() => styles(breakpoint), [breakpoint]);

  const handleLayout = useCallback(({ nativeEvent }: LayoutChangeEvent) => {
    setTabLayout(nativeEvent.layout);
  }, []);

  useEffect(() => {
    Animated.spring(indicatorX, {
      toValue: activeTab * (tabLayout?.width || 0) + 10,
      useNativeDriver: true,
    }).start();
  }, [activeTab, tabLayout]);

  return (
    <View style={style.container}>
      <Animated.View
        style={[
          style.indicator,
          {
            width: tabLayout?.width - 20,
            height: tabLayout?.height * 0.8,
            transform: [{ translateX: indicatorX }],
          },
        ]}
        pointerEvents="none"
      >
        <Image source={linesImage} style={[style.indicatorLines]} />
      </Animated.View>
      <LinearGradient
        colors={["#69C9FF", "#089CEF"]}
        locations={[0.08, 1.17]}
        style={style.buttonsContainer}
      >
        {data.map((item, index) => (
          <Pressable
            key={item.title}
            onPress={() => onChange(index)}
            style={style.button}
            onLayout={handleLayout}
            disabled={item.isDisabled}
          >
            <Text
              style={[
                style.buttonText,
                item.isDisabled && style.disabledButtonText,
              ]}
            >
              {item.title}
            </Text>
          </Pressable>
        ))}
      </LinearGradient>
    </View>
  );
};

export default LeaderboardTabs;
