import Hero from "./Node/Hero";
import Car from "./Node/Car";
import Log from "./Node/Log";
import Road from "./Node/Road";
import Grass from "./Node/Grass";
import River from "./Node/River";
import Tree from "./Node/Tree";
import Train from "./Node/Train";
import Boulder from "./Node/Boulder";
import RailRoad from "./Node/RailRoad";
import TrainLight from "./Node/TrainLight";
import LilyPad from "./Node/LilyPad";
import Coin from "./Node/Coin";
import SnowBall from "./Node/SnowBall";
import TraitModel from "./Node/TraitModel";
import PudgyRider from "./Node/PudgyRider";
import { gameEventEmitter } from "../context/GameContext";
import {
  EVENT_LOADING_DONE,
  EVENT_LOADING_ERROR,
  EVENT_LOADING_PROGRESS,
} from "./GameEvents";
import { log, err } from "./Utils/LogUtils";
import { ENV_DEBUG } from "./Utils/EnvUtils";

class ModelLoader {
  loadModels = async () => {
    try {
      this._coin = new Coin();
      this._lilyPad = new LilyPad();
      this._grass = new Grass();
      this._road = new Road();
      this._river = new River();
      this._boulder = new Boulder();
      this._tree = new Tree();
      this._car = new Car();
      this._railroad = new RailRoad();
      this._train = new Train();
      this._trainLight = new TrainLight();
      this._log = new Log();
      this._hero = new Hero();
      this._snowBall = new SnowBall();
      this._traits = new TraitModel();
      this._pudgyRiders = new PudgyRider();

      const objects = [
        this._coin,
        this._lilyPad,
        this._road,
        this._grass,
        this._river,
        this._log,
        this._boulder,
        this._tree,
        this._car,
        this._railroad,
        this._train,
        this._hero,
        this._trainLight,
        this._snowBall,
        this._traits,
        this._pudgyRiders,
      ];

      const step = Math.min(95, Math.round(100 / objects.length));
      let currentProgress = 0;
      for (const object of objects) {
        log("~~~ [ML] Loading object: ", object);
        await object.setup();
        currentProgress += step;
        gameEventEmitter.emit(EVENT_LOADING_PROGRESS, {
          progress: currentProgress,
        });
      }

      await new Promise((resolve) => {
        setTimeout(resolve, 1000);
      });

      gameEventEmitter.emit(EVENT_LOADING_DONE);
    } catch (e) {
      err("\n\n~~~ [ML] Error loading models: ", e);
      gameEventEmitter.emit(EVENT_LOADING_ERROR, e);
      return false;
    }

    return true;
  };
}

export default new ModelLoader();
