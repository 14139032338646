import { StyleSheet } from "react-native";
import selectBreakpoint from "../../src/Utils/selectBreakpoint";

const styles = (breakpoint: Breakpoint) => StyleSheet.create({
  scoreBackgroundImg: {
    aspectRatio: 940 / 412,
    height: selectBreakpoint(breakpoint, {
      desktop: 111,
      mobile: 55,
      tablet: 95
    })
  },
  coinAmount: {
    position: 'absolute',
    fontFamily: 'TT_Trailer',
    color: '#CF950B',
    ...selectBreakpoint(breakpoint, {
      desktop: {
        fontSize: 48,
        right: 130,
        top: 26,
      },
      tablet: {
        fontSize: 38,
        right: 115,
        top: 25,
      },
      mobile: {
        fontSize: 20,
        left: 40,
        top: 16,
      }
    })
  }
})

export default styles