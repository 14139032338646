import AsyncStorage from "@react-native-async-storage/async-storage";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

import { GameContext, gameEventEmitter } from "./GameContext";
import {
  EVENT_COIN_COLLISION,
  EVENT_GAME_OVER,
  EVENT_GAME_SCORE_CHANGED,
  EVENT_GAME_STATE_CHANGE,
  EVENT_GAME_TOGGLE_MUTE,
  EVENT_GLOBAL_POSITION_SET,
  EVENT_PLAYER_START,
  EVENT_RESTART_GAME,
} from "../src/GameEvents";
import State from "../src/state";
import { log, warn } from "../src/Utils/LogUtils";
import { ENV_DEBUG } from "../src/Utils/EnvUtils";
import { PlayerDataManager } from "../src/Models/PlayerDataManager";
import AudioManager from "../src/AudioManager";
import { GameOverModel } from "../src/Models/GameOverModel";

const STORAGE_KEY = "@BouncyBacon:Character";
const SHOULD_REHYDRATE = true;

const defaultState = {
  character: "pudgypenguin",
  highscore: 0,
  coins: 0,
  xp: 0,
  gems: 0,
  score: 0,
  audioMuted: false,
};

// This is a hack to get the state to work
const GameState = Object.assign({}, defaultState);

async function cacheAsync(value) {
  console.log("~~~ [LS] caching data ~~", value);
  await AsyncStorage.setItem(STORAGE_KEY, JSON.stringify(value));
  console.log("~~~ [LS] done caching data ~~", value);
}

async function rehydrateAsync() {
  if (!SHOULD_REHYDRATE || !AsyncStorage) {
    log("~~~ [LS] Load data returning default ~~");
    return defaultState;
  }
  try {
    log("~~~ [LS] Load data getting item ~~");
    const item = await AsyncStorage.getItem(STORAGE_KEY);
    log("~~~ [LS] Load data item ~~", item);
    const data = JSON.parse(item);
    log("~~~ [LS] Load data data ~~", data);
    return data;
  } catch (ignored) {
    log("~~~ [LS] Load data error ~~", ignored);
    return defaultState;
  }
}

// TODO: refactor this to not be a Anti-Pattern
export default function GameProvider({ children }) {
  const [character, setCharacter] = useState(defaultState.character);
  const [highscore, setHighscore] = useState(defaultState.highscore);
  const [score, setScore] = useState(defaultState.score);
  const [coins, setCoins] = useState(defaultState.coins);
  const [xp, setXp] = useState(defaultState.xp);
  const [gems, setGems] = useState(defaultState.gems);
  const [audioMuted, setAudioMuted] = useState(defaultState.audioMuted);
  const [globalPosition, setGlobalPosition] = useState(0);
  const [usersName, setUsersName] = useState(
    PlayerDataManager.getInstance().name ?? "Penguin",
  );
  const [canWriteToLS, setCanWriteToLS] = useState(false);
  const [traits, setTraits] = useState(null);
  const [engine, setEngine] = useState(null);

  const gameState = useRef(GameState);

  const subscribeToEvent = useCallback((eventName, callback) => {
    // Attach the listener
    gameEventEmitter.on(eventName, callback);

    // Return an unsubscribe function for cleanup
    return () => {
      gameEventEmitter.off(eventName, callback);
    };
  }, []);

  const subscribeEventOnce = useCallback((eventName, callback) => {
    // Attach the listener
    gameEventEmitter.once(eventName, callback);

    return () => {
      gameEventEmitter.off(eventName, callback);
    };
  }, []);

  const triggerEvent = useCallback((eventName, data) => {
    // Emit the event with the provided data
    gameEventEmitter.emit(eventName, data);
  }, []);

  useEffect(() => {
    const unsubAudioMuteChanged = subscribeToEvent(
      EVENT_GAME_TOGGLE_MUTE,
      () => {
        const { audioMuted } = gameState.current;
        console.log(
          `\n\n~~~ [LS] [GP] audioMuted changed ~~ ${!audioMuted}\n\n`,
        );
        setAudioMuted(!audioMuted);
      },
    );

    const unsubCoins = subscribeToEvent(EVENT_COIN_COLLISION, ({ points }) => {
      const { coins } = gameState.current;
      log("\n\n ~~~ [LS] [GP] coins collected", coins, coins + points);
      setCoins(coins + points);
    });

    const unsubGameStart = subscribeToEvent(EVENT_PLAYER_START, () => {
      const playerDataManager = PlayerDataManager.getInstance();
      const { highscore } = gameState.current;

      if (!playerDataManager.isLoaded) {
        warn(
          "~~~ [LS] [GP] PlayerDataManager is not loaded not setting highscore",
        );
      }

      if (
        playerDataManager.isLoaded &&
        playerDataManager.getRankData().roads_rank > highscore
      ) {
        log(
          "\n\n ~~~ [LS] [GP] rank vs highscore ~~",
          playerDataManager.getRankData().roads_rank,
          highscore,
        );
        setHighscore(playerDataManager.getRankData().roads_rank);
      }

      console.log("\n\n ~~~ [LS] [GP] PLAYER START ~~");

      setCoins(0);
      setGems(0);
      setScore(0);
      setXp(0);
      setCanWriteToLS(() => {
        console.log("\n\n~~~ [LS] [GP] Can write to LS ~~ \n\n");
        return true;
      });
    });

    const unsubScore = subscribeToEvent(
      EVENT_GAME_SCORE_CHANGED,
      (newScore) => {
        const { score } = gameState.current;
        log("\n\n ~~~ [LS] [GP] score collected", newScore, score);
        if (score < newScore) setScore(newScore);
      },
    );

    const unsubRestart = subscribeToEvent(EVENT_RESTART_GAME, () => {
      log(`\n\n ~~~ [LS] [GP] Received event: ${EVENT_RESTART_GAME}`);
      setScore(0);
      setGems(0);
      setCoins(0);

      // TODO: refactor this hack
      log("\n\n ~~~ [LS] [GP] Restarting ~~", engine);
      triggerEvent(EVENT_GAME_STATE_CHANGE, State.Game.gameOver);
      setTimeout(() => {
        triggerEvent(EVENT_GAME_STATE_CHANGE, State.Game.none);
      });
    });

    const unsubGameOver = subscribeToEvent(EVENT_GAME_OVER, async (data) => {
      log("\n\n ~~~ [LS] [GP] Game over ~~", data);

      setCanWriteToLS(false);

      let points = data?.points ?? score;

      if (!points) {
        points = score;
      }

      const handleGameOverResults = await GameOverModel.HandleGameOver(
        gameState.current,
        points,
      );

      const { highscore, gems, coins, globalPosition } = handleGameOverResults;
      setHighscore(highscore);
      setGems(gems);
      setCoins(coins);
      setGlobalPosition(globalPosition);

      triggerEvent(EVENT_GLOBAL_POSITION_SET, globalPosition);
    });

    return () => {
      unsubCoins();
      unsubGameStart();
      unsubScore();
      unsubGameOver();
      unsubRestart();
      unsubAudioMuteChanged();
    };
  }, []);

  useLayoutEffect(() => {
    console.log("~~~ [LS] gameState.current", gameState.current);
    gameState.current = Object.assign(gameState.current, {
      character,
      highscore,
      score,
      coins,
      gems,
      xp,
      audioMuted,
    });
    log("~~~ [LS] gameState.current", gameState.current);
    if (!canWriteToLS) {
      console.log(
        "~~~ [LS] Not Loaded, so not saving data ~~",
        character,
        highscore,
        coins,
        xp,
        gems,
        audioMuted,
      );
      return;
    }

    (async () => {
      console.log("~~~~ WTF!!!!! ~~~~");
      await cacheAsync({
        character,
        highscore,
        coins,
        xp,
        gems,
        audioMuted,
      });
      console.log(
        "~~~ [LS] Save data ~~",
        character,
        highscore,
        coins,
        xp,
        gems,
        audioMuted,
      );
    })();
  }, [character, highscore, coins, xp, gems, audioMuted]);

  useLayoutEffect(() => {
    log("~~~ [LS] looking to set high score ~~", score, highscore);
    if (score > highscore) {
      setHighscore(score);
    }
    gameState.current.score = score;
  }, [score]);

  useLayoutEffect(() => {
    AudioManager.MUTED = audioMuted;
    console.log("\n\n ~~~ [LS] audioMuted", audioMuted);
  }, [audioMuted]);

  useEffect(() => {
    const parseModulesAsync = async () => {
      try {
        const {
          character = defaultState.character,
          coins = defaultState.coins,
          xp = defaultState.xp,
          gems = defaultState.gems,
          audioMuted = defaultState.audioMuted,
        } = await rehydrateAsync();

        console.log(
          "\n\n ~~~ [LS] rehydrated ~~ character, coins, xp, gems, audioMuted",
          character,
          coins,
          xp,
          gems,
          audioMuted,
        );

        setCharacter(character);
        setHighscore(PlayerDataManager.currentRank());
        setCoins(coins);
        setXp(xp);
        setGems(gems);
        setAudioMuted(audioMuted);
      } catch (ignored) {
        if (ENV_DEBUG) {
          console.warn(
            "~~~ [LS] Failed to load session storage data ~~",
            ignored,
          );
        }
      }
      //   setLoaded(true);
    };

    parseModulesAsync();
  }, []);

  return (
    <GameContext.Provider
      value={{
        character,
        score,
        highscore,
        coins,
        xp,
        gems,
        globalPosition,
        usersName,
        traits,
        setTraits: (traits) => {
          setTraits(traits);
        },
        subscribeToEvent,
        triggerEvent,
        subscribeEventOnce,
        setGameState: (state) => {
          if (engine) engine.gameState = state;
          triggerEvent(EVENT_GAME_STATE_CHANGE, state);
        },
        gameState: engine ? engine.gameState : State.Game.none,
        engine,
        setEngine: (engine) => {
          setEngine(engine);
        },
      }}
    >
      {children}
    </GameContext.Provider>
  );
}
