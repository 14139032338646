import React, { useEffect, useMemo, useState } from "react";
import { gameEventEmitter } from "../context/GameContext";
import {
  EVENT_LOADING_PROGRESS,
  EventLoadingProgress,
} from "../src/GameEvents";

import { PUBLIC_ENVIRONMENT } from "@env";
import {
  View,
  StyleSheet,
  ImageBackground,
  Dimensions,
  Text,
} from "react-native";
import ProgressBar from "../components/ProgressBar/ProgressBar";
import useDimensions from "../src/hooks/useDimensions";
import PlatformScaler from "../components/PlatformScaler";

const bgImage = require("../assets/images/ui/iglooBgRow.svg");
const logoImage = require("../assets/images/ui/penguDashLogo.png");

const getStyles = (width: number, height: number) => {
  return StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: "#6BCAFF",
      overflow: "hidden",
    },
    pattern: {
      flexDirection: "row",
      flexWrap: "wrap",
      position: "absolute",
      ...StyleSheet.absoluteFillObject,
    },
    fullWindowBg: {
      flex: 1,
      flexDirection: "row",
      flexWrap: "wrap",
      backgroundColor: "#6BCAFF", // Your background color
    },
    backgroundImage: {
      position: "relative",
      left: 10,
      width: "100vw", // Adjust the width and height according to your image's aspect ratio
      height: 41,
      marginTop: 10, // This will create space (like padding) between each image
      backgroundImage: `url(${bgImage})`,
      backgroundRepeat: "repeat",
    },
    backgroundImageOffset: {
      position: "relative",
      width: "100vw", // Adjust the width and height according to your image's aspect ratio
      height: 41,
      marginTop: 10, // This will create space (like padding) between each image
      marginLeft: -10,
      backgroundImage: `url(${bgImage})`,
      backgroundRepeat: "repeat",
    },
    loadingContainer: {
      flex: 1,
      paddingBottom: 32,
    },
    logo: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
    },
    tCenterView: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "transparent",
      zIndex: 1,
    },
    logoImage: {
      width: 478, // Fixed width
      height: 420, // Fixed height
      resizeMode: "contain",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 2,
    },
    progressBarContainer: {
      zIndex: 99,
      alignItems: "center",
      gap: 16,
    },
    progressBarText: {
      fontSize: 36,
      fontFamily: "tt_trailer",
      color: "#ffffff",
      textTransform: "uppercase",
    },
  });
};

// TODO: get to work with react native
const LoadingScreen: React.FC<{}> = () => {
  // State variables
  const [loadingMessage, setLoadingMessage] = useState("Loading...");
  const [environment] = useState(PUBLIC_ENVIRONMENT ?? " UNKNOWN");
  // Effect
  const dimensions = useDimensions();

  const styles = useMemo(() => {
    return getStyles(dimensions.window.width, dimensions.window.height);
  }, [dimensions.window.width, dimensions.window.height]);

  useEffect(() => {
    // This is rendered outside the Game Provider context so must use
    // the gameEventEmitter directly and not the subscribeToEvent hook
    const eventLoadingFn = (loadingProgress: EventLoadingProgress) => {
      setLoadingMessage(loadingProgress.msg);
    };
    gameEventEmitter.on(EVENT_LOADING_PROGRESS, eventLoadingFn);

    return () => {
      // Clean up code (optional)
      gameEventEmitter.off(EVENT_LOADING_PROGRESS, eventLoadingFn);
    };
  }, []);

  return (
    <View style={styles.container}>
      <View style={styles.pattern}>
        {Array.from({ length: 20 }, (_, row) => (
          <View
            key={`${row}`}
            style={
              row % 2 === 0
                ? styles.backgroundImage
                : styles.backgroundImageOffset
            }
          />
        ))}
      </View>
      <View style={styles.loadingContainer}>
        <View style={styles.logo}>
          <PlatformScaler>
            <View style={styles.tCenterView}>
              <ImageBackground
                source={{ uri: logoImage }}
                style={styles.logoImage}
              />
            </View>
          </PlatformScaler>
        </View>
        <View style={styles.progressBarContainer}>
          <ProgressBar />
          <Text style={styles.progressBarText}>Loading</Text>
        </View>
      </View>
    </View>
  );
};

export default LoadingScreen;
