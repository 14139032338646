import { StyleSheet } from "react-native";
import selectBreakpoint from "../../../src/Utils/selectBreakpoint";

const styles = (breakpoint: Breakpoint) =>
  StyleSheet.create({
    container: {
      flex: 1,
    },
    headerContainer: {
      backgroundColor: "#CEEDFF",
      flexDirection: "row",
    },
    headerRank: {
      width: "55%",
      padding: 10,
      paddingLeft: 35,
    },
    headerItemText: {
      fontFamily: "menco_black",
      color: "#666666",
      fontSize: selectBreakpoint(breakpoint, {
        desktop: 24,
        tablet: 24,
        mobile: 16,
      }),
      whiteSpace: "pre",
    },
    headerReward: {
      width: "15%",
      padding: 10,
      alignItems: "center",
    },
    headerHighScore: {
      width: "30%",
      padding: 10,
      alignItems: "center",
    },
    bodyContainer: {
      flex: 1,
      backgroundColor: "#EBF5FF",
      ...selectBreakpoint(breakpoint, {
        mobile: {
          paddingBottom: 80,
        },
        tablet: {
          paddingBottom: 140,
        },
      }),
    },
  });

export default styles;
