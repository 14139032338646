import * as amplitude from "@amplitude/analytics-browser";
import { AMPLITUDE_API_KEY } from "@env";
import { PlayerDataManager } from "../Models/PlayerDataManager";
import { useMemo, useState } from "react";
import { memoize } from "../Utils/FunctionUtils";
import { log, warn } from "../Utils/LogUtils";

let isInitialized = false;

export const useAmplitude = () => {
  const playerData: PlayerDataManager = PlayerDataManager.getInstance();

  const initializeAmplitude = async (): Promise<void> => {
    warn("~~~ [AMPLITUDE] Amplitude initialization");
    if (!AMPLITUDE_API_KEY) {
      warn("~~~ [AMPLITUDE] AMPLITUDE_API_KEY is not set");
      return;
    }

    if (isInitialized) {
      warn("~~~ [AMPLITUDE] Amplitude is already initialized");
      return;
    }

    warn("~~~ [AMPLITUDE] Amplitude going to initialize");
    const t = await amplitude.init(AMPLITUDE_API_KEY, {
      defaultTracking: true,
    }).promise;
    warn("~~~ [AMPLITUDE] Amplitude is initialized");
    isInitialized = true;
  };

  const initializePlayerByID = async (): Promise<void> => {
    if (isInitialized) {
      warn("~~~ [AMPLITUDE] Amplitude is already initialized (2)");
      return;
    }
    await PlayerDataManager.init();
    amplitude.setUserId(playerData.id);
  };

  const trackEvent = (eventName: string): void => {
    if (!isInitialized) {
      warn("~~~ [AMPLITUDE] Amplitude is not initialized");
      return;
    }

    log("~~~ [AMPLITUDE] Track event", eventName);

    amplitude.track(eventName, {
      name: eventName,
      playerID: playerData.id,
      playerName: playerData.name,
    });
  };

  return { initializeAmplitude, initializePlayerByID, trackEvent };
};
