import { Audio } from "expo-av";
import AudioFiles from "./Audio";
import { log, warn, err } from "../src/Utils/LogUtils";
import { gameEventEmitter } from "../context/GameContext";
import {
  EVENT_GAME_OVER,
  EVENT_GAME_START,
  EVENT_GAME_TOGGLE_MUTE,
  EVENT_PLAYER_START,
} from "./GameEvents";
import { TWEEN } from "three/examples/jsm/libs/tween.module.min";
import { AVPlaybackStatus, AVPlaybackStatusToSet } from "expo-av/src/AV";

class AudioManager {
  sounds = AudioFiles;
  lastVolume = 0;
  audioFileMoveIndex = 0;

  // MUTED = Platform.OS === "web";
  MUTED = false;

  playMoveSound = async (isRandom = true) => {
    let numClips = Object.keys(this.sounds.penguin.move).length;
    if (isRandom) {
      await this.playAsync(
        this.sounds.penguin.move[`${Math.floor(Math.random() * numClips)}`],
      );
    } else {
      await this.playAsync(
        this.sounds.penguin.move[`${this.audioFileMoveIndex}`],
      );
      this.audioFileMoveIndex = (this.audioFileMoveIndex + 1) % numClips;
    }
  };

  playQuestCompleteSound = async () => {
    await this.playAsync(this.sounds.quest_complete);
  };

  playRewardSound = async () => {
    await this.playAsync(this.sounds.pickup.reward);
  };

  playGemSound = async () => {
    await this.playAsync(this.sounds.pickup.gem);
  };

  playGameStartSound = async () => {
    await this.playAsync(this.sounds.game_start);
  };

  playBannerSound = async () => {
    await this.playAsync(this.sounds.banner);
  };

  playTrainHitSound = async () => {
    await this.playAsync(this.sounds.train.die["0"]);
  };

  playTrainMoveSound = async () => {
    await this.playAsync(this.sounds.train.move["0"]);
  };

  playCarHornRandom = async () => {
    if (Math.floor(Math.random() * 2) === 0) {
      await this.playAsync(this.sounds.sled.alert);
    }
  };

  playDeathSound = async () => {
    await this.playAsync(
      this.sounds.penguin.die[`${Math.floor(Math.random() * 2)}`],
    );
  };

  playCarHitSound = async () => {
    await this.playAsync(
      this.sounds.sled.die[`${Math.floor(Math.random() * 2)}`],
    );
  };

  playCoinCollectedSound = async () => {
    log("coin sound");
    const sound = this.sounds.pickup.coin;
    await this.playAsync(sound);
  };

  _soundCache = {};

  getIdleSoundAsync = async (resourceId) => {
    log(`~~~~ [AM] SoundCache ${resourceId}:`, this._soundCache[resourceId]);
    if (this._soundCache[resourceId]) {
      for (const sound of this._soundCache[resourceId]) {
        const status = await sound.getStatusAsync();
        if (!status.isLoaded) {
          log(
            `~~~~ [AM] SoundCache loading ${resourceId}:`,
            this._soundCache[resourceId],
          );
          await sound.loadAsync(resourceId, {}, true);
          return sound;
        }
        if (!status.isPlaying) {
          return sound;
        }
      }
    }
    return null;
  };

  createIdleSoundAsync = async (resourceId) => {
    if (!this._soundCache[resourceId]) {
      this._soundCache[resourceId] = [];
    }
    const { sound } = await Audio.Sound.createAsync(resourceId);
    log(`~~~~ Creating ${resourceId} sound`);
    this._soundCache[resourceId].push(sound);
    log(`~~~~ Creating ${resourceId} sound`, this._soundCache[resourceId]);
    return sound;
  };

  clearIdleSoundAsync = async (resourceId) => {
    log(`~~~~ [AM] SoundCache ${resourceId}:`, this._soundCache[resourceId]);
    if (resourceId in this.sounds) {
      const soundName = this.sounds[resourceId];
      log(`~~~~ [AM] SoundCache ${soundName}:`, this._soundCache[soundName]);
      if (this._soundCache[soundName]) {
        for (const sound of this._soundCache[soundName]) {
          const soundStatus = await sound.getStatusAsync();
          if (soundStatus.isPlaying) {
            await sound.stopAsync();
          }

          await sound.unloadAsync();
        }

        log(
          `~~~~ [AM] Removing SoundCache ${soundName}:`,
          this._soundCache[soundName],
        );
        this._soundCache[soundName] = [];
        log(
          `~~~~ [AM] SoundCache rm ${soundName}:`,
          this._soundCache[soundName],
        );
      }
    } else {
      warn("Audio doesn't exist", resourceId);
    }
  };

  playAsync = async (soundObject, isLooping = false, startOver = true) => {
    if (this.MUTED) return;

    let sound = await this.getIdleSoundAsync(soundObject);
    if (!sound) {
      log(`~~~~~ [AM] creating ${soundObject} sound`);
      sound = await this.createIdleSoundAsync(soundObject);
    } else {
      log(`~~~~~ [AM] resetting ${soundObject} sound`);
      await sound.setPositionAsync(0);
    }
    if (isLooping) {
      await sound.setIsLoopingAsync(true);
    }
    if (startOver) {
      await sound.setPositionAsync(0);
    }
    return await sound.playAsync();
  };
  stopAsync = async (name) => {
    if (name in this.sounds) {
      const soundObject = this.sounds[name];
      if (this._soundCache[soundObject]) {
        for (const sound of this._soundCache[soundObject]) {
          const status = await sound.getStatusAsync();
          if (status.isPlaying) {
            sound.stopAsync();
          }
        }
      }
    } else {
      warn("Audio doesn't exist", name);
    }
  };
  volumeAsync = async (name, volume) => {
    if (name in this.sounds) {
      const soundObject = this.sounds[name];
      if (this._soundCache[soundObject]) {
        for (const sound of this._soundCache[soundObject]) {
          void sound.setVolumeAsync(volume);
        }
      }
    } else {
      warn("Audio doesn't exist", name);
    }
  };

  pauseAsync = async (name) => {
    if (name in this.sounds) {
      const soundObject = this.sounds[name];
      if (this._soundCache[soundObject]) {
        for (const sound of this._soundCache[soundObject]) {
          const status = await sound.getStatusAsync();
          if (status.isPlaying) {
            void sound.pauseAsync();
          }
        }
        this._soundCache[soundObject] = [];
      }
    } else {
      warn("Audio doesn't exist", name);
    }
  };

  pauseAllAsync = async () => {
    const names = Object.keys(this.sounds);
    for (const name of names) {
      const soundObject = this.sounds[name];
      if (this._soundCache[soundObject]) {
        for (const sound of this._soundCache[soundObject]) {
          const status = await sound.getStatusAsync();
          if (status.isPlaying) {
            void sound.pauseAsync();
          }
        }
      }
    }
  };

  resumeAllAsync = async () => {
    const names = Object.keys(this.sounds);
    for (const name of names) {
      const soundObject = this.sounds[name];
      if (this._soundCache[soundObject]) {
        for (const sound of this._soundCache[soundObject]) {
          const status = await sound.getStatusAsync();
          if (!status.isPaused) {
            void sound.playAsync();
          }
        }
      }
    }
  };

  stopAllAsync = async () => {
    const names = Object.keys(this.sounds);
    for (const name of names) {
      const soundObject = this.sounds[name];
      if (this._soundCache[soundObject]) {
        for (const sound of this._soundCache[soundObject]) {
          const status = await sound.getStatusAsync();
          if (status.isPlaying) {
            void sound.stopAsync();
          }
        }
        this._soundCache[soundObject] = [];
      }
    }
  };

  muteAllAsync = async () => {
    this.MUTED = true;
    return this.pauseAllAsync();
  };

  unmuteAllAsync = async () => {
    this.MUTED = false;
    return this.resumeAllAsync();
  };

  get assets() {
    return AudioFiles;
  }

  setupAsync = async () => {
    // noop -- maybe preload some common sounds upfront
    gameEventEmitter.on(EVENT_PLAYER_START, async () => {
      log("~~~~~ [AM] bg_music EVENT_PLAYER_START");
      // this.sounds.bg_music.setVolumeAsync(0.05);
      window["AudioManager"] = this;

      await this.playAsync(this.sounds.bg_music, true, false);
      await this.volumeAsync("bg_music", 0.0);

      let obj = { value: 0.0 };

      // Create the tween
      new TWEEN.Tween(obj)
        .to({ value: 0.3 }, 5000) // 5000 ms = 5 seconds
        .onUpdate(() => {
          // This will log the animated value at each step
          this.volumeAsync("bg_music", obj.value);
        })
        .onComplete(() => {
          this.lastVolume = 0.3;
        })
        .start();

      void this.playGameStartSound();
    });

    gameEventEmitter.on(EVENT_GAME_OVER, () => {
      // this.clearIdleSoundAsync("bg_music")
      this.stopAsync("bg_music");
    });

    gameEventEmitter.on(EVENT_GAME_TOGGLE_MUTE, () => {
      if (!this.MUTED) {
        this.muteAllAsync();
      } else {
        this.unmuteAllAsync();
      }
    });

    return true;
  };
}

export default new AudioManager();
