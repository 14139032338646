import React, { CSSProperties, useEffect, useMemo, useState } from "react"

import { gameEventEmitter } from "../../context/GameContext"
import { EVENT_LOADING_DONE, EVENT_LOADING_PROGRESS } from "../../src/GameEvents"
interface Props {
}

const getStyles = (progressWidth: number) => {
    return {
        container: {
            borderRadius: '26px',
            overflow: 'hidden',
            position: 'relative',
            width: '340px',
            height: '26px',
        } as Partial<CSSProperties>,
        backgroundView: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            borderRadius: '26px',
            backgroundColor: '#F2F2F2',
            flexShrink: 0,
        } as Partial<CSSProperties>,
        progressBar: {
            position: 'absolute',
            width: `${progressWidth}%`, // Adjust the width as per the progress
            height: '100%',
            borderTopLeftRadius: '26px',
            borderBottomLeftRadius: '26px',
            background: 'linear-gradient(90deg, rgba(72, 155, 223, 0.67) 0%, rgba(83, 124, 245, 0.85) 100%)',
        } as Partial<CSSProperties>,
        innerShadow: {
            flex: 1,
            height: '100%',
            boxShadow: 'inset 0 1.31274px 2.62548px 0 rgba(255, 255, 255, 0.25), inset 0 -0.65637px 1.96911px 0 rgba(0, 0, 0, 0.15)',
        } as Partial<CSSProperties>,
    };
}

const ProgressBar: React.FC<Props> = ({}) => {
    const [progress, setProgress] = useState(10);

    const styles = useMemo(() => getStyles(progress), [progress]);

    useEffect(() => {
        const setProgressIndicator = ({progress: newProgress }) => {
            const currentProgress = newProgress ?? (progress + 1);
            setProgress(Math.min(currentProgress, 100));
        }

        gameEventEmitter.on(EVENT_LOADING_PROGRESS, setProgressIndicator);
        gameEventEmitter.once(EVENT_LOADING_DONE, () => {
            setProgress(100);
        });
        return () => {
            gameEventEmitter.off(EVENT_LOADING_PROGRESS, setProgressIndicator);
        }
    }, [])

    return (
        <div style={styles.container}>
            <div style={styles.backgroundView}></div>
            <div style={styles.progressBar}>
                <div style={styles.innerShadow}></div>
            </div>
        </div>
    );
};


export default ProgressBar;
