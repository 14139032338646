import { FC, useMemo, useState } from "react";
import { Image, View } from "react-native";
import { gameEventEmitter } from "../../context/GameContext";
import {
  EVENT_GAME_STATE_CHANGE,
  EVENT_GAME_TOGGLE_MUTE,
} from "../../src/GameEvents";
import state from "../../src/state";
import pauseArtWork from "../../assets/images/ui/pause-artwork.png";
import AudioManager from "../../src/AudioManager";
import useBreakpoint from "../../src/hooks/useBreakpoint";
import styles from "./styles";
import BackButton from "../../components/BackButton";
import PauseScreenButton from "../../components/PauseScreenButton";

interface Props {}
const PauseScreen: FC<Props> = () => {
  const [isMuted, setIsMuted] = useState(AudioManager.MUTED);

  const breakpoint = useBreakpoint();
  const style = useMemo(() => styles(breakpoint), [breakpoint]);

  const handleResume = () => {
    gameEventEmitter.emit(EVENT_GAME_STATE_CHANGE, state.Game.playing);
  };

  const handleToggleSound = () => {
    gameEventEmitter.emit(EVENT_GAME_TOGGLE_MUTE);
    setIsMuted((prev) => !prev);
  };

  const handleShowControls = () => {
    gameEventEmitter.emit(EVENT_GAME_STATE_CHANGE, state.Game.showingControls);
  };

  const handleGoMainMenu = () => {
    gameEventEmitter.emit(
      EVENT_GAME_STATE_CHANGE,
      state.Game.returningToModeSelect
    );
  };

  const items = useMemo(
    () => [
      {
        icon: require("../../assets/images/ui/play.png"),
        iconHovered: require("../../assets/images/ui/play-pressed.png"),
        aspectRatio: 103 / 118,
        title: "Resume",
        onPress: handleResume,
      },
      {
        icon: require("../../assets/images/ui/sound-on.png"),
        iconHovered: require("../../assets/images/ui/sound-on-pressed.png"),
        aspectRatio: 156 / 128,
        title: "Sound on",
        onPress: handleToggleSound,
        isHidden: !isMuted,
      },
      {
        icon: require("../../assets/images/ui/sound-off.png"),
        iconHovered: require("../../assets/images/ui/sound-off-pressed.png"),
        aspectRatio: 158 / 150,
        title: "Sound off",
        onPress: handleToggleSound,
        isHidden: isMuted,
      },
      {
        icon: require("../../assets/images/ui/controls-blue.png"),
        iconHovered: require("../../assets/images/ui/controls-blue-pressed.png"),
        aspectRatio: 137 / 136,
        title: "Controls",
        onPress: handleShowControls,
      },
      {
        icon: require("../../assets/images/ui/main-menu.png"),
        iconHovered: require("../../assets/images/ui/main-menu-pressed.png"),
        aspectRatio: 1,
        title: "Main menu",
        onPress: handleGoMainMenu,
      },
    ],
    [isMuted]
  );

  return (
    <View style={style.container}>
      <BackButton onPress={handleResume} />
      <View style={style.wrapper}>
        {breakpoint !== "mobile" && (
          <View style={style.pauseArtworkWrapper}>
            <Image source={pauseArtWork} style={style.pauseArtWorkImage} />
          </View>
        )}
        <View style={style.menuContainer}>
          {items.map((item) =>
            item.isHidden ? null : (
              <PauseScreenButton
                key={item.title}
                title={item.title}
                defaultIcon={item.icon}
                hoveredIcon={item.iconHovered}
                iconAspectRatio={item.aspectRatio}
                onPress={item.onPress}
                style={style}
              />
            )
          )}
        </View>
      </View>
    </View>
  );
};

export default PauseScreen;
