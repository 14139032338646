import { StyleSheet } from "react-native";
import selectBreakpoint from "../../src/Utils/selectBreakpoint";

const styles = (breakpoint: Breakpoint) => StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'rgba(107,202,255,0.7)',
    ...StyleSheet.absoluteFillObject
  },
  backButtonContainer: {
    gap: 32,
    padding: 32,
    flexDirection: 'row',
    alignItems: 'center',
    position: 'absolute'
  },
  backButton: {
    height: 100,
    width: 104
  },
  wrapper: {
    flex: 1,
    marginHorizontal: 'auto',
    paddingVertical: 32,
    alignItems: 'center',
    justifyContent: 'center',
    ...selectBreakpoint(breakpoint, {
      desktop: {
        width: 1200,
      },
      tablet: {
        width: '100%',
        paddingHorizontal: 32
      },
      mobile: {
        width: '100%',
        paddingHorizontal: 16
      }
    })
  },
  controlsImage: {
    ...selectBreakpoint(breakpoint, {
      desktop: {
        aspectRatio: 962 / 572,
        width: '100%'
      },
      tablet: {
        aspectRatio: 1398 / 1008,
        width: '75%'
      },
      mobile: {
        aspectRatio: 1398 / 1008,
        width: '100%'
      }
    })
  }
})

export default styles