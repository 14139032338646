import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Text,
  TouchableOpacity,
  View,
  ImageBackground,
  Image,
  Animated,
  LayoutChangeEvent,
  LayoutRectangle,
  Pressable,
} from "react-native";
import { LinearGradient } from "expo-linear-gradient";

import cloudGridImage from "../../assets/images/ui/cloud-grid.png";
import casualModeImage from "../../assets/images/ui/casual-mode.png";
import challengeModeImage from "../../assets/images/ui/challenge-mode.png";
import leaderboardImage from "../../assets/images/ui/leaderboard.png";
import mobileLeaderboardImage from "../../assets/images/ui/mobile-leaderboard.png";
import playNowImage from "../../assets/images/ui/play-now.png";
import desktopPlayNowBackground from "../../assets/images/ui/desktop-play-now-background.png";
import mobilePlayNowImage from "../../assets/images/ui/mobile-play-now.png";
import controlsIcon from "../../assets/images/ui/controls.png";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import styles from "./styles";
import useBreakpoint from "../../src/hooks/useBreakpoint";
import { useAmplitude } from "../../src/hooks/useAmplitude";
import BackButton from "../../components/BackButton";

import { PUDGY_WORLD_URL } from "@env";

const URL_PUDGY_WORLD = PUDGY_WORLD_URL ?? "https://qa.pudgyworld.com/arcade";

const AnimatedImageBackground =
  Animated.createAnimatedComponent(ImageBackground);

const AnimatedPressable = Animated.createAnimatedComponent(Pressable);

const GameModeScreen = () => {
  const { initializeAmplitude, initializePlayerByID, trackEvent } =
    useAmplitude();

  const setupAmplitude = useCallback<() => Promise<any>>(async () => {
    await initializeAmplitude();
    return initializePlayerByID();
  }, []);

  useEffect(() => {
    setupAmplitude().then(() => {
      trackEvent("Mode Select Screen");
    });
  }, []);

  const navigation = useNavigation<StackNavigationProp<Navigation.Route>>();
  const [gameModeLayout, setGameModeLayout] = useState<LayoutRectangle | null>(
    null
  );
  const casualModeOverlayOpacity = useRef(new Animated.Value(0)).current;
  const casualModeImageScale = useRef(new Animated.Value(1)).current;

  const breakpoint = useBreakpoint();
  const style = useMemo(() => styles(breakpoint), [breakpoint]);

  const handleCasualModePress = useCallback(() => {
    navigation.navigate("Game");
  }, []);

  const handleLeaderboardsPress = useCallback(() => {
    navigation.navigate("Leaderboards", {
      shouldGoGameMode: true,
    });
  }, []);

  const handleControlsPress = useCallback(() => {
    navigation.navigate("Controls", {
      shouldGoGameMode: true,
    });
  }, []);

  const fadeIn = useCallback(() => {
    Animated.timing(casualModeOverlayOpacity, {
      toValue: 1,
      duration: 250,
      useNativeDriver: true,
    }).start();
    Animated.spring(casualModeImageScale, {
      toValue: 1.1,
      useNativeDriver: true,
    }).start();
  }, []);

  const fadeOut = useCallback(() => {
    Animated.timing(casualModeOverlayOpacity, {
      toValue: 0,
      duration: 250,
      useNativeDriver: true,
    }).start();
    Animated.timing(casualModeImageScale, {
      toValue: 1,
      duration: 250,
      useNativeDriver: true,
    }).start();
  }, [gameModeLayout]);

  const handleLayout = useCallback(({ nativeEvent }: LayoutChangeEvent) => {
    setGameModeLayout(nativeEvent.layout);
  }, []);

  useEffect(() => {
    if (gameModeLayout) fadeOut();
  }, [gameModeLayout]);

  return (
    <ImageBackground
      source={cloudGridImage}
      resizeMode="cover"
      style={style.background}
    >
      <BackButton
        title="Select game mode"
        onPress={() => {
          window.location.href = URL_PUDGY_WORLD;
          trackEvent("Exit Game Mode Select");
        }}
        isRelative
      />
      <View style={style.container}>
        <View style={style.playModeContainer}>
          <View
            style={style.playMode}
            // @ts-expect-error this works on web
            onMouseEnter={fadeIn}
            onMouseLeave={fadeOut}
            onLayout={handleLayout}
          >
            <LinearGradient
              colors={["#56A4FE", "transparent"]}
              style={style.playModeGradient}
              start={{ x: 0.28, y: 0 }}
              end={{ x: 1.07, y: 0 }}
            >
              <AnimatedImageBackground
                source={casualModeImage}
                style={[
                  style.playModeBackground,
                  { transform: [{ scale: casualModeImageScale }] },
                ]}
                resizeMode="cover"
              >
                <Animated.Text
                  style={[
                    style.cardTitle,
                    {
                      opacity: casualModeImageScale.interpolate({
                        inputRange: [1, 1.1],
                        outputRange: [1, 0],
                      }),
                    },
                  ]}
                >
                  Casual Mode
                </Animated.Text>
              </AnimatedImageBackground>
            </LinearGradient>
            {breakpoint !== "mobile" && (
              <AnimatedPressable
                onPress={handleCasualModePress}
                style={[
                  style.casualModeOverlay,
                  { opacity: casualModeOverlayOpacity },
                ]}
              >
                <View
                  style={{
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 16,
                  }}
                >
                  <Text style={[style.smCardTitle, style.overlayTitle]}>
                    Earn coins, improve your score, and practice for the big
                    leagues!
                  </Text>
                </View>
                <View
                  style={{ alignItems: "center", justifyContent: "center" }}
                >
                  <Image
                    source={
                      breakpoint === "desktop"
                        ? desktopPlayNowBackground
                        : playNowImage
                    }
                    style={style.casualPlayModeImage}
                  />
                  <Text style={style.playNowText}>Play Now</Text>
                </View>
              </AnimatedPressable>
            )}
            {breakpoint === "mobile" && (
              <Pressable
                onPress={handleCasualModePress}
                style={style.mobileStartNow}
              >
                <Image
                  source={mobilePlayNowImage}
                  style={{ width: "100%", aspectRatio: 652 / 187 }}
                />
              </Pressable>
            )}
          </View>
          <TouchableOpacity
            onPress={handleCasualModePress}
            style={[style.playMode, style.challengeCard]}
            disabled
          >
            <View style={style.comingSoonOverlay} />
            <View style={style.comingSoon}>
              <Text style={style.cardTitle}>Coming soon</Text>
            </View>
            <LinearGradient
              colors={["#95FD71", "#F8D278", "#F29483", "#EA7BF8", "#688DFF"]}
              style={style.playModeGradient}
              locations={[0, 0.25, 0.5, 0.75, 1]}
            >
              <ImageBackground
                source={challengeModeImage}
                style={style.playModeBackground}
                resizeMode="cover"
              >
                <Text style={style.cardTitle}>Challenge Mode</Text>
              </ImageBackground>
            </LinearGradient>
          </TouchableOpacity>
        </View>
        <View style={style.menuContainer}>
          <TouchableOpacity
            style={{ flex: 1 }}
            onPress={handleLeaderboardsPress}
          >
            <LinearGradient
              colors={["#56A4FE", "transparent"]}
              style={style.leaderboard}
              start={{ x: 0.28, y: 0 }}
              end={{ x: 1.07, y: 0 }}
            >
              <Image
                source={
                  breakpoint === "mobile"
                    ? mobileLeaderboardImage
                    : leaderboardImage
                }
                style={style.leaderboardImage}
                resizeMode="contain"
              />
              <Text style={[style.smCardTitle, style.leaderboardTitle]}>
                Leaderboards
              </Text>
            </LinearGradient>
          </TouchableOpacity>
          <TouchableOpacity
            style={style.controlsWrapper}
            onPress={handleControlsPress}
          >
            <LinearGradient
              colors={["#AE6C9F", "transparent"]}
              style={style.controls}
              start={{ x: 0.28, y: 0 }}
              end={{ x: 1.07, y: 0 }}
            >
              <Image source={controlsIcon} style={style.controlsIcon} />
              <Text style={[style.smCardTitle]}>Controls</Text>
            </LinearGradient>
          </TouchableOpacity>
        </View>
      </View>
    </ImageBackground>
  );
};

export default GameModeScreen;
