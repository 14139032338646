// Define the HTTP methods as constants for better type safety

import { PUBLIC_ENVIRONMENT } from "@env";

import { Identity, Traits } from "./types";
import { mockUserRes } from "./mockData/UserResMock";
import { LeaderBoardResMock } from "./mockData/LeaderBoardResMock";
import { mockUserGlobalPositionData } from "./mockData/UserRankResMock";
import { MOCK_ON_FAIL } from "@env";

export const ON_FAIL_MOCK = MOCK_ON_FAIL?.toLowerCase() === "true" ?? false;

export type Environment = "development" | "staging" | "production";

const PUBLIC_ENVIRONMENT_API: Environment =
  (PUBLIC_ENVIRONMENT as Environment) ?? "staging";

export const HTTPMethod = {
  POST: "POST",
  DELETE: "DELETE",
  GET: "GET",
  PUT: "PUT",
  PATCH: "PATCH",
  OPTIONS: "OPTIONS",
} as const;

// Define the API request body initialization options
export type APIBodyInit = {
  method: (typeof HTTPMethod)[keyof typeof HTTPMethod]; // Use the constant method names
  credentials?: RequestCredentials;
  mode?: RequestMode;
  headers?: HeadersInit;
  body?: BodyInit;
};

// Define the constructor type for API class
export type ConstructorType = {
  baseURL: string;
  credentials?: RequestCredentials;
  headers: HeadersInit;
};

function getURLDetails(url: string): {
  protocol: string;
  host: string;
  port: string | null;
} {
  try {
    const urlObj = new URL(url);
    return {
      protocol: urlObj.protocol.replace(":", ""),
      host: urlObj.hostname,
      port: urlObj.port || null,
    };
  } catch (error) {
    console.error("Invalid URL", error);
    return {
      protocol: "",
      host: "",
      port: null,
    };
  }
}

// Custom error class for handling API errors
export class APIError<Res> extends Error {
  res!: Response;
  data: Res;

  constructor(msg: string, res: Response, data: Res) {
    super(msg);

    this.res = res;
    this.data = data;

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, APIError.prototype);
  }

  error() {
    return "Fetch Error: " + this.message;
  }
}

// Define the API class
export class API {
  init: ConstructorType = {
    baseURL: "",
    credentials: "omit",
    headers: {},
  };

  constructor(init: ConstructorType) {
    this.init.baseURL = init.baseURL;
    this.init.credentials = init.credentials;
    this.init.headers = init.headers;
  }

  // Generalized method for sending API requests
  private async send<Res>(url: string | URL, body: APIBodyInit): Promise<Res> {
    try {
      return await fetch(`${this.init.baseURL}${url}`, body)
        .then(async (res) => {
          console.log(res);
          const data = await res.json();
          if (!res.ok) {
            throw new APIError<Res>("Bad response: ", res, data);
          }

          return data;
        })
        .catch((err) => {
          throw err;
        });
    } catch (error) {
      if (error instanceof APIError) {
        // Handle different HTTP status codes gracefully
        switch (error.res.status) {
          case 200:
            return error.data;
          case 400:
            throw new Error(
              `[Fetch ${
                HTTPMethod[body.method]
              }]: Bad request: ${JSON.stringify(error.data)}`,
            );
          case 401:
            throw new Error(
              `[Fetch ${
                HTTPMethod[body.method]
              }]: Unauthorized: ${JSON.stringify(error.data)}`,
            );
          case 404:
            throw new Error(
              `[Fetch ${HTTPMethod[body.method]}]: Not found: ${JSON.stringify(
                error.data,
              )}`,
            );
          case 500:
            throw new Error(
              `[Fetch ${
                HTTPMethod[body.method]
              }]: Internal server error: ${JSON.stringify(error.data)}`,
            );
          default:
            throw new Error(
              `[Fetch ${HTTPMethod[body.method]}]: Unhandled status code: ${
                error.res.status
              }`,
            );
        }
      } else {
        throw new Error(
          `[${HTTPMethod[body.method]}]: Error with error: ${error}`,
        );
      }
    }
  }

  // Methods for common HTTP request types
  async post<Res>(
    url: string | URL,
    body?: object,
    credentials?: RequestCredentials,
  ): Promise<Res> {
    return await this.send(url, {
      method: HTTPMethod.POST,
      credentials: this.init.credentials || credentials || "omit",
      headers: this.init.headers,
      body: JSON.stringify(body),
    });
  }

  async patch<Res>(
    url: string | URL,
    body?: object,
    credentials?: RequestCredentials,
  ): Promise<Res> {
    return await this.send(url, {
      method: HTTPMethod.PATCH,
      credentials: this.init.credentials || credentials || "omit",
      headers: this.init.headers,
      mode: "cors",
      body: JSON.stringify(body),
    });
  }

  async put<Res>(
    url: string | URL,
    body?: object,
    credentials?: RequestCredentials,
  ): Promise<Res> {
    return await this.send(url, {
      method: HTTPMethod.PUT,
      credentials: this.init.credentials || credentials || "omit",
      headers: this.init.headers,
      mode: "cors",
      body: JSON.stringify(body),
    });
  }

  async get<Res>(
    url: string | URL,
    credentials?: RequestCredentials,
  ): Promise<Res> {
    return await this.send(url, {
      method: HTTPMethod.GET,
      credentials: this.init.credentials || credentials || "omit",
      headers: this.init.headers,
      mode: "cors",
    });
  }

  async delete<Res>(
    url: string | URL,
    body?: object,
    credentials?: RequestCredentials,
  ): Promise<Res> {
    return await this.send(url, {
      method: HTTPMethod.DELETE,
      credentials: this.init.credentials || credentials || "omit",
      headers: this.init.headers,
      mode: "cors",
      body: JSON.stringify(body),
    });
  }

  // Get the base URL
  getBaseURL() {
    return this.init.baseURL;
  }

  // Set custom headers
  setHeaders(headers: HeadersInit): HeadersInit {
    if (!headers) return {};
    if (!this.init.headers) this.init.headers = {};
    this.init.headers = this.init.headers as HeadersInit;
    for (const [k, value] of Object.entries(headers)) {
      const key = k as keyof HeadersInit; // Use a more descriptive type
      this.init.headers[key] = value;
    }

    return this.init.headers;
  }
}

// This connects to the Pudgy-World-Game server relay, which probably
// will not work until we change the CORS settings on the Forever PP server.
function createPwApi() {
  let REAL_API_URL: string;
  switch (PUBLIC_ENVIRONMENT_API) {
    case "production":
      REAL_API_URL = "https://game-api.pudgyworld.com";
      break;
    case "staging":
      REAL_API_URL = "https://game-api-qa.pudgyworld.com";
      break;
    default:
      {
        const { protocol, host, port } = getURLDetails(window.location.href);
        REAL_API_URL = `${protocol}://${host}:8080`; //REAL_API_URL = "http://127.0.0.1:8080";
      }

      break;
  }

  return new API({
    baseURL: REAL_API_URL,
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  });
}

function createPPApi() {
  let REAL_API_URL: string;
  switch (PUBLIC_ENVIRONMENT_API) {
    case "production":
      REAL_API_URL = "https://api.pudgyworld.com/api";
      break;
    case "staging":
      REAL_API_URL = "https://api-qa.pudgyworld.com/api";
      break;
    default:
      {
        const { protocol, host } = getURLDetails(window.location.href);
        REAL_API_URL = `${protocol}://${host}:3000/api`;
      }
      break;
  }

  return new API({
    baseURL: REAL_API_URL,
    credentials: "omit",
    headers: { "Content-Type": "application/json" },
  });
}

export const api = createPwApi();

export const forever = createPPApi();

export type deathRes = {
  message: string;
  coins: number;
  gems: number;
  //postRank: number;
};

/** //! I have become DEATH destroyer of pudgys 💀 */
export const death = async (coins: number, gems: number): Promise<deathRes> => {
  return await api
    .post<deathRes>("/roads/death", { coins, gems })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
      if (ON_FAIL_MOCK) {
        return {
          message: "Mock Death Response",
          coins: 100,
          gems: 5,
          //postRank: number;
        };
      }
      return err.response.data;
    });
};

export type setRankRes = {
  isSuccess: boolean;
  newRank: number;
  //postRank: number;
};

export const setRank = async (points: number): Promise<setRankRes> => {
  return await api
    .post<deathRes>("/roads/setRank", { points })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
      return err.response.data;
    });
};

export type PenguinData = {
  penguin_name: string;
  trait_body_id: number;
  trait_skin_id: number;
  trait_face_id: number;
  trait_head_id: number;
  id: string;
  image?: string;
  created_at: string;
};

export type PenguinRanks = {
  chess_rank: number;
  roads_rank: number;
};

export type userRes = {
  user: Identity<Traits>;
  penguin: {
    user_ranks: PenguinRanks;
    user_penguin: PenguinData;
    image?: string;
  };
  error: string;
  isSuccess: boolean;
};

export const getPenguinData = async (): Promise<userRes> => {
  //if (get(userDataStore)) return
  return await api
    .get<userRes>("/roads/user")
    .then((res) => {
      console.log("retrieved penguin data", res);
      return res;
    })
    .catch((err) => {
      console.log(err);
      if (ON_FAIL_MOCK) {
        console.log("\n\n~~~ MOCKING PENGUIN DATA ~~~\n\n");
        return mockUserRes;
      }
      return err.response.data;
    });
};
export type UserRanks = {
  roads_rank: number;
};
type UserRankData = {
  id: string;
  bg_color: string;
  description: string;
  image: string;
  penguin_name: string;
  user_ranks: UserRanks;
  user_penguin: {
    id?: string;
    created_at: string;
  };
};
export type getUsersByRankRes = {
  isSuccess: boolean;
  message: string;
  users: {
    token_traits: UserRankData[];
  };
};

//TODO: maybe set these limit and offset to static numbers!
export const getUsersByRank = async (
  limit: number,
  offset: number,
): Promise<getUsersByRankRes> => {
  //if (get(userDataStore)) return
  return await forever
    .get<getUsersByRankRes>(
      `/getUsersByRank?limit=${limit}&offset=${offset}&rankType=roads_rank`,
    )
    .then((res) => {
      console.log("retrieved users by getUsersByRank", res);
      return res;
    })
    .catch((err) => {
      console.log(err);
      if (ON_FAIL_MOCK) {
        console.log("\n\n~~~ MOCKING RANK DATA ~~~\n\n");
        return LeaderBoardResMock;
      }
      return err.response.data;
    });
};

export type UserGlobalPositionData = {
  message: string;
  isSuccess: boolean;
  position: number;
  data: {
    owner_wallet: string;
    user_id: string;
    image?: string | undefined;
    id?: string | undefined;
    penguin_name: string;
    position: number;
    bg_color: string;
    user_ranks: UserRanks;
  };
};

export const getUsersRank = async (
  userId: string,
  rank: number,
): Promise<UserGlobalPositionData> => {
  //if (get(userDataStore)) return
  return await forever
    .get<UserGlobalPositionData>(
      `/getUsersRank?user_id=${userId}&rank=${rank}&rankType=roads_rank`,
    )
    .then((res) => {
      console.log("retrieved users postRank", res);
      return res;
    })
    .catch((err) => {
      if (ON_FAIL_MOCK) {
        console.log("\n\n~~~ MOCKING MY RANK DATA ~~~\n\n");
        return mockUserGlobalPositionData;
      }
      console.log(err);
      return err.response.data;
    });
};
