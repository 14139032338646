import Generic from "./Generic";
import { pudgyRiders } from "../Utils/AssetUtils";

export default class PudgyRider extends Generic {
  linkedSled = null;
  setup = async () => {
    const { vehicles } = this.globalModels;

    for (let index in pudgyRiders) {
      let rider = pudgyRiders[index];
      await this._register(rider, {
        ...vehicles[rider],
        name: rider,
        castShadow: true,
        receiveShadow: false,
      });
    }

    return this.models;
  };
}
