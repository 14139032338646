import { FC, useContext, useMemo, useRef, useState } from "react";
import {
  Animated,
  Image,
  ImageBackground,
  Pressable,
  Text,
  View,
  useWindowDimensions,
} from "react-native";
import dashButtonBackgroundImg from "../../assets/images/ui/dash-button-background.png";
import dashButtonBackgroundTabletImg from "../../assets/images/ui/dash-button-background-tablet.png";
import feetImg from "../../assets/images/ui/feet.png";
import rewardsBackgroundImg from "../../assets/images/ui/rewards-background.png";
import rewardsBackgroundMobileImg from "../../assets/images/ui/rewards-background-mobile.png";
import coinsImg from "../../assets/images/ui/coins.png";
import gemsImg from "../../assets/images/ui/gems.png";
import { GameContext } from "../../context/GameContext";
import {
  EVENT_GLOBAL_POSITION_SET,
  EVENT_RESTART_GAME,
} from "../../src/GameEvents";
import { LinearGradient } from "expo-linear-gradient";
import Leaderboard from "../../components/Leaderboard";
import useBreakpoint from "../../src/hooks/useBreakpoint";
import styles from "./styles";
import selectBreakpoint from "../../src/Utils/selectBreakpoint";
import { useResolvedValue } from "../../src/hooks/useResolvedValue";
import { PlayerDataManager } from "../../src/Models/PlayerDataManager";
import { useSubscribeToEvent } from "../../src/hooks/useEvents";
import LeaderboardRankRow from "../../components/Leaderboard/LeaderboardRankRow";
import firstPlaceImg from "../../assets/images/ui/1st-place.png";
import secondPlaceImg from "../../assets/images/ui/2nd-place.png";
import thirdPlaceImg from "../../assets/images/ui/3rd-place.png";

interface Props {}
const GameOverScreen: FC<Props> = () => {
  const medalImage = (index: number) => {
    const medals = [firstPlaceImg, secondPlaceImg, thirdPlaceImg];

    return medals[index];
  };

  const leaderboardScale = useRef(new Animated.Value(0)).current;
  const gameOverX = useRef(new Animated.Value(0)).current;
  const leaderboardX = useRef(new Animated.Value(0)).current;

  const dimensions = useWindowDimensions();
  const breakpoint = useBreakpoint();
  const style = useMemo(() => styles(breakpoint), [breakpoint]);

  const [isLeaderboardVisible, setLeaderboardVisible] =
    useState<boolean>(false);

  const {
    triggerEvent,
    score,
    highscore,
    coins,
    gems,
    usersName,
    globalPosition,
  } = useContext(GameContext);

  const [myRank, setMyRank] = useState(globalPosition);

  useSubscribeToEvent(EVENT_GLOBAL_POSITION_SET, (position: number) => {
    console.log("~~~~ setting global postion", position);
    setMyRank(position);
    return;
  });

  const [me, error] = useResolvedValue(() =>
    PlayerDataManager.toLeaderboardRow()
  );

  const handleContinue = () => {
    if (isLeaderboardVisible) {
      triggerEvent(EVENT_RESTART_GAME);
      return;
    }

    Animated.spring(leaderboardScale, {
      toValue: 1,
      useNativeDriver: true,
    }).start();
    Animated.spring(gameOverX, {
      toValue: 1,
      useNativeDriver: true,
    }).start();
    Animated.spring(leaderboardX, {
      toValue: 1,
      useNativeDriver: true,
    }).start();
    setLeaderboardVisible(true);
  };

  return (
    <View style={style.container}>
      <View style={style.wrapper}>
        <Animated.View
          style={{
            transform: [
              {
                scale: leaderboardScale,
              },
              {
                translateX: selectBreakpoint(breakpoint, {
                  desktop: 0,
                  tablet: leaderboardX.interpolate({
                    inputRange: [0, 1],
                    outputRange: ["0%", "65%"],
                  }),
                  mobile: leaderboardX.interpolate({
                    inputRange: [0, 1],
                    outputRange: [0, dimensions.width / 2],
                  }),
                }),
              },
            ],
            width: selectBreakpoint(breakpoint, {
              desktop: "auto",
              tablet: "100%",
              mobile: "100%",
            }),
          }}
        >
          <Leaderboard
            currentUsersName={usersName}
            renderFooter={
              breakpoint === "desktop" ? null : (
                <Pressable
                  onPress={handleContinue}
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                  }}
                >
                  <ImageBackground
                    source={
                      breakpoint === "tablet"
                        ? dashButtonBackgroundTabletImg
                        : dashButtonBackgroundImg
                    }
                    style={style.actionButtonBackground}
                  >
                    <Text style={style.actionButtonText}>
                      {isLeaderboardVisible ? "Play again" : "Continue"}
                    </Text>
                  </ImageBackground>
                </Pressable>
              )
            }
          />
        </Animated.View>
        <Animated.View
          style={{
            transform: [
              {
                translateX: selectBreakpoint(breakpoint, {
                  desktop: gameOverX.interpolate({
                    inputRange: [0, 1],
                    outputRange: ["-50%", "0%"],
                  }),
                  tablet: gameOverX.interpolate({
                    inputRange: [0, 1],
                    outputRange: [
                      -(dimensions.width * 0.375) + 8,
                      dimensions.width / 2,
                    ],
                  }),
                  mobile: gameOverX.interpolate({
                    inputRange: [0, 1],
                    outputRange: [
                      -(dimensions.width / 2),
                      dimensions.width / 2,
                    ],
                  }),
                }),
              },
            ],
            width: selectBreakpoint(breakpoint, {
              desktop: "auto",
              tablet: "100%",
              mobile: "100%",
            }),
          }}
        >
          <LinearGradient
            colors={["#ffffff", "rgba(157, 219, 255, 0.1)"]}
            locations={[0.018, 1.11]}
            start={[0.0, 0.0]}
            end={[1.0, 1.0]}
            style={style.modal}
          >
            <View style={style.contentContainer}>
              <Text style={style.gameOver}>
                {isLeaderboardVisible ? "Try Again?" : "Game over"}
              </Text>
            </View>
            <View style={style.statContainer}>
              <Image source={feetImg} style={style.statIcon} />
              <Text style={style.statValue}>{score}</Text>
            </View>
            <View style={style.statContainer}>
              <Text style={style.statBest}>Best</Text>
              <Text style={[style.statValue, style.statBestValue]}>
                {highscore}
              </Text>
            </View>
            <ImageBackground
              source={
                breakpoint === "mobile"
                  ? rewardsBackgroundMobileImg
                  : rewardsBackgroundImg
              }
              style={style.rewardsBackground}
              resizeMode="contain"
            >
              <View style={[style.rewardItemContainer, { left: 15 }]}>
                <Image
                  source={coinsImg}
                  style={style.coinsImg}
                  resizeMode="contain"
                />
                <Text style={style.coinsText}>+{coins}</Text>
              </View>
              <View style={[style.rewardItemContainer, { left: -15 }]}>
                <Image
                  source={gemsImg}
                  style={style.gemsImg}
                  resizeMode="contain"
                />
                <Text style={style.gemsText}>+{gems}</Text>
              </View>
            </ImageBackground>
            {me && myRank > 0 && !isLeaderboardVisible && (
              <View
                style={{
                  backgroundColor: "rgb(235, 245, 255)",
                }}
              >
                <LeaderboardRankRow
                  data={me}
                  index={myRank}
                  medalImage={medalImage(myRank)}
                  isLast={true}
                  breakpoint={breakpoint}
                />
              </View>
            )}
            <Pressable onPress={handleContinue}>
              <ImageBackground
                source={
                  breakpoint === "tablet"
                    ? dashButtonBackgroundTabletImg
                    : dashButtonBackgroundImg
                }
                style={style.actionButtonBackground}
              >
                <Text style={style.actionButtonText}>
                  {isLeaderboardVisible ? "Play again" : "Continue"}
                </Text>
              </ImageBackground>
            </Pressable>
          </LinearGradient>
        </Animated.View>
      </View>
    </View>
  );
};

export default GameOverScreen;
