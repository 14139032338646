import { TextureLoader } from "expo-three";
import {MeshPhongMaterial, MeshToonMaterial, NearestFilter, Texture} from "three";
import Models from "./Models";
// import Models from "./Models";

const textureCache = {};

export async function loadTexture(resource) {
  if (textureCache[resource]) {
    return textureCache[resource].clone();
  }

  return new Promise((resolve, reject) => {
    const img = document.createElement("img");
    img.crossOrigin = "anonymous";
    img.onload = () => {
      const texture = new Texture();
      texture.image = img;
      texture.needsUpdate = true;

      texture.magFilter = NearestFilter;
      texture.minFilter = NearestFilter;

      textureCache[resource] = texture;

      resolve(texture);
    }

    img.onerror = (error) => {
        reject(error);
    }

    img.src = resource;
  })
}

const materialCache = {};

export default class CrossyMaterial extends MeshPhongMaterial {
  static loadTexture = loadTexture;

  static async load(resource) {
    if (materialCache[resource]) {
      return materialCache[resource].clone();
    }

    const texture = await loadTexture(resource);

    materialCache[resource] = new MeshPhongMaterial({
      map: texture,
      //TODO: gradientMap: loadTexture(Models.toonShader.TOON_TONE),
      flatShading: false,
      emissiveIntensity: 0,
      shininess: 0,
      reflectivity: 0,
    });

    return materialCache[resource];
  }
}
