import React from "react";

interface PlatformScalerProps {
  scale?: number;
}

const PlatformScaler: React.FC<PlatformScalerProps> = ({
  scale = 0.7,
  children,
}) => {
  const width = window.innerWidth;
  const height = window.innerHeight;
  const isPhone = width < 768;

  const scalingParameters = isPhone
    ? {
        width: `${scale * 100}%`,
        height: `${scale * 100}%`,
        transform: `scale(${scale})`,
        transformOrigin: `${width / 2} ${height / 2}`,
      }
    : {};

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ ...styles.container, ...scalingParameters }}>
        {children}
      </div>
    </div>
  );
};

const styles = {
  container: {
    flex: 1,
  },
};

export default PlatformScaler;
