export const EVENT_COIN_COLLISION = "EVENT_COIN_COLLISION";
export const EVENT_GAME_OVER = "EVENT_GAME_OVER";
export const EVENT_GAME_START = "EVENT_GAME_START";
export const EVENT_PLAYER_START = "EVENT_PLAYER_START";
export const EVENT_PLAYER_ROW_CHANGED = "PLAYER_ROW_CHANGE";
export const EVENT_RANDOM_TRAITS = "EVENT_RANDOM_TRAITS";

export const EVENT_LOADING_DONE = "EVENT_LOADING_DONE";
export const EVENT_LOADING_ERROR = "EVENT_LOADING_ERROR";
export const EVENT_LOADING_PROGRESS = "EVENT_LOADING_PROGRESS";

export const EVENT_TRAITS_CHANGED = "EVENT_TRAITS_CHANGED";

export const EVENT_RESTART_GAME = "EVENT_RESTART_GAME";

export const EVENT_GAME_STATE_CHANGE = "EVENT_GAME_STATE_CHANGE";

export const EVENT_GAME_SCORE_CHANGED = "EVENT_GAME_SCORE_CHANGED";

export const EVENT_GAME_TOGGLE_MUTE = "EVENT_GAME_TOGGLE_MUTE";

export const EVENT_GLOBAL_POSITION_SET = "EVENT_GLOBAL_POSITION_SET";

export class EventLoadingProgress {
  msg;
}

export class EventTraitsChanged {
  traits;
}

export class EventGameStateChange {
  gameState;
}
