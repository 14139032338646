import { StyleSheet } from "react-native";
import selectBreakpoint from "../../src/Utils/selectBreakpoint";

const styles = (breakpoint: Breakpoint) => StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'rgba(107,202,255,0.7)',
    ...StyleSheet.absoluteFillObject
  },
  wrapper: {
    flex: 1,
    marginHorizontal: 'auto',
    paddingVertical: 32,
    alignItems: 'center',
    justifyContent: 'center',
    gap: 32,
    ...selectBreakpoint(breakpoint, {
      desktop: {
        width: 1200,
        flexDirection: 'row',
      },
      tablet: {
        width: '100%',
        flexDirection: 'row',
        paddingHorizontal: 32,
      },
      mobile: {
        width: '100%',
        flexDirection: 'row',
        paddingHorizontal: 16
      }
    })
  },
  modal: {
    borderRadius: 16,
    borderWidth: 3,
    borderColor: '#CEEDFF',
    overflow: 'hidden',
    transformOrigin: 'left',
    ...selectBreakpoint(breakpoint, {
      desktop: {
        minWidth: 600,
      },
      tablet: {
        width: '75%',
      },
      mobile: {
        width: '100%'
      }
    })
  },
  contentContainer: {
    padding: 32,
  },
  gameOver: {
    fontFamily: 'tt_trailer',
    textTransform: 'uppercase',
    color: '#477DFD',
    textAlign: 'center',
    fontSize: selectBreakpoint(breakpoint, {
      desktop: 72,
      tablet: 72,
      mobile: 60
    }),
  },
  buttonsWrapper: {
    flexDirection: 'row',
    gap: 16
  },
  buttonWrapper: {
    flex: 1,
    minWidth: 220
  },
  actionButtonBackground: {
    width: '100%',
    aspectRatio: 359 / 80,
    alignItems: 'center',
    justifyContent: 'center',
    gap: 163,
    ...selectBreakpoint(breakpoint, {
      tablet: {
        aspectRatio: 2600 / 452
      }
    })
  },
  actionButtonText: {
    fontFamily: 'menco_black',
    fontWeight: '900',
    color: '#ffffff',
    textShadow: '-1px 1px 0px #1A1A1A',
    paddingTop: 8,
    fontSize: selectBreakpoint(breakpoint, {
      desktop: 40,
      tablet: 40,
      mobile: 24
    }),
  },
  statContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 16
  },
  statIcon: {
    width: 55,
    aspectRatio: 222 / 204
  },
  statValue: {
    fontFamily: 'tt_trailer',
    color: '#2C5FFF',
    fontSize: selectBreakpoint(breakpoint, {
      desktop: 72,
      tablet: 72,
      mobile: 48
    }),
  },
  statBest: {
    fontFamily: 'tt_trailer',
    color: '#002BB4',
    fontSize: selectBreakpoint(breakpoint, {
      desktop: 48,
      tablet: 48,
      mobile: 28
    }),
  },
  statBestValue: {
    fontSize: 48
  },
  rewardsBackground: {
    height: 140,
    aspectRatio: 1193 / 561,
    alignSelf: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 32
  },
  rewardItemContainer: {
    flex: 1,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  coinsImg: {
    aspectRatio: 1,
    height: selectBreakpoint(breakpoint, {
      desktop: '70%',
      tablet: '70%',
      mobile: '50%'
    }),
  },
  gemsImg: {
    aspectRatio: 370 / 348,
    height: selectBreakpoint(breakpoint, {
      desktop: '70%',
      tablet: '70%',
      mobile: '50%'
    }),
  },
  coinsText: {
    position: 'absolute',
    fontSize: 24,
    fontFamily: 'menco_black',
    color: '#ffffff',
    textShadow: '0px -1px 0px #F3B41E, 1px 0px 0px #F3B41E, 0px 1px 0px #F3B41E, -1px 0px 0px #F3B41E, 0px 0px 12px rgba(72, 155, 223, 0.40)',
    transform: selectBreakpoint(breakpoint, {
      desktop: [{ translateX: 25 }, { translateY: 30 }],
      tablet: [{ translateX: 25 }, { translateY: 30 }],
      mobile: [{translateX: 15}, {translateY: 20}]
    })
  },
  gemsText: {
    position: 'absolute',
    fontSize: 24,
    fontFamily: 'menco_black',
    color: '#ffffff',
    textShadow: '0px -1px 0px #EA7BF8, 1px 0px 0px #EA7BF8, 0px 1px 0px #EA7BF8, -1px 0px 0px #EA7BF8, 0px 0px 12px rgba(72, 155, 223, 0.40)',
    transform: selectBreakpoint(breakpoint, {
      desktop: [{ translateX: 25 }, { translateY: 30 }],
      tablet: [{ translateX: 25 }, { translateY: 30 }],
      mobile: [{translateX: 15}, {translateY: 20}]
    })
  }
})

export default styles