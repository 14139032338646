import React, { useContext, FC, useState, useEffect, useMemo } from "react";
import { Image, Text, View } from "react-native";
import { GameContext } from "../../context/GameContext";
import coinBackgroundImg from "../../assets/images/ui/coin-background.png";
import { EVENT_COIN_COLLISION, EVENT_GAME_START } from "../../src/GameEvents";
import useBreakpoint from "../../src/hooks/useBreakpoint";
import styles from "./styles";

interface Props {}
const CoinText: FC<Props> = () => {
  const [coinsAmount, setCoinsAmount] = useState<number>(0);
  const { subscribeToEvent } = useContext(GameContext);

  const breakpoint = useBreakpoint();
  const style = useMemo(() => styles(breakpoint), [breakpoint]);

  useEffect(() => {
    const unsubAddCoin = subscribeToEvent(
      EVENT_COIN_COLLISION,
      ({ points }) => {
        setCoinsAmount((coins_amount) => (coins_amount += points));
      },
    );

    const unsubGameStart = subscribeToEvent(EVENT_GAME_START, () => {
      setCoinsAmount(0);
    });

    return () => {
      unsubAddCoin();
      unsubGameStart();
    };
  }, []);

  return (
    <View>
      <Image
        source={coinBackgroundImg}
        style={style.scoreBackgroundImg}
        resizeMode="contain"
      />
      <Text style={style.coinAmount}>
        {String(coinsAmount).padStart(4, "0")}
      </Text>
    </View>
  );
};

export default CoinText;
