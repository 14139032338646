import { ENV_DEBUG } from "./EnvUtils"

export function log(msg: any, ...args: any[]) {
    if(ENV_DEBUG) console.log(msg, ...args);
}

export function warn(msg: any, ...args: any[]) {
    if(ENV_DEBUG) console.log(msg, ...args);
}

export function err(msg: any, ...args: any[]) {
    if(ENV_DEBUG) console.error(msg, ...args);
}