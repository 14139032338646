import { useContext, useMemo } from "react";
import Leaderboard from "../../components/Leaderboard";
import { GameContext } from "../../context/GameContext";
import { View } from "react-native";
import useBreakpoint from "../../src/hooks/useBreakpoint";
import styles from "./styles";
import BackButton from "../../components/BackButton";
import { useNavigation } from "@react-navigation/native";
import { useResolvedValue } from "../../src/hooks/useResolvedValue";
import { PlayerDataManager } from "../../src/Models/PlayerDataManager";

const LeaderboardsScreen = () => {
  const navigation = useNavigation();
  // const { usersName } = useContext(GameContext);
  const [data, error] = useResolvedValue(() =>
    PlayerDataManager.toLeaderboardRow(),
  );

  const breakpoint = useBreakpoint();
  const style = useMemo(() => styles(breakpoint), [breakpoint]);

  const handleBack = () => {
    navigation.goBack();
  };

  return (
    <>
      <BackButton onPress={handleBack} title="Leaderboards" />
      <View style={style.container}>
        {data && (
          <Leaderboard currentUsersName={data.nameText} renderFooter={null} />
        )}
      </View>
    </>
  );
};

export default LeaderboardsScreen;
