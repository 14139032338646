import { StyleSheet } from "react-native";
import selectBreakpoint from "../../src/Utils/selectBreakpoint";

const styles = (breakpoint: Breakpoint) => StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'rgba(107,202,255,0.7)',
    marginHorizontal: 'auto',
    paddingVertical: 32,
    alignItems: 'center',
    justifyContent: 'center',
    gap: 32,
    width: '100%',
    ...selectBreakpoint(breakpoint, {
      desktop: {
        width: '100%',
      },
      tablet: {
        width: '100%',
        flexDirection: 'row',
        paddingHorizontal: 32,
      },
      mobile: {
        width: '100%',
        flexDirection: 'row',
        paddingHorizontal: 16
      }
    })
  }
})

export default styles