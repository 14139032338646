import { FC, useMemo } from "react"
import { Image, Pressable, Text, View } from "react-native"
import backIcon from "../../assets/images/ui/back.png"
import useBreakpoint from "../../src/hooks/useBreakpoint"
import styles from "./styles"

interface Props {
  onPress: () => void
  title?: string
  isRelative?: boolean
}
const BackButton: FC<Props> = ({onPress, title, isRelative}) => {
  const breakpoint = useBreakpoint()
  const style = useMemo(() => styles(breakpoint), [breakpoint])
  return (
    <View style={[style.container, isRelative && {position: 'relative'}]}>
      <Pressable onPress={onPress}>
        <Image source={backIcon} style={style.button} resizeMode="contain" />
      </Pressable>
      {title ? (
        <Text style={style.title}>{title}</Text>
      ) : null}
    </View>
  )
}

export default BackButton