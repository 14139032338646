import { PlayerDataManager } from "./PlayerDataManager";
import { log, warn } from "../Utils/LogUtils";

export type GameData = {
  character: string;
  highscore: number;
  coins: number;
  xp: number;
  gems: number;
  score: number;
  audioMuted?: boolean;
  globalPosition?: number;
};

const GemPayouts = [25, 50, 80, 120, 160, 200, 240, 300];
const BonusCoins = [
  1, 10, 26, 40, 60, 80, 100, 120, 140, 160, 180, 200, 220, 240, 260, 280,
];
function calculateGems(points: number): number {
  let gemCount = 0;
  for (let i = 0; i < GemPayouts.length; i++) {
    if (points >= GemPayouts[i]) {
      gemCount++;
    } else {
      break;
    }
  }
  return gemCount;
}

function calculateExtraCoins(points: number): number {
  // ten extra coins for going over 300 points
  let coinCount = points >= 300 ? 10 : 0;
  for (let i = 0; i < BonusCoins.length; i++) {
    if (points >= BonusCoins[i]) {
      coinCount += 5;
    } else {
      break;
    }
  }
  return coinCount;
}

export class GameOverModel {
  private static instance: GameOverModel;

  private constructor() {
    // Private constructor to prevent instantiation outside of the class
  }

  public static getInstance(): GameOverModel {
    if (!GameOverModel.instance) {
      GameOverModel.instance = new GameOverModel();
    }
    return GameOverModel.instance;
  }

  static async HandleGameOver(
    data: GameData,
    points: number,
  ): Promise<GameData> {
    let {
      character,
      highscore,
      score,
      coins,
      xp,
      gems,
      globalPosition = 0,
    } = data;
    const playerDataManager = PlayerDataManager.getInstance();

    if (!playerDataManager.isLoaded) {
      warn("~~~ [LS] [GP] PlayerDataManager is not loaded not saving data");
    }

    const ranks = playerDataManager.getRankData() ?? {
      roads_rank: 0,
    };

    log("\n\n ~~~ [LS] [GP] current rank from player/server ~~", ranks);

    log("\n\n ~~~ [LS] [GP] points vs highscore", points, highscore);

    if (points > highscore) {
      log("\n\n ~~~ [LS] [GP] setting highscore to points", points, highscore);

      highscore = points;
    }

    gems = calculateGems(points);
    coins += calculateExtraCoins(points);

    const localHighScore = Math.max(points, highscore);
    log("\n\n ~~~ [LS] [GP] localHighScore", localHighScore);
    const scoreToSubmit = Math.max(localHighScore, ranks.roads_rank); //Local highscore is greater than the last time you fetched from DB

    log("\n\n ~~~ [LS] [GP] scoreToSubmit", scoreToSubmit);
    if (playerDataManager.isLoaded) {
      log(
        "\n\n ~~~ [LS] [GP] going to submit, scoreToSubmit, coins, gems",
        scoreToSubmit,
        coins,
        gems,
      );

      const rank = await playerDataManager.postRank(
        points,
        localHighScore,
        ranks.roads_rank,
      ); //Don't need to await (I don't think)
      log("\n\n ~~~ [LS] [GP] rank", rank);

      const deathRes = await playerDataManager.submit(coins, gems);
      log("~~~ [LS] [GP] Game Over Results", deathRes);

      playerDataManager.setRank(scoreToSubmit);

      globalPosition = await playerDataManager.getUsersRank();
      log("~~~ [LS] [GP] current user position: ", globalPosition);
    }

    highscore = scoreToSubmit;

    log("\n\n ~~~ [LS] [GP] setting ranks", scoreToSubmit);

    return {
      character,
      highscore,
      coins,
      xp,
      gems,
      score,
      globalPosition,
    };
  }

  // Your class methods and properties go here
}
