// https://github.com/haldarmahesh/use-mobile-detect-hook/tree/master
// this detects if the user is on desktop or mobile
// test - 3
export const getMobileDetect = (userAgent) => {
  const isAndroid = () => Boolean(userAgent.match(/Android/i));
  const isIos = () => Boolean(userAgent.match(/iPhone|iPad|iPod/i));
  const isOpera = () => Boolean(userAgent.match(/Opera Mini/i));
  const isWindowsMobile = () => Boolean(userAgent.match(/IEMobile/i));
  const isSSR = () => Boolean(userAgent.match(/SSR/i));

  const isMobile = () =>
    Boolean(isAndroid() || isIos() || isOpera() || isWindowsMobile());
  const isDesktop = () => Boolean(!isMobile() && !isSSR());
  const isTablet = () =>
    Boolean(isMobile() && Boolean(userAgent.match(/iPad/i)));
  return {
    isMobile,
    isDesktop,
    isTablet,
    isAndroid,
    isIos,
    isSSR,
  };
};
export const useMobileDetect = () => {
  const userAgent =
    typeof navigator === "undefined" ? "SSR" : navigator.userAgent;
  return getMobileDetect(userAgent);
};

export const useIsMobile = () => {
  return useMobileDetect().isMobile();
};
export const useIsTablet = () => {
  return useMobileDetect().isTablet();
};

export const useIsDesktop = () => {
  return useMobileDetect().isDesktop();
};

export const useIsIos = () => {
  return useMobileDetect().isIos();
};

export const useIsAndroid = () => {
  return useMobileDetect().isAndroid();
};
