import { StyleSheet } from "react-native";
import selectBreakpoint from "../../../src/Utils/selectBreakpoint";

const styles = (breakpoint: Breakpoint) =>
  StyleSheet.create({
    bodyRow: {
      flexDirection: "row",
    },
    bodyRank: {
      padding: 10,
      justifyContent: "center",
      width: selectBreakpoint(breakpoint, {
        desktop: "20%",
        tablet: "20%",
        mobile: "15%",
      }),
    },
    bodyText: {
      fontFamily: "menco_black",
      color: "#333333",
      textAlign: "center",
      fontSize: selectBreakpoint(breakpoint, {
        desktop: 24,
        tablet: 24,
        mobile: 16,
      }),
    },
    avatarWrapper: {
      borderRadius: 100,
      backgroundColor: "#ffffff",
      borderColor: "#6AB6FD",
      borderWidth: selectBreakpoint(breakpoint, {
        desktop: 3,
        tablet: 3,
        mobile: 1,
      }),
    },
    avatarImg: {
      borderRadius: 100,
      ...selectBreakpoint(breakpoint, {
        desktop: {
          width: 50,
          height: 50,
        },
        tablet: {
          width: 50,
          height: 50,
        },
        mobile: {
          width: 32,
          height: 32,
        },
      }),
    },
    medalImg: {
      aspectRatio: 204 / 167,
      height: 40,
    },
    bodyUserContainer: {
      width: "35%",
      padding: 10,
      flexDirection: "row",
      alignItems: "center",
      gap: 12,
    },
    bodyRewardContainer: {
      width: "15%",
      padding: 10,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      gap: 2,
    },
    giftImg: {
      aspectRatio: 128 / 116,
      height: 30,
    },
    coinImg: {
      aspectRatio: 1,
      height: 24,
    },
    bodyRankContainer: {
      width: "30%",
      padding: 10,
      alignItems: "center",
      justifyContent: "center",
    },
    divider: {
      width: "90%",
      height: 1,
      backgroundColor: "#ffffff",
      alignSelf: "center",
    },
  });

export default styles;
