import { FC, PropsWithChildren } from "react";
import {
  Image,
  ImageBackground,
  ImageBackgroundProps,
  Pressable,
  PressableProps,
  StyleSheet,
  Text,
} from "react-native";
import styles from "../../screens/PauseScreen/styles";
import buttonBackgroundImg from "../../assets/images/ui/button-background.png";
import buttonPressedBackgroundImg from "../../assets/images/ui/button-pressed-background.png";

interface Props {
  title: string;
  defaultIcon: any;
  hoveredIcon: any;
  iconAspectRatio: number;
  style: ReturnType<typeof styles>;
  onPress: () => void;
}
const PauseScreenButton: FC<Props> = (props) => {
  const Content = ({ hovered }: { hovered?: boolean }) => (
    <>
      <Image
        source={hovered ? props.defaultIcon : props.hoveredIcon}
        style={{
          height: "25%",
          aspectRatio: props.iconAspectRatio,
        }}
      />
      <Text style={props.style.buttonText}>{props.title}</Text>
    </>
  );
  return (
    <Pressable style={props.style.menuItem} onPress={props.onPress}>
      {({
        // @ts-ignore
        hovered,
      }) => (
        <>
          <ImageBackground
            source={buttonBackgroundImg}
            style={props.style.menuItemBackgroundImage}
          >
            <Content hovered />
          </ImageBackground>
          <ImageBackground
            source={buttonPressedBackgroundImg}
            style={[
              props.style.menuItemBackgroundImage,
              StyleSheet.absoluteFillObject,
              { opacity: hovered ? 1 : 0 },
            ]}
          >
            <Content />
          </ImageBackground>
        </>
      )}
    </Pressable>
  );
};

export default PauseScreenButton;
